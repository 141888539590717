/* eslint-disable */
import * as Types from '../types.generated';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type IsAnalyticsEnabledQueryVariables = Types.Exact<{ [key: string]: never }>;

export type IsAnalyticsEnabledQuery = { __typename?: 'Query' } & Pick<Types.Query, 'isAnalyticsEnabled'>;

export type GetAnalyticsChartsQueryVariables = Types.Exact<{ [key: string]: never }>;

export type GetAnalyticsChartsQuery = { __typename?: 'Query' } & {
    getAnalyticsCharts: Array<
        { __typename?: 'AnalyticsChartGroup' } & Pick<Types.AnalyticsChartGroup, 'title'> & {
                charts: Array<
                    | ({ __typename?: 'TimeSeriesChart' } & Pick<Types.TimeSeriesChart, 'title' | 'interval'> & {
                              lines: Array<
                                  { __typename?: 'NamedLine' } & Pick<Types.NamedLine, 'name'> & {
                                          data: Array<
                                              { __typename?: 'NumericDataPoint' } & Pick<
                                                  Types.NumericDataPoint,
                                                  'x' | 'y'
                                              >
                                          >;
                                      }
                              >;
                              dateRange: { __typename?: 'DateRange' } & Pick<Types.DateRange, 'start' | 'end'>;
                          })
                    | ({ __typename?: 'BarChart' } & Pick<Types.BarChart, 'title'> & {
                              bars: Array<
                                  { __typename?: 'NamedBar' } & Pick<Types.NamedBar, 'name'> & {
                                          segments: Array<
                                              { __typename?: 'BarSegment' } & Pick<Types.BarSegment, 'label' | 'value'>
                                          >;
                                      }
                              >;
                          })
                    | ({ __typename?: 'TableChart' } & Pick<Types.TableChart, 'title' | 'columns'> & {
                              rows: Array<{ __typename?: 'Row' } & Pick<Types.Row, 'values'>>;
                          })
                >;
            }
    >;
};

export const IsAnalyticsEnabledDocument = gql`
    query isAnalyticsEnabled {
        isAnalyticsEnabled
    }
`;

/**
 * __useIsAnalyticsEnabledQuery__
 *
 * To run a query within a React component, call `useIsAnalyticsEnabledQuery` and pass it any options that fit your needs.
 * When your component renders, `useIsAnalyticsEnabledQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useIsAnalyticsEnabledQuery({
 *   variables: {
 *   },
 * });
 */
export function useIsAnalyticsEnabledQuery(
    baseOptions?: Apollo.QueryHookOptions<IsAnalyticsEnabledQuery, IsAnalyticsEnabledQueryVariables>,
) {
    return Apollo.useQuery<IsAnalyticsEnabledQuery, IsAnalyticsEnabledQueryVariables>(
        IsAnalyticsEnabledDocument,
        baseOptions,
    );
}
export function useIsAnalyticsEnabledLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<IsAnalyticsEnabledQuery, IsAnalyticsEnabledQueryVariables>,
) {
    return Apollo.useLazyQuery<IsAnalyticsEnabledQuery, IsAnalyticsEnabledQueryVariables>(
        IsAnalyticsEnabledDocument,
        baseOptions,
    );
}
export type IsAnalyticsEnabledQueryHookResult = ReturnType<typeof useIsAnalyticsEnabledQuery>;
export type IsAnalyticsEnabledLazyQueryHookResult = ReturnType<typeof useIsAnalyticsEnabledLazyQuery>;
export type IsAnalyticsEnabledQueryResult = Apollo.QueryResult<
    IsAnalyticsEnabledQuery,
    IsAnalyticsEnabledQueryVariables
>;
export const GetAnalyticsChartsDocument = gql`
    query getAnalyticsCharts {
        getAnalyticsCharts {
            title
            charts {
                ... on TimeSeriesChart {
                    title
                    lines {
                        name
                        data {
                            x
                            y
                        }
                    }
                    dateRange {
                        start
                        end
                    }
                    interval
                }
                ... on BarChart {
                    title
                    bars {
                        name
                        segments {
                            label
                            value
                        }
                    }
                }
                ... on TableChart {
                    title
                    columns
                    rows {
                        values
                    }
                }
            }
        }
    }
`;

/**
 * __useGetAnalyticsChartsQuery__
 *
 * To run a query within a React component, call `useGetAnalyticsChartsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAnalyticsChartsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAnalyticsChartsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetAnalyticsChartsQuery(
    baseOptions?: Apollo.QueryHookOptions<GetAnalyticsChartsQuery, GetAnalyticsChartsQueryVariables>,
) {
    return Apollo.useQuery<GetAnalyticsChartsQuery, GetAnalyticsChartsQueryVariables>(
        GetAnalyticsChartsDocument,
        baseOptions,
    );
}
export function useGetAnalyticsChartsLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<GetAnalyticsChartsQuery, GetAnalyticsChartsQueryVariables>,
) {
    return Apollo.useLazyQuery<GetAnalyticsChartsQuery, GetAnalyticsChartsQueryVariables>(
        GetAnalyticsChartsDocument,
        baseOptions,
    );
}
export type GetAnalyticsChartsQueryHookResult = ReturnType<typeof useGetAnalyticsChartsQuery>;
export type GetAnalyticsChartsLazyQueryHookResult = ReturnType<typeof useGetAnalyticsChartsLazyQuery>;
export type GetAnalyticsChartsQueryResult = Apollo.QueryResult<
    GetAnalyticsChartsQuery,
    GetAnalyticsChartsQueryVariables
>;
