/* eslint-disable */
import * as Types from '../types.generated';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type GetAccessTokenQueryVariables = Types.Exact<{
    input: Types.GetAccessTokenInput;
}>;

export type GetAccessTokenQuery = { __typename?: 'Query' } & {
    getAccessToken?: Types.Maybe<{ __typename?: 'AccessToken' } & Pick<Types.AccessToken, 'accessToken'>>;
};

export const GetAccessTokenDocument = gql`
    query getAccessToken($input: GetAccessTokenInput!) {
        getAccessToken(input: $input) {
            accessToken
        }
    }
`;

/**
 * __useGetAccessTokenQuery__
 *
 * To run a query within a React component, call `useGetAccessTokenQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAccessTokenQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAccessTokenQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetAccessTokenQuery(
    baseOptions: Apollo.QueryHookOptions<GetAccessTokenQuery, GetAccessTokenQueryVariables>,
) {
    return Apollo.useQuery<GetAccessTokenQuery, GetAccessTokenQueryVariables>(GetAccessTokenDocument, baseOptions);
}
export function useGetAccessTokenLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<GetAccessTokenQuery, GetAccessTokenQueryVariables>,
) {
    return Apollo.useLazyQuery<GetAccessTokenQuery, GetAccessTokenQueryVariables>(GetAccessTokenDocument, baseOptions);
}
export type GetAccessTokenQueryHookResult = ReturnType<typeof useGetAccessTokenQuery>;
export type GetAccessTokenLazyQueryHookResult = ReturnType<typeof useGetAccessTokenLazyQuery>;
export type GetAccessTokenQueryResult = Apollo.QueryResult<GetAccessTokenQuery, GetAccessTokenQueryVariables>;
