/* eslint-disable */
import * as Types from '../types.generated';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type ListPoliciesQueryVariables = Types.Exact<{
    input: Types.ListPoliciesInput;
}>;

export type ListPoliciesQuery = { __typename?: 'Query' } & {
    listPolicies?: Types.Maybe<
        { __typename?: 'ListPoliciesResult' } & Pick<Types.ListPoliciesResult, 'start' | 'count' | 'total'> & {
                policies: Array<
                    { __typename?: 'Policy' } & Pick<
                        Types.Policy,
                        'urn' | 'type' | 'name' | 'description' | 'state' | 'privileges' | 'editable'
                    > & {
                            resources?: Types.Maybe<
                                { __typename?: 'ResourceFilter' } & Pick<
                                    Types.ResourceFilter,
                                    'type' | 'allResources' | 'resources'
                                >
                            >;
                            actors: { __typename?: 'ActorFilter' } & Pick<
                                Types.ActorFilter,
                                'users' | 'groups' | 'allUsers' | 'allGroups' | 'resourceOwners'
                            >;
                        }
                >;
            }
    >;
};

export type CreatePolicyMutationVariables = Types.Exact<{
    input: Types.PolicyUpdateInput;
}>;

export type CreatePolicyMutation = { __typename?: 'Mutation' } & Pick<Types.Mutation, 'createPolicy'>;

export type UpdatePolicyMutationVariables = Types.Exact<{
    urn: Types.Scalars['String'];
    input: Types.PolicyUpdateInput;
}>;

export type UpdatePolicyMutation = { __typename?: 'Mutation' } & Pick<Types.Mutation, 'updatePolicy'>;

export type DeletePolicyMutationVariables = Types.Exact<{
    urn: Types.Scalars['String'];
}>;

export type DeletePolicyMutation = { __typename?: 'Mutation' } & Pick<Types.Mutation, 'deletePolicy'>;

export const ListPoliciesDocument = gql`
    query listPolicies($input: ListPoliciesInput!) {
        listPolicies(input: $input) {
            start
            count
            total
            policies {
                urn
                type
                name
                description
                state
                resources {
                    type
                    allResources
                    resources
                }
                privileges
                actors {
                    users
                    groups
                    allUsers
                    allGroups
                    resourceOwners
                }
                editable
            }
        }
    }
`;

/**
 * __useListPoliciesQuery__
 *
 * To run a query within a React component, call `useListPoliciesQuery` and pass it any options that fit your needs.
 * When your component renders, `useListPoliciesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListPoliciesQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useListPoliciesQuery(
    baseOptions: Apollo.QueryHookOptions<ListPoliciesQuery, ListPoliciesQueryVariables>,
) {
    return Apollo.useQuery<ListPoliciesQuery, ListPoliciesQueryVariables>(ListPoliciesDocument, baseOptions);
}
export function useListPoliciesLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<ListPoliciesQuery, ListPoliciesQueryVariables>,
) {
    return Apollo.useLazyQuery<ListPoliciesQuery, ListPoliciesQueryVariables>(ListPoliciesDocument, baseOptions);
}
export type ListPoliciesQueryHookResult = ReturnType<typeof useListPoliciesQuery>;
export type ListPoliciesLazyQueryHookResult = ReturnType<typeof useListPoliciesLazyQuery>;
export type ListPoliciesQueryResult = Apollo.QueryResult<ListPoliciesQuery, ListPoliciesQueryVariables>;
export const CreatePolicyDocument = gql`
    mutation createPolicy($input: PolicyUpdateInput!) {
        createPolicy(input: $input)
    }
`;
export type CreatePolicyMutationFn = Apollo.MutationFunction<CreatePolicyMutation, CreatePolicyMutationVariables>;

/**
 * __useCreatePolicyMutation__
 *
 * To run a mutation, you first call `useCreatePolicyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreatePolicyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createPolicyMutation, { data, loading, error }] = useCreatePolicyMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreatePolicyMutation(
    baseOptions?: Apollo.MutationHookOptions<CreatePolicyMutation, CreatePolicyMutationVariables>,
) {
    return Apollo.useMutation<CreatePolicyMutation, CreatePolicyMutationVariables>(CreatePolicyDocument, baseOptions);
}
export type CreatePolicyMutationHookResult = ReturnType<typeof useCreatePolicyMutation>;
export type CreatePolicyMutationResult = Apollo.MutationResult<CreatePolicyMutation>;
export type CreatePolicyMutationOptions = Apollo.BaseMutationOptions<
    CreatePolicyMutation,
    CreatePolicyMutationVariables
>;
export const UpdatePolicyDocument = gql`
    mutation updatePolicy($urn: String!, $input: PolicyUpdateInput!) {
        updatePolicy(urn: $urn, input: $input)
    }
`;
export type UpdatePolicyMutationFn = Apollo.MutationFunction<UpdatePolicyMutation, UpdatePolicyMutationVariables>;

/**
 * __useUpdatePolicyMutation__
 *
 * To run a mutation, you first call `useUpdatePolicyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdatePolicyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updatePolicyMutation, { data, loading, error }] = useUpdatePolicyMutation({
 *   variables: {
 *      urn: // value for 'urn'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdatePolicyMutation(
    baseOptions?: Apollo.MutationHookOptions<UpdatePolicyMutation, UpdatePolicyMutationVariables>,
) {
    return Apollo.useMutation<UpdatePolicyMutation, UpdatePolicyMutationVariables>(UpdatePolicyDocument, baseOptions);
}
export type UpdatePolicyMutationHookResult = ReturnType<typeof useUpdatePolicyMutation>;
export type UpdatePolicyMutationResult = Apollo.MutationResult<UpdatePolicyMutation>;
export type UpdatePolicyMutationOptions = Apollo.BaseMutationOptions<
    UpdatePolicyMutation,
    UpdatePolicyMutationVariables
>;
export const DeletePolicyDocument = gql`
    mutation deletePolicy($urn: String!) {
        deletePolicy(urn: $urn)
    }
`;
export type DeletePolicyMutationFn = Apollo.MutationFunction<DeletePolicyMutation, DeletePolicyMutationVariables>;

/**
 * __useDeletePolicyMutation__
 *
 * To run a mutation, you first call `useDeletePolicyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeletePolicyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deletePolicyMutation, { data, loading, error }] = useDeletePolicyMutation({
 *   variables: {
 *      urn: // value for 'urn'
 *   },
 * });
 */
export function useDeletePolicyMutation(
    baseOptions?: Apollo.MutationHookOptions<DeletePolicyMutation, DeletePolicyMutationVariables>,
) {
    return Apollo.useMutation<DeletePolicyMutation, DeletePolicyMutationVariables>(DeletePolicyDocument, baseOptions);
}
export type DeletePolicyMutationHookResult = ReturnType<typeof useDeletePolicyMutation>;
export type DeletePolicyMutationResult = Apollo.MutationResult<DeletePolicyMutation>;
export type DeletePolicyMutationOptions = Apollo.BaseMutationOptions<
    DeletePolicyMutation,
    DeletePolicyMutationVariables
>;
