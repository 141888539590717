/* eslint-disable */
import * as Types from '../types.generated';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type AppConfigQueryVariables = Types.Exact<{ [key: string]: never }>;

export type AppConfigQuery = { __typename?: 'Query' } & {
    appConfig?: Types.Maybe<
        { __typename?: 'AppConfig' } & Pick<Types.AppConfig, 'appVersion'> & {
                policiesConfig: { __typename?: 'PoliciesConfig' } & Pick<Types.PoliciesConfig, 'enabled'> & {
                        platformPrivileges: Array<
                            { __typename?: 'Privilege' } & Pick<Types.Privilege, 'type' | 'displayName' | 'description'>
                        >;
                        resourcePrivileges: Array<
                            { __typename?: 'ResourcePrivileges' } & Pick<
                                Types.ResourcePrivileges,
                                'resourceType' | 'resourceTypeDisplayName' | 'entityType'
                            > & {
                                    privileges: Array<
                                        { __typename?: 'Privilege' } & Pick<
                                            Types.Privilege,
                                            'type' | 'displayName' | 'description'
                                        >
                                    >;
                                }
                        >;
                    };
                analyticsConfig: { __typename?: 'AnalyticsConfig' } & Pick<Types.AnalyticsConfig, 'enabled'>;
                identityManagementConfig: { __typename?: 'IdentityManagementConfig' } & Pick<
                    Types.IdentityManagementConfig,
                    'enabled'
                >;
                managedIngestionConfig: { __typename?: 'ManagedIngestionConfig' } & Pick<
                    Types.ManagedIngestionConfig,
                    'enabled'
                >;
            }
    >;
};

export type GetEntityCountsQueryVariables = Types.Exact<{
    input?: Types.Maybe<Types.EntityCountInput>;
}>;

export type GetEntityCountsQuery = { __typename?: 'Query' } & {
    getEntityCounts?: Types.Maybe<
        { __typename?: 'EntityCountResults' } & {
            counts?: Types.Maybe<
                Array<{ __typename?: 'EntityCountResult' } & Pick<Types.EntityCountResult, 'entityType' | 'count'>>
            >;
        }
    >;
};

export const AppConfigDocument = gql`
    query appConfig {
        appConfig {
            appVersion
            policiesConfig {
                enabled
                platformPrivileges {
                    type
                    displayName
                    description
                }
                resourcePrivileges {
                    resourceType
                    resourceTypeDisplayName
                    entityType
                    privileges {
                        type
                        displayName
                        description
                    }
                }
            }
            analyticsConfig {
                enabled
            }
            identityManagementConfig {
                enabled
            }
            managedIngestionConfig {
                enabled
            }
        }
    }
`;

/**
 * __useAppConfigQuery__
 *
 * To run a query within a React component, call `useAppConfigQuery` and pass it any options that fit your needs.
 * When your component renders, `useAppConfigQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAppConfigQuery({
 *   variables: {
 *   },
 * });
 */
export function useAppConfigQuery(baseOptions?: Apollo.QueryHookOptions<AppConfigQuery, AppConfigQueryVariables>) {
    return Apollo.useQuery<AppConfigQuery, AppConfigQueryVariables>(AppConfigDocument, baseOptions);
}
export function useAppConfigLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<AppConfigQuery, AppConfigQueryVariables>,
) {
    return Apollo.useLazyQuery<AppConfigQuery, AppConfigQueryVariables>(AppConfigDocument, baseOptions);
}
export type AppConfigQueryHookResult = ReturnType<typeof useAppConfigQuery>;
export type AppConfigLazyQueryHookResult = ReturnType<typeof useAppConfigLazyQuery>;
export type AppConfigQueryResult = Apollo.QueryResult<AppConfigQuery, AppConfigQueryVariables>;
export const GetEntityCountsDocument = gql`
    query getEntityCounts($input: EntityCountInput) {
        getEntityCounts(input: $input) {
            counts {
                entityType
                count
            }
        }
    }
`;

/**
 * __useGetEntityCountsQuery__
 *
 * To run a query within a React component, call `useGetEntityCountsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetEntityCountsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetEntityCountsQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetEntityCountsQuery(
    baseOptions?: Apollo.QueryHookOptions<GetEntityCountsQuery, GetEntityCountsQueryVariables>,
) {
    return Apollo.useQuery<GetEntityCountsQuery, GetEntityCountsQueryVariables>(GetEntityCountsDocument, baseOptions);
}
export function useGetEntityCountsLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<GetEntityCountsQuery, GetEntityCountsQueryVariables>,
) {
    return Apollo.useLazyQuery<GetEntityCountsQuery, GetEntityCountsQueryVariables>(
        GetEntityCountsDocument,
        baseOptions,
    );
}
export type GetEntityCountsQueryHookResult = ReturnType<typeof useGetEntityCountsQuery>;
export type GetEntityCountsLazyQueryHookResult = ReturnType<typeof useGetEntityCountsLazyQuery>;
export type GetEntityCountsQueryResult = Apollo.QueryResult<GetEntityCountsQuery, GetEntityCountsQueryVariables>;
