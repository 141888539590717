/* eslint-disable */
import * as Types from '../types.generated';

import {
    DataJobFieldsFragment,
    OwnershipFieldsFragment,
    SchemaMetadataFieldsFragment,
    PlatformFieldsFragment,
    GlobalTagsFieldsFragment,
    GlossaryTermsFragment,
    EntityDomainFragment,
    InstitutionalMemoryFieldsFragment,
    EntityContainerFragment,
    NonRecursiveDataFlowFieldsFragment,
    NonConflictingPlatformFieldsFragment,
    DashboardFieldsFragment,
} from './fragments.generated';
import { FullRelationshipResultsFragment } from './relationships.generated';
import { gql } from '@apollo/client';
import {
    DataJobFieldsFragmentDoc,
    OwnershipFieldsFragmentDoc,
    SchemaMetadataFieldsFragmentDoc,
    PlatformFieldsFragmentDoc,
    GlobalTagsFieldsFragmentDoc,
    GlossaryTermsFragmentDoc,
    EntityDomainFragmentDoc,
    InstitutionalMemoryFieldsFragmentDoc,
    EntityContainerFragmentDoc,
    NonRecursiveDataFlowFieldsFragmentDoc,
    NonConflictingPlatformFieldsFragmentDoc,
    DashboardFieldsFragmentDoc,
} from './fragments.generated';
import { FullRelationshipResultsFragmentDoc } from './relationships.generated';
import * as Apollo from '@apollo/client';
export type GetDataJobQueryVariables = Types.Exact<{
    urn: Types.Scalars['String'];
}>;

export type GetDataJobQuery = { __typename?: 'Query' } & {
    dataJob?: Types.Maybe<
        { __typename?: 'DataJob' } & {
            parentFlow?: Types.Maybe<{ __typename?: 'EntityRelationshipsResult' } & FullRelationshipResultsFragment>;
            incoming?: Types.Maybe<{ __typename?: 'EntityRelationshipsResult' } & FullRelationshipResultsFragment>;
            outgoing?: Types.Maybe<{ __typename?: 'EntityRelationshipsResult' } & FullRelationshipResultsFragment>;
        } & DataJobFieldsFragment
    >;
};

export type UpdateDataJobMutationVariables = Types.Exact<{
    urn: Types.Scalars['String'];
    input: Types.DataJobUpdateInput;
}>;

export type UpdateDataJobMutation = { __typename?: 'Mutation' } & {
    updateDataJob?: Types.Maybe<{ __typename?: 'DataJob' } & Pick<Types.DataJob, 'urn'>>;
};

export const GetDataJobDocument = gql`
    query getDataJob($urn: String!) {
        dataJob(urn: $urn) {
            ...dataJobFields
            parentFlow: relationships(input: { types: ["IsPartOf"], direction: OUTGOING, start: 0, count: 1 }) {
                ...fullRelationshipResults
            }
            incoming: relationships(
                input: {
                    types: ["DownstreamOf", "Consumes", "Produces", "TrainedBy"]
                    direction: INCOMING
                    start: 0
                    count: 100
                }
            ) {
                ...fullRelationshipResults
            }
            outgoing: relationships(
                input: { types: ["DownstreamOf", "Consumes", "Produces"], direction: OUTGOING, start: 0, count: 100 }
            ) {
                ...fullRelationshipResults
            }
        }
    }
    ${DataJobFieldsFragmentDoc}
    ${FullRelationshipResultsFragmentDoc}
`;

/**
 * __useGetDataJobQuery__
 *
 * To run a query within a React component, call `useGetDataJobQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDataJobQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDataJobQuery({
 *   variables: {
 *      urn: // value for 'urn'
 *   },
 * });
 */
export function useGetDataJobQuery(baseOptions: Apollo.QueryHookOptions<GetDataJobQuery, GetDataJobQueryVariables>) {
    return Apollo.useQuery<GetDataJobQuery, GetDataJobQueryVariables>(GetDataJobDocument, baseOptions);
}
export function useGetDataJobLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<GetDataJobQuery, GetDataJobQueryVariables>,
) {
    return Apollo.useLazyQuery<GetDataJobQuery, GetDataJobQueryVariables>(GetDataJobDocument, baseOptions);
}
export type GetDataJobQueryHookResult = ReturnType<typeof useGetDataJobQuery>;
export type GetDataJobLazyQueryHookResult = ReturnType<typeof useGetDataJobLazyQuery>;
export type GetDataJobQueryResult = Apollo.QueryResult<GetDataJobQuery, GetDataJobQueryVariables>;
export const UpdateDataJobDocument = gql`
    mutation updateDataJob($urn: String!, $input: DataJobUpdateInput!) {
        updateDataJob(urn: $urn, input: $input) {
            urn
        }
    }
`;
export type UpdateDataJobMutationFn = Apollo.MutationFunction<UpdateDataJobMutation, UpdateDataJobMutationVariables>;

/**
 * __useUpdateDataJobMutation__
 *
 * To run a mutation, you first call `useUpdateDataJobMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateDataJobMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateDataJobMutation, { data, loading, error }] = useUpdateDataJobMutation({
 *   variables: {
 *      urn: // value for 'urn'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateDataJobMutation(
    baseOptions?: Apollo.MutationHookOptions<UpdateDataJobMutation, UpdateDataJobMutationVariables>,
) {
    return Apollo.useMutation<UpdateDataJobMutation, UpdateDataJobMutationVariables>(
        UpdateDataJobDocument,
        baseOptions,
    );
}
export type UpdateDataJobMutationHookResult = ReturnType<typeof useUpdateDataJobMutation>;
export type UpdateDataJobMutationResult = Apollo.MutationResult<UpdateDataJobMutation>;
export type UpdateDataJobMutationOptions = Apollo.BaseMutationOptions<
    UpdateDataJobMutation,
    UpdateDataJobMutationVariables
>;
