/* eslint-disable */
import * as Types from '../types.generated';

import { NonRecursiveMlModelFragment } from './fragments.generated';
import { FullRelationshipResultsFragment } from './relationships.generated';
import { gql } from '@apollo/client';
import { NonRecursiveMlModelFragmentDoc } from './fragments.generated';
import { FullRelationshipResultsFragmentDoc } from './relationships.generated';
import * as Apollo from '@apollo/client';
export type GetMlModelQueryVariables = Types.Exact<{
    urn: Types.Scalars['String'];
}>;

export type GetMlModelQuery = { __typename?: 'Query' } & {
    mlModel?: Types.Maybe<
        { __typename?: 'MLModel' } & {
            incoming?: Types.Maybe<{ __typename?: 'EntityRelationshipsResult' } & FullRelationshipResultsFragment>;
            outgoing?: Types.Maybe<{ __typename?: 'EntityRelationshipsResult' } & FullRelationshipResultsFragment>;
        } & NonRecursiveMlModelFragment
    >;
};

export const GetMlModelDocument = gql`
    query getMLModel($urn: String!) {
        mlModel(urn: $urn) {
            ...nonRecursiveMLModel
            incoming: relationships(
                input: {
                    types: ["DownstreamOf", "Consumes", "Produces", "TrainedBy", "MemberOf"]
                    direction: INCOMING
                    start: 0
                    count: 100
                }
            ) {
                ...fullRelationshipResults
            }
            outgoing: relationships(
                input: {
                    types: ["DownstreamOf", "Consumes", "Produces", "TrainedBy", "MemberOf"]
                    direction: OUTGOING
                    start: 0
                    count: 100
                }
            ) {
                ...fullRelationshipResults
            }
        }
    }
    ${NonRecursiveMlModelFragmentDoc}
    ${FullRelationshipResultsFragmentDoc}
`;

/**
 * __useGetMlModelQuery__
 *
 * To run a query within a React component, call `useGetMlModelQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMlModelQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMlModelQuery({
 *   variables: {
 *      urn: // value for 'urn'
 *   },
 * });
 */
export function useGetMlModelQuery(baseOptions: Apollo.QueryHookOptions<GetMlModelQuery, GetMlModelQueryVariables>) {
    return Apollo.useQuery<GetMlModelQuery, GetMlModelQueryVariables>(GetMlModelDocument, baseOptions);
}
export function useGetMlModelLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<GetMlModelQuery, GetMlModelQueryVariables>,
) {
    return Apollo.useLazyQuery<GetMlModelQuery, GetMlModelQueryVariables>(GetMlModelDocument, baseOptions);
}
export type GetMlModelQueryHookResult = ReturnType<typeof useGetMlModelQuery>;
export type GetMlModelLazyQueryHookResult = ReturnType<typeof useGetMlModelLazyQuery>;
export type GetMlModelQueryResult = Apollo.QueryResult<GetMlModelQuery, GetMlModelQueryVariables>;
