import React from 'react';
import { Alert } from 'antd';
import { HomePageHeader } from './HomePageHeader';
import { HomePageBody } from './HomePageBody';

export const HomePage = () => {
    // prettier-ignore
    return (
        <>
           {document.location && document.location.href.includes('staging') && <Alert
                message="Environment Notes"
                description={<div>This is the Development environment. Please access to <a href='https://dataportal.nedigital.sg/'>https://dataportal.nedigital.sg/</a> for the Production environment.</div>}
                type="info"
                showIcon
                closable
            />
           }
            <HomePageHeader />
            <HomePageBody />
        </>
    );
};
