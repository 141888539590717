/* eslint-disable */
import * as Types from '../types.generated';

import { gql } from '@apollo/client';
export type GlobalTagsFieldsFragment = { __typename?: 'GlobalTags' } & {
    tags?: Types.Maybe<
        Array<
            { __typename?: 'TagAssociation' } & {
                tag: { __typename?: 'Tag' } & Pick<Types.Tag, 'urn' | 'name' | 'description'>;
            }
        >
    >;
};

export type GlossaryTermsFragment = { __typename?: 'GlossaryTerms' } & {
    terms?: Types.Maybe<
        Array<
            { __typename?: 'GlossaryTermAssociation' } & {
                term: { __typename?: 'GlossaryTerm' } & Pick<Types.GlossaryTerm, 'urn' | 'name'>;
            }
        >
    >;
};

export type OwnershipFieldsFragment = { __typename?: 'Ownership' } & {
    owners?: Types.Maybe<
        Array<
            { __typename?: 'Owner' } & Pick<Types.Owner, 'type'> & {
                    owner:
                        | ({ __typename?: 'CorpUser' } & Pick<Types.CorpUser, 'urn' | 'type' | 'username'> & {
                                  info?: Types.Maybe<
                                      { __typename?: 'CorpUserInfo' } & Pick<
                                          Types.CorpUserInfo,
                                          | 'active'
                                          | 'displayName'
                                          | 'title'
                                          | 'email'
                                          | 'firstName'
                                          | 'lastName'
                                          | 'fullName'
                                      >
                                  >;
                                  editableInfo?: Types.Maybe<
                                      { __typename?: 'CorpUserEditableInfo' } & Pick<
                                          Types.CorpUserEditableInfo,
                                          'pictureLink'
                                      >
                                  >;
                              })
                        | ({ __typename?: 'CorpGroup' } & Pick<Types.CorpGroup, 'urn' | 'type' | 'name'> & {
                                  info?: Types.Maybe<
                                      { __typename?: 'CorpGroupInfo' } & Pick<
                                          Types.CorpGroupInfo,
                                          'displayName' | 'email' | 'groups'
                                      > & {
                                              admins?: Types.Maybe<
                                                  Array<
                                                      { __typename?: 'CorpUser' } & Pick<
                                                          Types.CorpUser,
                                                          'urn' | 'username'
                                                      > & {
                                                              info?: Types.Maybe<
                                                                  { __typename?: 'CorpUserInfo' } & Pick<
                                                                      Types.CorpUserInfo,
                                                                      | 'active'
                                                                      | 'displayName'
                                                                      | 'title'
                                                                      | 'email'
                                                                      | 'firstName'
                                                                      | 'lastName'
                                                                      | 'fullName'
                                                                  >
                                                              >;
                                                              editableInfo?: Types.Maybe<
                                                                  { __typename?: 'CorpUserEditableInfo' } & Pick<
                                                                      Types.CorpUserEditableInfo,
                                                                      'pictureLink' | 'teams' | 'skills'
                                                                  >
                                                              >;
                                                          }
                                                  >
                                              >;
                                              members?: Types.Maybe<
                                                  Array<
                                                      { __typename?: 'CorpUser' } & Pick<
                                                          Types.CorpUser,
                                                          'urn' | 'username'
                                                      > & {
                                                              info?: Types.Maybe<
                                                                  { __typename?: 'CorpUserInfo' } & Pick<
                                                                      Types.CorpUserInfo,
                                                                      | 'active'
                                                                      | 'displayName'
                                                                      | 'title'
                                                                      | 'email'
                                                                      | 'firstName'
                                                                      | 'lastName'
                                                                      | 'fullName'
                                                                  >
                                                              >;
                                                              editableInfo?: Types.Maybe<
                                                                  { __typename?: 'CorpUserEditableInfo' } & Pick<
                                                                      Types.CorpUserEditableInfo,
                                                                      'pictureLink' | 'teams' | 'skills'
                                                                  >
                                                              >;
                                                          }
                                                  >
                                              >;
                                          }
                                  >;
                              });
                }
        >
    >;
    lastModified: { __typename?: 'AuditStamp' } & Pick<Types.AuditStamp, 'time'>;
};

export type InstitutionalMemoryFieldsFragment = { __typename?: 'InstitutionalMemory' } & {
    elements: Array<
        { __typename?: 'InstitutionalMemoryMetadata' } & Pick<
            Types.InstitutionalMemoryMetadata,
            'url' | 'description'
        > & {
                author: { __typename?: 'CorpUser' } & Pick<Types.CorpUser, 'urn' | 'username'>;
                created: { __typename?: 'AuditStamp' } & Pick<Types.AuditStamp, 'actor' | 'time'>;
            }
    >;
};

export type NonRecursiveDatasetFieldsFragment = { __typename?: 'Dataset' } & Pick<
    Types.Dataset,
    'urn' | 'name' | 'type' | 'origin' | 'uri' | 'platformNativeType'
> & {
        platform: { __typename?: 'DataPlatform' } & PlatformFieldsFragment;
        properties?: Types.Maybe<
            { __typename?: 'DatasetProperties' } & Pick<Types.DatasetProperties, 'description' | 'externalUrl'> & {
                    customProperties?: Types.Maybe<
                        Array<{ __typename?: 'StringMapEntry' } & Pick<Types.StringMapEntry, 'key' | 'value'>>
                    >;
                }
        >;
        editableProperties?: Types.Maybe<
            { __typename?: 'DatasetEditableProperties' } & Pick<Types.DatasetEditableProperties, 'description'>
        >;
        ownership?: Types.Maybe<{ __typename?: 'Ownership' } & OwnershipFieldsFragment>;
        institutionalMemory?: Types.Maybe<{ __typename?: 'InstitutionalMemory' } & InstitutionalMemoryFieldsFragment>;
        schemaMetadata?: Types.Maybe<{ __typename?: 'SchemaMetadata' } & SchemaMetadataFieldsFragment>;
        previousSchemaMetadata?: Types.Maybe<{ __typename?: 'SchemaMetadata' } & SchemaMetadataFieldsFragment>;
        editableSchemaMetadata?: Types.Maybe<
            { __typename?: 'EditableSchemaMetadata' } & {
                editableSchemaFieldInfo: Array<
                    { __typename?: 'EditableSchemaFieldInfo' } & Pick<
                        Types.EditableSchemaFieldInfo,
                        'fieldPath' | 'description'
                    > & { globalTags?: Types.Maybe<{ __typename?: 'GlobalTags' } & GlobalTagsFieldsFragment> }
                >;
            }
        >;
        deprecation?: Types.Maybe<
            { __typename?: 'Deprecation' } & Pick<
                Types.Deprecation,
                'actor' | 'deprecated' | 'note' | 'decommissionTime'
            >
        >;
        globalTags?: Types.Maybe<{ __typename?: 'GlobalTags' } & GlobalTagsFieldsFragment>;
        glossaryTerms?: Types.Maybe<{ __typename?: 'GlossaryTerms' } & GlossaryTermsFragment>;
        subTypes?: Types.Maybe<{ __typename?: 'SubTypes' } & Pick<Types.SubTypes, 'typeNames'>>;
        domain?: Types.Maybe<{ __typename?: 'Domain' } & EntityDomainFragment>;
        container?: Types.Maybe<{ __typename?: 'Container' } & EntityContainerFragment>;
    };

export type NonRecursiveDataFlowFieldsFragment = { __typename?: 'DataFlow' } & Pick<
    Types.DataFlow,
    'urn' | 'type' | 'orchestrator' | 'flowId' | 'cluster'
> & {
        properties?: Types.Maybe<
            { __typename?: 'DataFlowProperties' } & Pick<
                Types.DataFlowProperties,
                'name' | 'description' | 'project' | 'externalUrl'
            > & {
                    customProperties?: Types.Maybe<
                        Array<{ __typename?: 'StringMapEntry' } & Pick<Types.StringMapEntry, 'key' | 'value'>>
                    >;
                }
        >;
        editableProperties?: Types.Maybe<
            { __typename?: 'DataFlowEditableProperties' } & Pick<Types.DataFlowEditableProperties, 'description'>
        >;
        ownership?: Types.Maybe<{ __typename?: 'Ownership' } & OwnershipFieldsFragment>;
        platform: { __typename?: 'DataPlatform' } & PlatformFieldsFragment;
        domain?: Types.Maybe<{ __typename?: 'Domain' } & EntityDomainFragment>;
    };

export type NonRecursiveDataJobFieldsFragment = { __typename?: 'DataJob' } & Pick<Types.DataJob, 'urn'> & {
        properties?: Types.Maybe<
            { __typename?: 'DataJobProperties' } & Pick<
                Types.DataJobProperties,
                'name' | 'description' | 'externalUrl'
            > & {
                    customProperties?: Types.Maybe<
                        Array<{ __typename?: 'StringMapEntry' } & Pick<Types.StringMapEntry, 'key' | 'value'>>
                    >;
                }
        >;
        globalTags?: Types.Maybe<{ __typename?: 'GlobalTags' } & GlobalTagsFieldsFragment>;
        domain?: Types.Maybe<{ __typename?: 'Domain' } & EntityDomainFragment>;
    };

export type DataJobFieldsFragment = { __typename?: 'DataJob' } & Pick<Types.DataJob, 'urn' | 'type' | 'jobId'> & {
        dataFlow?: Types.Maybe<{ __typename?: 'DataFlow' } & NonRecursiveDataFlowFieldsFragment>;
        ownership?: Types.Maybe<{ __typename?: 'Ownership' } & OwnershipFieldsFragment>;
        inputOutput?: Types.Maybe<
            { __typename?: 'DataJobInputOutput' } & {
                inputDatasets?: Types.Maybe<Array<{ __typename?: 'Dataset' } & NonRecursiveDatasetFieldsFragment>>;
                outputDatasets?: Types.Maybe<Array<{ __typename?: 'Dataset' } & NonRecursiveDatasetFieldsFragment>>;
                inputDatajobs?: Types.Maybe<Array<{ __typename?: 'DataJob' } & NonRecursiveDataJobFieldsFragment>>;
            }
        >;
        properties?: Types.Maybe<
            { __typename?: 'DataJobProperties' } & Pick<
                Types.DataJobProperties,
                'name' | 'description' | 'externalUrl'
            > & {
                    customProperties?: Types.Maybe<
                        Array<{ __typename?: 'StringMapEntry' } & Pick<Types.StringMapEntry, 'key' | 'value'>>
                    >;
                }
        >;
        editableProperties?: Types.Maybe<
            { __typename?: 'DataJobEditableProperties' } & Pick<Types.DataJobEditableProperties, 'description'>
        >;
        globalTags?: Types.Maybe<{ __typename?: 'GlobalTags' } & GlobalTagsFieldsFragment>;
        institutionalMemory?: Types.Maybe<{ __typename?: 'InstitutionalMemory' } & InstitutionalMemoryFieldsFragment>;
        glossaryTerms?: Types.Maybe<{ __typename?: 'GlossaryTerms' } & GlossaryTermsFragment>;
        domain?: Types.Maybe<{ __typename?: 'Domain' } & EntityDomainFragment>;
    };

export type DashboardFieldsFragment = { __typename?: 'Dashboard' } & Pick<
    Types.Dashboard,
    'urn' | 'type' | 'tool' | 'dashboardId'
> & {
        properties?: Types.Maybe<
            { __typename?: 'DashboardProperties' } & Pick<
                Types.DashboardProperties,
                'name' | 'description' | 'externalUrl' | 'access' | 'lastRefreshed'
            > & {
                    customProperties?: Types.Maybe<
                        Array<{ __typename?: 'StringMapEntry' } & Pick<Types.StringMapEntry, 'key' | 'value'>>
                    >;
                    created: { __typename?: 'AuditStamp' } & Pick<Types.AuditStamp, 'time'>;
                    lastModified: { __typename?: 'AuditStamp' } & Pick<Types.AuditStamp, 'time'>;
                }
        >;
        ownership?: Types.Maybe<{ __typename?: 'Ownership' } & OwnershipFieldsFragment>;
        globalTags?: Types.Maybe<{ __typename?: 'GlobalTags' } & GlobalTagsFieldsFragment>;
        institutionalMemory?: Types.Maybe<{ __typename?: 'InstitutionalMemory' } & InstitutionalMemoryFieldsFragment>;
        glossaryTerms?: Types.Maybe<{ __typename?: 'GlossaryTerms' } & GlossaryTermsFragment>;
        platform: { __typename?: 'DataPlatform' } & PlatformFieldsFragment;
        domain?: Types.Maybe<{ __typename?: 'Domain' } & EntityDomainFragment>;
        container?: Types.Maybe<{ __typename?: 'Container' } & EntityContainerFragment>;
    };

export type NonRecursiveMlFeatureFragment = { __typename?: 'MLFeature' } & Pick<
    Types.MlFeature,
    'urn' | 'type' | 'name' | 'featureNamespace' | 'description' | 'dataType'
> & {
        featureProperties?: Types.Maybe<
            { __typename?: 'MLFeatureProperties' } & Pick<Types.MlFeatureProperties, 'description' | 'dataType'> & {
                    version?: Types.Maybe<{ __typename?: 'VersionTag' } & Pick<Types.VersionTag, 'versionTag'>>;
                    sources?: Types.Maybe<
                        Array<
                            Types.Maybe<
                                { __typename?: 'Dataset' } & Pick<
                                    Types.Dataset,
                                    'urn' | 'name' | 'type' | 'origin' | 'description' | 'uri' | 'platformNativeType'
                                > & { platform: { __typename?: 'DataPlatform' } & PlatformFieldsFragment }
                            >
                        >
                    >;
                }
        >;
        ownership?: Types.Maybe<{ __typename?: 'Ownership' } & OwnershipFieldsFragment>;
        institutionalMemory?: Types.Maybe<{ __typename?: 'InstitutionalMemory' } & InstitutionalMemoryFieldsFragment>;
        status?: Types.Maybe<{ __typename?: 'Status' } & Pick<Types.Status, 'removed'>>;
    };

export type NonRecursiveMlPrimaryKeyFragment = { __typename?: 'MLPrimaryKey' } & Pick<
    Types.MlPrimaryKey,
    'urn' | 'type' | 'name' | 'featureNamespace' | 'description' | 'dataType'
> & {
        primaryKeyProperties?: Types.Maybe<
            { __typename?: 'MLPrimaryKeyProperties' } & Pick<
                Types.MlPrimaryKeyProperties,
                'description' | 'dataType'
            > & {
                    version?: Types.Maybe<{ __typename?: 'VersionTag' } & Pick<Types.VersionTag, 'versionTag'>>;
                    sources?: Types.Maybe<
                        Array<
                            Types.Maybe<
                                { __typename?: 'Dataset' } & Pick<
                                    Types.Dataset,
                                    'urn' | 'name' | 'type' | 'origin' | 'description' | 'uri' | 'platformNativeType'
                                > & { platform: { __typename?: 'DataPlatform' } & PlatformFieldsFragment }
                            >
                        >
                    >;
                }
        >;
        ownership?: Types.Maybe<{ __typename?: 'Ownership' } & OwnershipFieldsFragment>;
        institutionalMemory?: Types.Maybe<{ __typename?: 'InstitutionalMemory' } & InstitutionalMemoryFieldsFragment>;
        status?: Types.Maybe<{ __typename?: 'Status' } & Pick<Types.Status, 'removed'>>;
    };

export type NonRecursiveMlFeatureTableFragment = { __typename?: 'MLFeatureTable' } & Pick<
    Types.MlFeatureTable,
    'urn' | 'type' | 'name' | 'description'
> & {
        platform: { __typename?: 'DataPlatform' } & PlatformFieldsFragment;
        featureTableProperties?: Types.Maybe<
            { __typename?: 'MLFeatureTableProperties' } & Pick<Types.MlFeatureTableProperties, 'description'> & {
                    mlFeatures?: Types.Maybe<
                        Array<Types.Maybe<{ __typename?: 'MLFeature' } & NonRecursiveMlFeatureFragment>>
                    >;
                    mlPrimaryKeys?: Types.Maybe<
                        Array<Types.Maybe<{ __typename?: 'MLPrimaryKey' } & NonRecursiveMlPrimaryKeyFragment>>
                    >;
                }
        >;
        ownership?: Types.Maybe<{ __typename?: 'Ownership' } & OwnershipFieldsFragment>;
        institutionalMemory?: Types.Maybe<{ __typename?: 'InstitutionalMemory' } & InstitutionalMemoryFieldsFragment>;
        status?: Types.Maybe<{ __typename?: 'Status' } & Pick<Types.Status, 'removed'>>;
    };

export type SchemaMetadataFieldsFragment = { __typename?: 'SchemaMetadata' } & Pick<
    Types.SchemaMetadata,
    | 'aspectVersion'
    | 'createdAt'
    | 'datasetUrn'
    | 'name'
    | 'platformUrn'
    | 'version'
    | 'cluster'
    | 'hash'
    | 'primaryKeys'
> & {
        platformSchema?: Types.Maybe<
            | ({ __typename?: 'TableSchema' } & Pick<Types.TableSchema, 'schema'>)
            | ({ __typename?: 'KeyValueSchema' } & Pick<Types.KeyValueSchema, 'keySchema' | 'valueSchema'>)
        >;
        fields: Array<
            { __typename?: 'SchemaField' } & Pick<
                Types.SchemaField,
                | 'fieldPath'
                | 'jsonPath'
                | 'nullable'
                | 'description'
                | 'type'
                | 'nativeDataType'
                | 'recursive'
                | 'isPartOfKey'
            > & {
                    globalTags?: Types.Maybe<{ __typename?: 'GlobalTags' } & GlobalTagsFieldsFragment>;
                    glossaryTerms?: Types.Maybe<{ __typename?: 'GlossaryTerms' } & GlossaryTermsFragment>;
                }
        >;
        foreignKeys?: Types.Maybe<
            Array<
                Types.Maybe<
                    { __typename?: 'ForeignKeyConstraint' } & Pick<Types.ForeignKeyConstraint, 'name'> & {
                            sourceFields?: Types.Maybe<
                                Array<
                                    Types.Maybe<
                                        { __typename?: 'SchemaFieldEntity' } & Pick<
                                            Types.SchemaFieldEntity,
                                            'fieldPath'
                                        >
                                    >
                                >
                            >;
                            foreignFields?: Types.Maybe<
                                Array<
                                    Types.Maybe<
                                        { __typename?: 'SchemaFieldEntity' } & Pick<
                                            Types.SchemaFieldEntity,
                                            'fieldPath'
                                        >
                                    >
                                >
                            >;
                            foreignDataset?: Types.Maybe<
                                { __typename?: 'Dataset' } & Pick<
                                    Types.Dataset,
                                    'urn' | 'name' | 'type' | 'origin' | 'uri' | 'platformNativeType'
                                > & {
                                        properties?: Types.Maybe<
                                            { __typename?: 'DatasetProperties' } & Pick<
                                                Types.DatasetProperties,
                                                'description'
                                            >
                                        >;
                                        platform: { __typename?: 'DataPlatform' } & PlatformFieldsFragment;
                                        ownership?: Types.Maybe<{ __typename?: 'Ownership' } & OwnershipFieldsFragment>;
                                        globalTags?: Types.Maybe<
                                            { __typename?: 'GlobalTags' } & GlobalTagsFieldsFragment
                                        >;
                                        glossaryTerms?: Types.Maybe<
                                            { __typename?: 'GlossaryTerms' } & GlossaryTermsFragment
                                        >;
                                    }
                            >;
                        }
                >
            >
        >;
    };

export type NonRecursiveMlModelFragment = { __typename?: 'MLModel' } & Pick<
    Types.MlModel,
    'urn' | 'type' | 'name' | 'description' | 'origin'
> & {
        platform: { __typename?: 'DataPlatform' } & PlatformFieldsFragment;
        ownership?: Types.Maybe<{ __typename?: 'Ownership' } & OwnershipFieldsFragment>;
        properties?: Types.Maybe<
            { __typename?: 'MLModelProperties' } & Pick<
                Types.MlModelProperties,
                'description' | 'date' | 'version' | 'type' | 'mlFeatures'
            > & {
                    trainingMetrics?: Types.Maybe<
                        Array<
                            Types.Maybe<
                                { __typename?: 'MLMetric' } & Pick<Types.MlMetric, 'name' | 'description' | 'value'>
                            >
                        >
                    >;
                    hyperParams?: Types.Maybe<
                        Array<
                            Types.Maybe<
                                { __typename?: 'MLHyperParam' } & Pick<
                                    Types.MlHyperParam,
                                    'name' | 'description' | 'value'
                                >
                            >
                        >
                    >;
                    groups?: Types.Maybe<
                        Array<
                            Types.Maybe<
                                { __typename?: 'MLModelGroup' } & Pick<
                                    Types.MlModelGroup,
                                    'urn' | 'name' | 'description'
                                >
                            >
                        >
                    >;
                    customProperties?: Types.Maybe<
                        Array<{ __typename?: 'StringMapEntry' } & Pick<Types.StringMapEntry, 'key' | 'value'>>
                    >;
                }
        >;
        globalTags?: Types.Maybe<{ __typename?: 'GlobalTags' } & GlobalTagsFieldsFragment>;
    };

export type NonRecursiveMlModelGroupFieldsFragment = { __typename?: 'MLModelGroup' } & Pick<
    Types.MlModelGroup,
    'urn' | 'type' | 'name' | 'description' | 'origin'
> & {
        platform: { __typename?: 'DataPlatform' } & PlatformFieldsFragment;
        ownership?: Types.Maybe<{ __typename?: 'Ownership' } & OwnershipFieldsFragment>;
    };

export type PlatformFieldsFragment = { __typename?: 'DataPlatform' } & Pick<
    Types.DataPlatform,
    'urn' | 'type' | 'name' | 'displayName'
> & {
        properties?: Types.Maybe<
            { __typename?: 'DataPlatformProperties' } & Pick<
                Types.DataPlatformProperties,
                'type' | 'displayName' | 'datasetNameDelimiter' | 'logoUrl'
            >
        >;
        info?: Types.Maybe<
            { __typename?: 'DataPlatformInfo' } & Pick<
                Types.DataPlatformInfo,
                'type' | 'displayName' | 'datasetNameDelimiter' | 'logoUrl'
            >
        >;
    };

export type NonConflictingPlatformFieldsFragment = { __typename?: 'DataPlatform' } & Pick<
    Types.DataPlatform,
    'urn' | 'type' | 'name' | 'displayName'
> & {
        properties?: Types.Maybe<
            { __typename?: 'DataPlatformProperties' } & Pick<
                Types.DataPlatformProperties,
                'displayName' | 'datasetNameDelimiter' | 'logoUrl'
            >
        >;
        info?: Types.Maybe<
            { __typename?: 'DataPlatformInfo' } & Pick<
                Types.DataPlatformInfo,
                'type' | 'displayName' | 'datasetNameDelimiter' | 'logoUrl'
            >
        >;
    };

export type EntityContainerFragment = { __typename?: 'Container' } & Pick<Types.Container, 'urn'> & {
        platform: { __typename?: 'DataPlatform' } & PlatformFieldsFragment;
        properties?: Types.Maybe<{ __typename?: 'ContainerProperties' } & Pick<Types.ContainerProperties, 'name'>>;
        subTypes?: Types.Maybe<{ __typename?: 'SubTypes' } & Pick<Types.SubTypes, 'typeNames'>>;
    };

export type EntityDomainFragment = { __typename?: 'Domain' } & Pick<Types.Domain, 'urn'> & {
        properties?: Types.Maybe<{ __typename?: 'DomainProperties' } & Pick<Types.DomainProperties, 'name'>>;
    };

export const OwnershipFieldsFragmentDoc = gql`
    fragment ownershipFields on Ownership {
        owners {
            owner {
                ... on CorpUser {
                    urn
                    type
                    username
                    info {
                        active
                        displayName
                        title
                        email
                        firstName
                        lastName
                        fullName
                    }
                    editableInfo {
                        pictureLink
                    }
                }
                ... on CorpGroup {
                    urn
                    type
                    name
                    info {
                        displayName
                        email
                        admins {
                            urn
                            username
                            info {
                                active
                                displayName
                                title
                                email
                                firstName
                                lastName
                                fullName
                            }
                            editableInfo {
                                pictureLink
                                teams
                                skills
                            }
                        }
                        members {
                            urn
                            username
                            info {
                                active
                                displayName
                                title
                                email
                                firstName
                                lastName
                                fullName
                            }
                            editableInfo {
                                pictureLink
                                teams
                                skills
                            }
                        }
                        groups
                    }
                }
            }
            type
        }
        lastModified {
            time
        }
    }
`;
export const PlatformFieldsFragmentDoc = gql`
    fragment platformFields on DataPlatform {
        urn
        type
        name
        properties {
            type
            displayName
            datasetNameDelimiter
            logoUrl
        }
        displayName
        info {
            type
            displayName
            datasetNameDelimiter
            logoUrl
        }
    }
`;
export const EntityDomainFragmentDoc = gql`
    fragment entityDomain on Domain {
        urn
        properties {
            name
        }
    }
`;
export const NonRecursiveDataFlowFieldsFragmentDoc = gql`
    fragment nonRecursiveDataFlowFields on DataFlow {
        urn
        type
        orchestrator
        flowId
        cluster
        properties {
            name
            description
            project
            externalUrl
            customProperties {
                key
                value
            }
        }
        editableProperties {
            description
        }
        ownership {
            ...ownershipFields
        }
        platform {
            ...platformFields
        }
        domain {
            ...entityDomain
        }
    }
    ${OwnershipFieldsFragmentDoc}
    ${PlatformFieldsFragmentDoc}
    ${EntityDomainFragmentDoc}
`;
export const InstitutionalMemoryFieldsFragmentDoc = gql`
    fragment institutionalMemoryFields on InstitutionalMemory {
        elements {
            url
            author {
                urn
                username
            }
            description
            created {
                actor
                time
            }
        }
    }
`;
export const GlobalTagsFieldsFragmentDoc = gql`
    fragment globalTagsFields on GlobalTags {
        tags {
            tag {
                urn
                name
                description
            }
        }
    }
`;
export const GlossaryTermsFragmentDoc = gql`
    fragment glossaryTerms on GlossaryTerms {
        terms {
            term {
                urn
                name
            }
        }
    }
`;
export const SchemaMetadataFieldsFragmentDoc = gql`
    fragment schemaMetadataFields on SchemaMetadata {
        aspectVersion
        createdAt
        datasetUrn
        name
        platformUrn
        version
        cluster
        hash
        platformSchema {
            ... on TableSchema {
                schema
            }
            ... on KeyValueSchema {
                keySchema
                valueSchema
            }
        }
        fields {
            fieldPath
            jsonPath
            nullable
            description
            type
            nativeDataType
            recursive
            isPartOfKey
            globalTags {
                ...globalTagsFields
            }
            glossaryTerms {
                ...glossaryTerms
            }
        }
        primaryKeys
        foreignKeys {
            name
            sourceFields {
                fieldPath
            }
            foreignFields {
                fieldPath
            }
            foreignDataset {
                urn
                name
                type
                origin
                uri
                properties {
                    description
                }
                platform {
                    ...platformFields
                }
                platformNativeType
                ownership {
                    ...ownershipFields
                }
                globalTags {
                    ...globalTagsFields
                }
                glossaryTerms {
                    ...glossaryTerms
                }
            }
        }
    }
    ${GlobalTagsFieldsFragmentDoc}
    ${GlossaryTermsFragmentDoc}
    ${PlatformFieldsFragmentDoc}
    ${OwnershipFieldsFragmentDoc}
`;
export const EntityContainerFragmentDoc = gql`
    fragment entityContainer on Container {
        urn
        platform {
            ...platformFields
        }
        properties {
            name
        }
        subTypes {
            typeNames
        }
    }
    ${PlatformFieldsFragmentDoc}
`;
export const NonRecursiveDatasetFieldsFragmentDoc = gql`
    fragment nonRecursiveDatasetFields on Dataset {
        urn
        name
        type
        origin
        uri
        platform {
            ...platformFields
        }
        platformNativeType
        properties {
            description
            customProperties {
                key
                value
            }
            externalUrl
        }
        editableProperties {
            description
        }
        ownership {
            ...ownershipFields
        }
        institutionalMemory {
            ...institutionalMemoryFields
        }
        schemaMetadata(version: 0) {
            ...schemaMetadataFields
        }
        previousSchemaMetadata: schemaMetadata(version: -1) {
            ...schemaMetadataFields
        }
        editableSchemaMetadata {
            editableSchemaFieldInfo {
                fieldPath
                description
                globalTags {
                    ...globalTagsFields
                }
            }
        }
        deprecation {
            actor
            deprecated
            note
            decommissionTime
        }
        globalTags {
            ...globalTagsFields
        }
        glossaryTerms {
            ...glossaryTerms
        }
        subTypes {
            typeNames
        }
        domain {
            ...entityDomain
        }
        container {
            ...entityContainer
        }
    }
    ${PlatformFieldsFragmentDoc}
    ${OwnershipFieldsFragmentDoc}
    ${InstitutionalMemoryFieldsFragmentDoc}
    ${SchemaMetadataFieldsFragmentDoc}
    ${GlobalTagsFieldsFragmentDoc}
    ${GlossaryTermsFragmentDoc}
    ${EntityDomainFragmentDoc}
    ${EntityContainerFragmentDoc}
`;
export const NonRecursiveDataJobFieldsFragmentDoc = gql`
    fragment nonRecursiveDataJobFields on DataJob {
        urn
        properties {
            name
            description
            externalUrl
            customProperties {
                key
                value
            }
        }
        globalTags {
            ...globalTagsFields
        }
        domain {
            ...entityDomain
        }
    }
    ${GlobalTagsFieldsFragmentDoc}
    ${EntityDomainFragmentDoc}
`;
export const DataJobFieldsFragmentDoc = gql`
    fragment dataJobFields on DataJob {
        urn
        type
        dataFlow {
            ...nonRecursiveDataFlowFields
        }
        jobId
        ownership {
            ...ownershipFields
        }
        inputOutput {
            inputDatasets {
                ...nonRecursiveDatasetFields
            }
            outputDatasets {
                ...nonRecursiveDatasetFields
            }
            inputDatajobs {
                ...nonRecursiveDataJobFields
            }
        }
        properties {
            name
            description
            externalUrl
            customProperties {
                key
                value
            }
        }
        editableProperties {
            description
        }
        globalTags {
            ...globalTagsFields
        }
        institutionalMemory {
            ...institutionalMemoryFields
        }
        glossaryTerms {
            ...glossaryTerms
        }
        domain {
            ...entityDomain
        }
    }
    ${NonRecursiveDataFlowFieldsFragmentDoc}
    ${OwnershipFieldsFragmentDoc}
    ${NonRecursiveDatasetFieldsFragmentDoc}
    ${NonRecursiveDataJobFieldsFragmentDoc}
    ${GlobalTagsFieldsFragmentDoc}
    ${InstitutionalMemoryFieldsFragmentDoc}
    ${GlossaryTermsFragmentDoc}
    ${EntityDomainFragmentDoc}
`;
export const DashboardFieldsFragmentDoc = gql`
    fragment dashboardFields on Dashboard {
        urn
        type
        tool
        dashboardId
        properties {
            name
            description
            customProperties {
                key
                value
            }
            externalUrl
            access
            lastRefreshed
            created {
                time
            }
            lastModified {
                time
            }
        }
        ownership {
            ...ownershipFields
        }
        globalTags {
            ...globalTagsFields
        }
        institutionalMemory {
            ...institutionalMemoryFields
        }
        glossaryTerms {
            ...glossaryTerms
        }
        platform {
            ...platformFields
        }
        domain {
            ...entityDomain
        }
        container {
            ...entityContainer
        }
    }
    ${OwnershipFieldsFragmentDoc}
    ${GlobalTagsFieldsFragmentDoc}
    ${InstitutionalMemoryFieldsFragmentDoc}
    ${GlossaryTermsFragmentDoc}
    ${PlatformFieldsFragmentDoc}
    ${EntityDomainFragmentDoc}
    ${EntityContainerFragmentDoc}
`;
export const NonRecursiveMlFeatureFragmentDoc = gql`
    fragment nonRecursiveMLFeature on MLFeature {
        urn
        type
        name
        featureNamespace
        description
        dataType
        featureProperties {
            description
            dataType
            version {
                versionTag
            }
            sources {
                urn
                name
                type
                origin
                description
                uri
                platform {
                    ...platformFields
                }
                platformNativeType
            }
        }
        ownership {
            ...ownershipFields
        }
        institutionalMemory {
            ...institutionalMemoryFields
        }
        status {
            removed
        }
    }
    ${PlatformFieldsFragmentDoc}
    ${OwnershipFieldsFragmentDoc}
    ${InstitutionalMemoryFieldsFragmentDoc}
`;
export const NonRecursiveMlPrimaryKeyFragmentDoc = gql`
    fragment nonRecursiveMLPrimaryKey on MLPrimaryKey {
        urn
        type
        name
        featureNamespace
        description
        dataType
        primaryKeyProperties {
            description
            dataType
            version {
                versionTag
            }
            sources {
                urn
                name
                type
                origin
                description
                uri
                platform {
                    ...platformFields
                }
                platformNativeType
            }
        }
        ownership {
            ...ownershipFields
        }
        institutionalMemory {
            ...institutionalMemoryFields
        }
        status {
            removed
        }
    }
    ${PlatformFieldsFragmentDoc}
    ${OwnershipFieldsFragmentDoc}
    ${InstitutionalMemoryFieldsFragmentDoc}
`;
export const NonRecursiveMlFeatureTableFragmentDoc = gql`
    fragment nonRecursiveMLFeatureTable on MLFeatureTable {
        urn
        type
        name
        platform {
            ...platformFields
        }
        description
        featureTableProperties {
            description
            mlFeatures {
                ...nonRecursiveMLFeature
            }
            mlPrimaryKeys {
                ...nonRecursiveMLPrimaryKey
            }
        }
        ownership {
            ...ownershipFields
        }
        institutionalMemory {
            ...institutionalMemoryFields
        }
        status {
            removed
        }
    }
    ${PlatformFieldsFragmentDoc}
    ${NonRecursiveMlFeatureFragmentDoc}
    ${NonRecursiveMlPrimaryKeyFragmentDoc}
    ${OwnershipFieldsFragmentDoc}
    ${InstitutionalMemoryFieldsFragmentDoc}
`;
export const NonRecursiveMlModelFragmentDoc = gql`
    fragment nonRecursiveMLModel on MLModel {
        urn
        type
        name
        description
        origin
        platform {
            ...platformFields
        }
        ownership {
            ...ownershipFields
        }
        properties {
            description
            date
            version
            type
            trainingMetrics {
                name
                description
                value
            }
            hyperParams {
                name
                description
                value
            }
            mlFeatures
            groups {
                urn
                name
                description
            }
            customProperties {
                key
                value
            }
        }
        globalTags {
            ...globalTagsFields
        }
    }
    ${PlatformFieldsFragmentDoc}
    ${OwnershipFieldsFragmentDoc}
    ${GlobalTagsFieldsFragmentDoc}
`;
export const NonRecursiveMlModelGroupFieldsFragmentDoc = gql`
    fragment nonRecursiveMLModelGroupFields on MLModelGroup {
        urn
        type
        name
        description
        origin
        platform {
            ...platformFields
        }
        ownership {
            ...ownershipFields
        }
    }
    ${PlatformFieldsFragmentDoc}
    ${OwnershipFieldsFragmentDoc}
`;
export const NonConflictingPlatformFieldsFragmentDoc = gql`
    fragment nonConflictingPlatformFields on DataPlatform {
        urn
        type
        name
        properties {
            displayName
            datasetNameDelimiter
            logoUrl
        }
        displayName
        info {
            type
            displayName
            datasetNameDelimiter
            logoUrl
        }
    }
`;
