/* eslint-disable */
import * as Types from '../types.generated';

import {
    PlatformFieldsFragment,
    OwnershipFieldsFragment,
    GlobalTagsFieldsFragment,
    GlossaryTermsFragment,
    EntityDomainFragment,
    InstitutionalMemoryFieldsFragment,
    EntityContainerFragment,
    SchemaMetadataFieldsFragment,
    NonRecursiveDataFlowFieldsFragment,
    NonConflictingPlatformFieldsFragment,
} from './fragments.generated';
import { gql } from '@apollo/client';
import {
    PlatformFieldsFragmentDoc,
    OwnershipFieldsFragmentDoc,
    GlobalTagsFieldsFragmentDoc,
    GlossaryTermsFragmentDoc,
    EntityDomainFragmentDoc,
    InstitutionalMemoryFieldsFragmentDoc,
    EntityContainerFragmentDoc,
    SchemaMetadataFieldsFragmentDoc,
    NonRecursiveDataFlowFieldsFragmentDoc,
    NonConflictingPlatformFieldsFragmentDoc,
} from './fragments.generated';
import * as Apollo from '@apollo/client';
export type DataFlowFieldsFragment = { __typename?: 'DataFlow' } & Pick<
    Types.DataFlow,
    'urn' | 'type' | 'orchestrator' | 'flowId' | 'cluster'
> & {
        platform: { __typename?: 'DataPlatform' } & PlatformFieldsFragment;
        properties?: Types.Maybe<
            { __typename?: 'DataFlowProperties' } & Pick<
                Types.DataFlowProperties,
                'name' | 'description' | 'project' | 'externalUrl'
            > & {
                    customProperties?: Types.Maybe<
                        Array<{ __typename?: 'StringMapEntry' } & Pick<Types.StringMapEntry, 'key' | 'value'>>
                    >;
                }
        >;
        editableProperties?: Types.Maybe<
            { __typename?: 'DataFlowEditableProperties' } & Pick<Types.DataFlowEditableProperties, 'description'>
        >;
        ownership?: Types.Maybe<{ __typename?: 'Ownership' } & OwnershipFieldsFragment>;
        globalTags?: Types.Maybe<{ __typename?: 'GlobalTags' } & GlobalTagsFieldsFragment>;
        institutionalMemory?: Types.Maybe<{ __typename?: 'InstitutionalMemory' } & InstitutionalMemoryFieldsFragment>;
        glossaryTerms?: Types.Maybe<{ __typename?: 'GlossaryTerms' } & GlossaryTermsFragment>;
        domain?: Types.Maybe<{ __typename?: 'Domain' } & EntityDomainFragment>;
    };

export type GetDataFlowQueryVariables = Types.Exact<{
    urn: Types.Scalars['String'];
}>;

export type GetDataFlowQuery = { __typename?: 'Query' } & {
    dataFlow?: Types.Maybe<
        { __typename?: 'DataFlow' } & {
            childJobs?: Types.Maybe<
                { __typename?: 'EntityRelationshipsResult' } & Pick<
                    Types.EntityRelationshipsResult,
                    'start' | 'count' | 'total'
                > & {
                        relationships: Array<
                            { __typename?: 'EntityRelationship' } & {
                                entity:
                                    | { __typename?: 'Chart' }
                                    | { __typename?: 'Container' }
                                    | { __typename?: 'CorpGroup' }
                                    | { __typename?: 'CorpUser' }
                                    | { __typename?: 'Dashboard' }
                                    | { __typename?: 'DataFlow' }
                                    | ({ __typename?: 'DataJob' } & Pick<Types.DataJob, 'urn' | 'type' | 'jobId'> & {
                                              dataFlow?: Types.Maybe<
                                                  { __typename?: 'DataFlow' } & Pick<
                                                      Types.DataFlow,
                                                      'urn' | 'type' | 'orchestrator'
                                                  >
                                              >;
                                              ownership?: Types.Maybe<
                                                  { __typename?: 'Ownership' } & OwnershipFieldsFragment
                                              >;
                                              properties?: Types.Maybe<
                                                  { __typename?: 'DataJobProperties' } & Pick<
                                                      Types.DataJobProperties,
                                                      'name' | 'description'
                                                  >
                                              >;
                                              editableProperties?: Types.Maybe<
                                                  { __typename?: 'DataJobEditableProperties' } & Pick<
                                                      Types.DataJobEditableProperties,
                                                      'description'
                                                  >
                                              >;
                                              globalTags?: Types.Maybe<
                                                  { __typename?: 'GlobalTags' } & GlobalTagsFieldsFragment
                                              >;
                                              glossaryTerms?: Types.Maybe<
                                                  { __typename?: 'GlossaryTerms' } & GlossaryTermsFragment
                                              >;
                                          })
                                    | { __typename?: 'DataPlatform' }
                                    | { __typename?: 'DataPlatformInstance' }
                                    | { __typename?: 'Dataset' }
                                    | { __typename?: 'Domain' }
                                    | { __typename?: 'GlossaryTerm' }
                                    | { __typename?: 'MLFeature' }
                                    | { __typename?: 'MLFeatureTable' }
                                    | { __typename?: 'MLModel' }
                                    | { __typename?: 'MLModelGroup' }
                                    | { __typename?: 'MLPrimaryKey' }
                                    | { __typename?: 'Tag' };
                            }
                        >;
                    }
            >;
        } & DataFlowFieldsFragment
    >;
};

export type UpdateDataFlowMutationVariables = Types.Exact<{
    urn: Types.Scalars['String'];
    input: Types.DataFlowUpdateInput;
}>;

export type UpdateDataFlowMutation = { __typename?: 'Mutation' } & {
    updateDataFlow?: Types.Maybe<{ __typename?: 'DataFlow' } & Pick<Types.DataFlow, 'urn'>>;
};

export const DataFlowFieldsFragmentDoc = gql`
    fragment dataFlowFields on DataFlow {
        urn
        type
        orchestrator
        flowId
        cluster
        platform {
            ...platformFields
        }
        properties {
            name
            description
            project
            externalUrl
            customProperties {
                key
                value
            }
        }
        editableProperties {
            description
        }
        ownership {
            ...ownershipFields
        }
        globalTags {
            ...globalTagsFields
        }
        institutionalMemory {
            ...institutionalMemoryFields
        }
        glossaryTerms {
            ...glossaryTerms
        }
        domain {
            ...entityDomain
        }
    }
    ${PlatformFieldsFragmentDoc}
    ${OwnershipFieldsFragmentDoc}
    ${GlobalTagsFieldsFragmentDoc}
    ${InstitutionalMemoryFieldsFragmentDoc}
    ${GlossaryTermsFragmentDoc}
    ${EntityDomainFragmentDoc}
`;
export const GetDataFlowDocument = gql`
    query getDataFlow($urn: String!) {
        dataFlow(urn: $urn) {
            ...dataFlowFields
            childJobs: relationships(input: { types: ["IsPartOf"], direction: INCOMING, start: 0, count: 100 }) {
                start
                count
                total
                relationships {
                    entity {
                        ... on DataJob {
                            urn
                            type
                            jobId
                            dataFlow {
                                urn
                                type
                                orchestrator
                            }
                            ownership {
                                ...ownershipFields
                            }
                            properties {
                                name
                                description
                            }
                            editableProperties {
                                description
                            }
                            globalTags {
                                ...globalTagsFields
                            }
                            glossaryTerms {
                                ...glossaryTerms
                            }
                        }
                    }
                }
            }
        }
    }
    ${DataFlowFieldsFragmentDoc}
    ${OwnershipFieldsFragmentDoc}
    ${GlobalTagsFieldsFragmentDoc}
    ${GlossaryTermsFragmentDoc}
`;

/**
 * __useGetDataFlowQuery__
 *
 * To run a query within a React component, call `useGetDataFlowQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDataFlowQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDataFlowQuery({
 *   variables: {
 *      urn: // value for 'urn'
 *   },
 * });
 */
export function useGetDataFlowQuery(baseOptions: Apollo.QueryHookOptions<GetDataFlowQuery, GetDataFlowQueryVariables>) {
    return Apollo.useQuery<GetDataFlowQuery, GetDataFlowQueryVariables>(GetDataFlowDocument, baseOptions);
}
export function useGetDataFlowLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<GetDataFlowQuery, GetDataFlowQueryVariables>,
) {
    return Apollo.useLazyQuery<GetDataFlowQuery, GetDataFlowQueryVariables>(GetDataFlowDocument, baseOptions);
}
export type GetDataFlowQueryHookResult = ReturnType<typeof useGetDataFlowQuery>;
export type GetDataFlowLazyQueryHookResult = ReturnType<typeof useGetDataFlowLazyQuery>;
export type GetDataFlowQueryResult = Apollo.QueryResult<GetDataFlowQuery, GetDataFlowQueryVariables>;
export const UpdateDataFlowDocument = gql`
    mutation updateDataFlow($urn: String!, $input: DataFlowUpdateInput!) {
        updateDataFlow(urn: $urn, input: $input) {
            urn
        }
    }
`;
export type UpdateDataFlowMutationFn = Apollo.MutationFunction<UpdateDataFlowMutation, UpdateDataFlowMutationVariables>;

/**
 * __useUpdateDataFlowMutation__
 *
 * To run a mutation, you first call `useUpdateDataFlowMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateDataFlowMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateDataFlowMutation, { data, loading, error }] = useUpdateDataFlowMutation({
 *   variables: {
 *      urn: // value for 'urn'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateDataFlowMutation(
    baseOptions?: Apollo.MutationHookOptions<UpdateDataFlowMutation, UpdateDataFlowMutationVariables>,
) {
    return Apollo.useMutation<UpdateDataFlowMutation, UpdateDataFlowMutationVariables>(
        UpdateDataFlowDocument,
        baseOptions,
    );
}
export type UpdateDataFlowMutationHookResult = ReturnType<typeof useUpdateDataFlowMutation>;
export type UpdateDataFlowMutationResult = Apollo.MutationResult<UpdateDataFlowMutation>;
export type UpdateDataFlowMutationOptions = Apollo.BaseMutationOptions<
    UpdateDataFlowMutation,
    UpdateDataFlowMutationVariables
>;
