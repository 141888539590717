export type Maybe<T> = T | null;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
    ID: string;
    String: string;
    Boolean: boolean;
    Int: number;
    Float: number;
    Long: number;
};

/** The access level for a Metadata Entity, either public or private */
export enum AccessLevel {
    /** Publicly available */
    Public = 'PUBLIC',
    /** Restricted to a subset of viewers */
    Private = 'PRIVATE',
}

export type AccessToken = {
    __typename?: 'AccessToken';
    /** The access token itself */
    accessToken: Scalars['String'];
};

/** The duration for which an Access Token is valid. */
export enum AccessTokenDuration {
    /** 1 hour */
    OneHour = 'ONE_HOUR',
    /** 1 day */
    OneDay = 'ONE_DAY',
    /** 1 week */
    OneWeek = 'ONE_WEEK',
    /** 1 month */
    OneMonth = 'ONE_MONTH',
    /** 3 months */
    ThreeMonths = 'THREE_MONTHS',
    /** 6 months */
    SixMonths = 'SIX_MONTHS',
    /** 1 year */
    OneYear = 'ONE_YEAR',
}

/** A type of DataHub Access Token. */
export enum AccessTokenType {
    /** Generates a personal access token */
    Personal = 'PERSONAL',
}

/** The actors that a DataHub Access Policy applies to */
export type ActorFilter = {
    __typename?: 'ActorFilter';
    /** A disjunctive set of users to apply the policy to */
    users?: Maybe<Array<Scalars['String']>>;
    /** A disjunctive set of groups to apply the policy to */
    groups?: Maybe<Array<Scalars['String']>>;
    /**
     * Whether the filter should return TRUE for owners of a particular resource
     * Only applies to policies of type METADATA, which have a resource associated with them
     */
    resourceOwners: Scalars['Boolean'];
    /** Whether the filter should apply to all users */
    allUsers: Scalars['Boolean'];
    /** Whether the filter should apply to all groups */
    allGroups: Scalars['Boolean'];
};

/** Input required when creating or updating an Access Policies Determines which actors the Policy applies to */
export type ActorFilterInput = {
    /** A disjunctive set of users to apply the policy to */
    users?: Maybe<Array<Scalars['String']>>;
    /** A disjunctive set of groups to apply the policy to */
    groups?: Maybe<Array<Scalars['String']>>;
    /**
     * Whether the filter should return TRUE for owners of a particular resource
     * Only applies to policies of type METADATA, which have a resource associated with them
     */
    resourceOwners: Scalars['Boolean'];
    /** Whether the filter should apply to all users */
    allUsers: Scalars['Boolean'];
    /** Whether the filter should apply to all groups */
    allGroups: Scalars['Boolean'];
};

/** Input required to add members to a DataHub group */
export type AddGroupMembersInput = {
    /** The group to add members to */
    groupUrn: Scalars['String'];
    /** The members to add to the group */
    userUrns: Array<Scalars['String']>;
};

/** Input provided when adding the association between a Metadata Entity and a Link */
export type AddLinkInput = {
    /** The url of the link to add or remove */
    linkUrl: Scalars['String'];
    /** A label to attach to the link */
    label: Scalars['String'];
    /** The urn of the resource or entity to attach the link to, for example a dataset urn */
    resourceUrn: Scalars['String'];
};

/** Input provided when adding the association between a Metadata Entity and an user or group owner */
export type AddOwnerInput = {
    /** The primary key of the Owner to add or remove */
    ownerUrn: Scalars['String'];
    /** The owner type, either a user or group */
    ownerEntityType: OwnerEntityType;
    /** The urn of the resource or entity to attach or remove the owner from, for example a dataset urn */
    resourceUrn: Scalars['String'];
};

/** Information about the aggregation that can be used for filtering, included the field value and number of results */
export type AggregationMetadata = {
    __typename?: 'AggregationMetadata';
    /** A particular value of a facet field */
    value: Scalars['String'];
    /** The number of search results containing the value */
    count: Scalars['Long'];
    /** Entity corresponding to the facet field */
    entity?: Maybe<Entity>;
};

/** For consumption by UI only */
export type AnalyticsChart = TimeSeriesChart | BarChart | TableChart;

/** For consumption by UI only */
export type AnalyticsChartGroup = {
    __typename?: 'AnalyticsChartGroup';
    title: Scalars['String'];
    charts: Array<AnalyticsChart>;
};

/** Configurations related to the Analytics Feature */
export type AnalyticsConfig = {
    __typename?: 'AnalyticsConfig';
    /** Whether the Analytics feature is enabled and should be displayed */
    enabled: Scalars['Boolean'];
};

/**
 * Config loaded at application boot time
 * This configuration dictates the behavior of the UI, such as which features are enabled or disabled
 */
export type AppConfig = {
    __typename?: 'AppConfig';
    /** App version */
    appVersion?: Maybe<Scalars['String']>;
    /** Configurations related to the Analytics Feature */
    analyticsConfig: AnalyticsConfig;
    /** Configurations related to the Policies Feature */
    policiesConfig: PoliciesConfig;
    /** Configurations related to the User & Group management */
    identityManagementConfig: IdentityManagementConfig;
    /** Configurations related to UI-based ingestion */
    managedIngestionConfig: ManagedIngestionConfig;
};

/** A versioned aspect, or single group of related metadata, associated with an Entity and having a unique version */
export type Aspect = {
    /** The version of the aspect, where zero represents the latest version */
    version?: Maybe<Scalars['Long']>;
};

/** Params to configure what list of aspects should be fetched by the aspects property */
export type AspectParams = {
    /** Only fetch auto render aspects */
    autoRenderOnly?: Maybe<Scalars['Boolean']>;
};

/** Details for the frontend on how the raw aspect should be rendered */
export type AspectRenderSpec = {
    __typename?: 'AspectRenderSpec';
    /** Format the aspect should be displayed in for the UI. Powered by the renderSpec annotation on the aspect model */
    displayType?: Maybe<Scalars['String']>;
    /** Name to refer to the aspect type by for the UI. Powered by the renderSpec annotation on the aspect model */
    displayName?: Maybe<Scalars['String']>;
    /** Field in the aspect payload to index into for rendering. */
    key?: Maybe<Scalars['String']>;
};

/** A time stamp along with an optional actor */
export type AuditStamp = {
    __typename?: 'AuditStamp';
    /** When the audited action took place */
    time: Scalars['Long'];
    /** Who performed the audited action */
    actor?: Maybe<Scalars['String']>;
};

/** Information about the currently authenticated user */
export type AuthenticatedUser = {
    __typename?: 'AuthenticatedUser';
    /** The user information associated with the authenticated user, including properties used in rendering the profile */
    corpUser: CorpUser;
    /** The privileges assigned to the currently authenticated user, which dictates which parts of the UI they should be able to use */
    platformPrivileges: PlatformPrivileges;
};

/** Input for performing an auto completion query against a single Metadata Entity */
export type AutoCompleteInput = {
    /** Entity type to be autocompleted against */
    type?: Maybe<EntityType>;
    /** The raw query string */
    query: Scalars['String'];
    /** An optional entity field name to autocomplete on */
    field?: Maybe<Scalars['String']>;
    /** The maximum number of autocomplete results to be returned */
    limit?: Maybe<Scalars['Int']>;
    /** Faceted filters applied to autocomplete results */
    filters?: Maybe<Array<FacetFilterInput>>;
};

/** Input for performing an auto completion query against a a set of Metadata Entities */
export type AutoCompleteMultipleInput = {
    /**
     * Entity types to be autocompleted against
     * Optional, if none supplied, all searchable types will be autocompleted against
     */
    types?: Maybe<Array<EntityType>>;
    /** The raw query string */
    query: Scalars['String'];
    /** An optional field to autocomplete against */
    field?: Maybe<Scalars['String']>;
    /** The maximum number of autocomplete results */
    limit?: Maybe<Scalars['Int']>;
    /** Faceted filters applied to autocomplete results */
    filters?: Maybe<Array<FacetFilterInput>>;
};

/** The results returned on a multi entity autocomplete query */
export type AutoCompleteMultipleResults = {
    __typename?: 'AutoCompleteMultipleResults';
    /** The raw query string */
    query: Scalars['String'];
    /** The autocompletion suggestions */
    suggestions: Array<AutoCompleteResultForEntity>;
};

/** An individual auto complete result specific to an individual Metadata Entity Type */
export type AutoCompleteResultForEntity = {
    __typename?: 'AutoCompleteResultForEntity';
    /** Entity type */
    type: EntityType;
    /** The autocompletion results for specified entity type */
    suggestions: Array<Scalars['String']>;
};

/** The results returned on a single entity autocomplete query */
export type AutoCompleteResults = {
    __typename?: 'AutoCompleteResults';
    /** The query string */
    query: Scalars['String'];
    /** The autocompletion results */
    suggestions: Array<Scalars['String']>;
};

/** For consumption by UI only */
export type BarChart = {
    __typename?: 'BarChart';
    title: Scalars['String'];
    bars: Array<NamedBar>;
};

/** For consumption by UI only */
export type BarSegment = {
    __typename?: 'BarSegment';
    label: Scalars['String'];
    value: Scalars['Int'];
};

export type BaseData = {
    __typename?: 'BaseData';
    /** Dataset used for the Training or Evaluation of the MLModel */
    dataset: Scalars['String'];
    /** Motivation to pick these datasets */
    motivation?: Maybe<Scalars['String']>;
    /** Details of Data Proprocessing */
    preProcessing?: Maybe<Array<Scalars['String']>>;
};

export type BooleanBox = {
    __typename?: 'BooleanBox';
    booleanValue: Scalars['Boolean'];
};

/** Input required for browse queries */
export type BrowseInput = {
    /** The browse entity type */
    type: EntityType;
    /** The browse path */
    path?: Maybe<Array<Scalars['String']>>;
    /** The starting point of paginated results */
    start?: Maybe<Scalars['Int']>;
    /** The number of elements included in the results */
    count?: Maybe<Scalars['Int']>;
    /** Faceted filters applied to browse results */
    filters?: Maybe<Array<FacetFilterInput>>;
};

/** A hierarchical entity path */
export type BrowsePath = {
    __typename?: 'BrowsePath';
    /** The components of the browse path */
    path: Array<Scalars['String']>;
};

/** Inputs for fetching the browse paths for a Metadata Entity */
export type BrowsePathsInput = {
    /** The browse entity type */
    type: EntityType;
    /** The entity urn */
    urn: Scalars['String'];
};

/** A group of Entities under a given browse path */
export type BrowseResultGroup = {
    __typename?: 'BrowseResultGroup';
    /** The path name of a group of browse results */
    name: Scalars['String'];
    /** The number of entities within the group */
    count: Scalars['Long'];
};

/** Metadata about the Browse Paths response */
export type BrowseResultMetadata = {
    __typename?: 'BrowseResultMetadata';
    /** The provided path */
    path: Array<Scalars['String']>;
    /** The total number of entities under the provided browse path */
    totalNumEntities: Scalars['Long'];
};

/** The results of a browse path traversal query */
export type BrowseResults = {
    __typename?: 'BrowseResults';
    /** The browse results */
    entities: Array<Entity>;
    /** The groups present at the provided browse path */
    groups: Array<BrowseResultGroup>;
    /** The starting point of paginated results */
    start: Scalars['Int'];
    /** The number of elements included in the results */
    count: Scalars['Int'];
    /** The total number of browse results under the path with filters applied */
    total: Scalars['Int'];
    /** Metadata containing resulting browse groups */
    metadata: BrowseResultMetadata;
};

/** Input for cancelling an execution request input */
export type CancelIngestionExecutionRequestInput = {
    /** Urn of the ingestion source */
    ingestionSourceUrn: Scalars['String'];
    /** Urn of the specific execution request to cancel */
    executionRequestUrn: Scalars['String'];
};

export type CaveatDetails = {
    __typename?: 'CaveatDetails';
    /** Did the results suggest any further testing */
    needsFurtherTesting?: Maybe<Scalars['Boolean']>;
    /** Caveat Description */
    caveatDescription?: Maybe<Scalars['String']>;
    /** Relevant groups that were not represented in the evaluation dataset */
    groupsNotRepresented?: Maybe<Array<Scalars['String']>>;
};

export type CaveatsAndRecommendations = {
    __typename?: 'CaveatsAndRecommendations';
    /** Caveats on using this MLModel */
    caveats?: Maybe<CaveatDetails>;
    /** Recommendations on where this MLModel should be used */
    recommendations?: Maybe<Scalars['String']>;
    /** Ideal characteristics of an evaluation dataset for this MLModel */
    idealDatasetCharacteristics?: Maybe<Array<Scalars['String']>>;
};

/** A Chart Metadata Entity */
export type Chart = EntityWithRelationships &
    Entity & {
        __typename?: 'Chart';
        /** The primary key of the Chart */
        urn: Scalars['String'];
        /** A standard Entity Type */
        type: EntityType;
        /** The parent container in which the entity resides */
        container?: Maybe<Container>;
        /**
         * The chart tool name
         * Note that this field will soon be deprecated in favor a unified notion of Data Platform
         */
        tool: Scalars['String'];
        /** An id unique within the charting tool */
        chartId: Scalars['String'];
        /** Additional read only properties about the Chart */
        properties?: Maybe<ChartProperties>;
        /** Additional read write properties about the Chart */
        editableProperties?: Maybe<ChartEditableProperties>;
        /** Info about the query which is used to render the chart */
        query?: Maybe<ChartQuery>;
        /** Ownership metadata of the chart */
        ownership?: Maybe<Ownership>;
        /** Status metadata of the chart */
        status?: Maybe<Status>;
        /** The tags associated with the chart */
        tags?: Maybe<GlobalTags>;
        /** References to internal resources related to the dashboard */
        institutionalMemory?: Maybe<InstitutionalMemory>;
        /** The structured glossary terms associated with the dashboard */
        glossaryTerms?: Maybe<GlossaryTerms>;
        /** The Domain associated with the Chart */
        domain?: Maybe<Domain>;
        /** Edges extending from this entity */
        relationships?: Maybe<EntityRelationshipsResult>;
        /**
         * Deprecated, use properties field instead
         * Additional read only information about the chart
         * @deprecated Field no longer supported
         */
        info?: Maybe<ChartInfo>;
        /**
         * Deprecated, use editableProperties field instead
         * Additional read write information about the Chart
         * @deprecated Field no longer supported
         */
        editableInfo?: Maybe<ChartEditableProperties>;
        /**
         * Deprecated, use tags instead
         * The structured tags associated with the chart
         * @deprecated Field no longer supported
         */
        globalTags?: Maybe<GlobalTags>;
        /** Standardized platform urn where the chart is defined */
        platform: DataPlatform;
    };

/** A Chart Metadata Entity */
export type ChartRelationshipsArgs = {
    input: RelationshipsInput;
};

/**
 * Chart properties that are editable via the UI This represents logical metadata,
 * as opposed to technical metadata
 */
export type ChartEditableProperties = {
    __typename?: 'ChartEditableProperties';
    /** Description of the Chart */
    description?: Maybe<Scalars['String']>;
};

/** Update to writable Chart fields */
export type ChartEditablePropertiesUpdate = {
    /** Writable description aka documentation for a Chart */
    description: Scalars['String'];
};

/**
 * Deprecated, use ChartProperties instead
 * Additional read only information about the chart
 */
export type ChartInfo = {
    __typename?: 'ChartInfo';
    /** Display name of the chart */
    name: Scalars['String'];
    /** Description of the chart */
    description?: Maybe<Scalars['String']>;
    /**
     * Deprecated, use relationship Consumes instead
     * Data sources for the chart
     * @deprecated Field no longer supported
     */
    inputs?: Maybe<Array<Dataset>>;
    /** Native platform URL of the chart */
    externalUrl?: Maybe<Scalars['String']>;
    /** Access level for the chart */
    type?: Maybe<ChartType>;
    /** Access level for the chart */
    access?: Maybe<AccessLevel>;
    /** A list of platform specific metadata tuples */
    customProperties?: Maybe<Array<StringMapEntry>>;
    /** The time when this chart last refreshed */
    lastRefreshed?: Maybe<Scalars['Long']>;
    /** An AuditStamp corresponding to the creation of this chart */
    created: AuditStamp;
    /** An AuditStamp corresponding to the modification of this chart */
    lastModified: AuditStamp;
    /** An optional AuditStamp corresponding to the deletion of this chart */
    deleted?: Maybe<AuditStamp>;
};

/** Additional read only properties about the chart */
export type ChartProperties = {
    __typename?: 'ChartProperties';
    /** Display name of the chart */
    name: Scalars['String'];
    /** Description of the chart */
    description?: Maybe<Scalars['String']>;
    /** Native platform URL of the chart */
    externalUrl?: Maybe<Scalars['String']>;
    /** Access level for the chart */
    type?: Maybe<ChartType>;
    /** Access level for the chart */
    access?: Maybe<AccessLevel>;
    /** A list of platform specific metadata tuples */
    customProperties?: Maybe<Array<StringMapEntry>>;
    /** The time when this chart last refreshed */
    lastRefreshed?: Maybe<Scalars['Long']>;
    /** An AuditStamp corresponding to the creation of this chart */
    created: AuditStamp;
    /** An AuditStamp corresponding to the modification of this chart */
    lastModified: AuditStamp;
    /** An optional AuditStamp corresponding to the deletion of this chart */
    deleted?: Maybe<AuditStamp>;
};

/** The query that was used to populate a Chart */
export type ChartQuery = {
    __typename?: 'ChartQuery';
    /** Raw query to build a chart from input datasets */
    rawQuery: Scalars['String'];
    /** The type of the chart query */
    type: ChartQueryType;
};

/** The type of the Chart Query */
export enum ChartQueryType {
    /** Standard ANSI SQL */
    Sql = 'SQL',
    /** LookML */
    Lookml = 'LOOKML',
}

/** The type of a Chart Entity */
export enum ChartType {
    /** Bar graph */
    Bar = 'BAR',
    /** Pie chart */
    Pie = 'PIE',
    /** Scatter plot */
    Scatter = 'SCATTER',
    /** Table */
    Table = 'TABLE',
    /** Markdown formatted text */
    Text = 'TEXT',
    /** A line chart */
    Line = 'LINE',
    /** An area chart */
    Area = 'AREA',
    /** A histogram chart */
    Histogram = 'HISTOGRAM',
    /** A box plot chart */
    BoxPlot = 'BOX_PLOT',
}

/** Arguments provided to update a Chart Entity */
export type ChartUpdateInput = {
    /** Update to ownership */
    ownership?: Maybe<OwnershipUpdate>;
    /**
     * Deprecated, use tags field instead
     * Update to global tags
     */
    globalTags?: Maybe<GlobalTagsUpdate>;
    /** Update to tags */
    tags?: Maybe<GlobalTagsUpdate>;
    /** Update to editable properties */
    editableProperties?: Maybe<ChartEditablePropertiesUpdate>;
};

/** A container of other Metadata Entities */
export type Container = Entity & {
    __typename?: 'Container';
    /** The primary key of the container */
    urn: Scalars['String'];
    /** A standard Entity Type */
    type: EntityType;
    /** Standardized platform. */
    platform: DataPlatform;
    /** Fetch an Entity Container by primary key (urn) */
    container?: Maybe<Container>;
    /** Read-only properties that originate in the source data platform */
    properties?: Maybe<ContainerProperties>;
    /** Read-write properties that originate in DataHub */
    editableProperties?: Maybe<ContainerEditableProperties>;
    /** Ownership metadata of the dataset */
    ownership?: Maybe<Ownership>;
    /** References to internal resources related to the dataset */
    institutionalMemory?: Maybe<InstitutionalMemory>;
    /** Tags used for searching dataset */
    tags?: Maybe<GlobalTags>;
    /** The structured glossary terms associated with the dataset */
    glossaryTerms?: Maybe<GlossaryTerms>;
    /** Sub types of the container, e.g. "Database" etc */
    subTypes?: Maybe<SubTypes>;
    /** The Domain associated with the Dataset */
    domain?: Maybe<Domain>;
    /** Children entities inside of the Container */
    entities?: Maybe<SearchResults>;
    /** Edges extending from this entity */
    relationships?: Maybe<EntityRelationshipsResult>;
};

/** A container of other Metadata Entities */
export type ContainerEntitiesArgs = {
    input?: Maybe<ContainerEntitiesInput>;
};

/** A container of other Metadata Entities */
export type ContainerRelationshipsArgs = {
    input: RelationshipsInput;
};

/** Read-write properties that originate in DataHub */
export type ContainerEditableProperties = {
    __typename?: 'ContainerEditableProperties';
    /** DataHub description of the Container */
    description?: Maybe<Scalars['String']>;
};

/** Input required to fetch the entities inside of a container. */
export type ContainerEntitiesInput = {
    /** Optional query filter for particular entities inside the container */
    query?: Maybe<Scalars['String']>;
    /** The offset of the result set */
    start?: Maybe<Scalars['Int']>;
    /** The number of entities to include in result set */
    count?: Maybe<Scalars['Int']>;
    /** Optional Facet filters to apply to the result set */
    filters?: Maybe<Array<FacetFilterInput>>;
};

/** Read-only properties that originate in the source data platform */
export type ContainerProperties = {
    __typename?: 'ContainerProperties';
    /** Display name of the Container */
    name: Scalars['String'];
    /** System description of the Container */
    description?: Maybe<Scalars['String']>;
    /** Custom properties of the Dataset */
    customProperties?: Maybe<Array<StringMapEntry>>;
    /** Native platform URL of the dashboard */
    externalUrl?: Maybe<Scalars['String']>;
};

/** Params about the recommended content */
export type ContentParams = {
    __typename?: 'ContentParams';
    /** Number of entities corresponding to the recommended content */
    count?: Maybe<Scalars['Long']>;
};

/** A DataHub Group entity, which represents a Person on the Metadata Entity Graph */
export type CorpGroup = Entity & {
    __typename?: 'CorpGroup';
    /** The primary key of the group */
    urn: Scalars['String'];
    /** A standard Entity Type */
    type: EntityType;
    /** Group name eg wherehows dev, ask_metadata */
    name: Scalars['String'];
    /** Additional read only properties about the group */
    properties?: Maybe<CorpGroupProperties>;
    /** Edges extending from this entity */
    relationships?: Maybe<EntityRelationshipsResult>;
    /**
     * Deprecated, use properties field instead
     * Additional read only info about the group
     * @deprecated Field no longer supported
     */
    info?: Maybe<CorpGroupInfo>;
};

/** A DataHub Group entity, which represents a Person on the Metadata Entity Graph */
export type CorpGroupRelationshipsArgs = {
    input: RelationshipsInput;
};

/**
 * Deprecated, use CorpUserProperties instead
 * Additional read only info about a group
 */
export type CorpGroupInfo = {
    __typename?: 'CorpGroupInfo';
    /** The name to display when rendering the group */
    displayName?: Maybe<Scalars['String']>;
    /** The description provided for the group */
    description?: Maybe<Scalars['String']>;
    /** email of this group */
    email?: Maybe<Scalars['String']>;
    /**
     * Deprecated, do not use
     * owners of this group
     * @deprecated Field no longer supported
     */
    admins?: Maybe<Array<CorpUser>>;
    /**
     * Deprecated, use relationship IsMemberOfGroup instead
     * List of ldap urn in this group
     * @deprecated Field no longer supported
     */
    members?: Maybe<Array<CorpUser>>;
    /**
     * Deprecated, do not use
     * List of groups urns in this group
     * @deprecated Field no longer supported
     */
    groups?: Maybe<Array<Scalars['String']>>;
};

/** Additional read only properties about a group */
export type CorpGroupProperties = {
    __typename?: 'CorpGroupProperties';
    /** display name of this group */
    displayName?: Maybe<Scalars['String']>;
    /** The description provided for the group */
    description?: Maybe<Scalars['String']>;
    /** email of this group */
    email?: Maybe<Scalars['String']>;
};

/** A DataHub User entity, which represents a Person on the Metadata Entity Graph */
export type CorpUser = Entity & {
    __typename?: 'CorpUser';
    /** The primary key of the user */
    urn: Scalars['String'];
    /** The standard Entity Type */
    type: EntityType;
    /**
     * A username associated with the user
     * This uniquely identifies the user within DataHub
     */
    username: Scalars['String'];
    /** Additional read only properties about the corp user */
    properties?: Maybe<CorpUserProperties>;
    /** Read write properties about the corp user */
    editableProperties?: Maybe<CorpUserEditableProperties>;
    /** The status of the user */
    status?: Maybe<CorpUserStatus>;
    /** The tags associated with the user */
    tags?: Maybe<GlobalTags>;
    /** Edges extending from this entity */
    relationships?: Maybe<EntityRelationshipsResult>;
    /**
     * Deprecated, use properties field instead
     * Additional read only info about the corp user
     * @deprecated Field no longer supported
     */
    info?: Maybe<CorpUserInfo>;
    /**
     * Deprecated, use editableProperties field instead
     * Read write info about the corp user
     * @deprecated Field no longer supported
     */
    editableInfo?: Maybe<CorpUserEditableInfo>;
    /**
     * Deprecated, use the tags field instead
     * The structured tags associated with the user
     * @deprecated Field no longer supported
     */
    globalTags?: Maybe<GlobalTags>;
};

/** A DataHub User entity, which represents a Person on the Metadata Entity Graph */
export type CorpUserRelationshipsArgs = {
    input: RelationshipsInput;
};

/**
 * Deprecated, use CorpUserEditableProperties instead
 * Additional read write info about a user
 */
export type CorpUserEditableInfo = {
    __typename?: 'CorpUserEditableInfo';
    /** About me section of the user */
    aboutMe?: Maybe<Scalars['String']>;
    /** Teams that the user belongs to */
    teams?: Maybe<Array<Scalars['String']>>;
    /** Skills that the user possesses */
    skills?: Maybe<Array<Scalars['String']>>;
    /** A URL which points to a picture which user wants to set as a profile photo */
    pictureLink?: Maybe<Scalars['String']>;
};

/** Additional read write properties about a user */
export type CorpUserEditableProperties = {
    __typename?: 'CorpUserEditableProperties';
    /** About me section of the user */
    aboutMe?: Maybe<Scalars['String']>;
    /** Teams that the user belongs to */
    teams?: Maybe<Array<Scalars['String']>>;
    /** Skills that the user possesses */
    skills?: Maybe<Array<Scalars['String']>>;
    /** A URL which points to a picture which user wants to set as a profile photo */
    pictureLink?: Maybe<Scalars['String']>;
    /** The slack handle of the user */
    slack?: Maybe<Scalars['String']>;
    /** Phone number for the user */
    phone?: Maybe<Scalars['String']>;
    /** Email address for the user */
    email?: Maybe<Scalars['String']>;
};

/**
 * Deprecated, use CorpUserProperties instead
 * Additional read only info about a user
 */
export type CorpUserInfo = {
    __typename?: 'CorpUserInfo';
    /** Whether the user is active */
    active: Scalars['Boolean'];
    /** Display name of the user */
    displayName?: Maybe<Scalars['String']>;
    /** Email address of the user */
    email?: Maybe<Scalars['String']>;
    /** Title of the user */
    title?: Maybe<Scalars['String']>;
    /** Direct manager of the user */
    manager?: Maybe<CorpUser>;
    /** department id the user belong to */
    departmentId?: Maybe<Scalars['Long']>;
    /** department name this user belong to */
    departmentName?: Maybe<Scalars['String']>;
    /** first name of the user */
    firstName?: Maybe<Scalars['String']>;
    /** last name of the user */
    lastName?: Maybe<Scalars['String']>;
    /** Common name of this user, format is firstName plus lastName */
    fullName?: Maybe<Scalars['String']>;
    /** two uppercase letters country code */
    countryCode?: Maybe<Scalars['String']>;
};

/** Additional read only properties about a user */
export type CorpUserProperties = {
    __typename?: 'CorpUserProperties';
    /** Whether the user is active */
    active: Scalars['Boolean'];
    /** Display name of the user */
    displayName?: Maybe<Scalars['String']>;
    /** Email address of the user */
    email?: Maybe<Scalars['String']>;
    /** Title of the user */
    title?: Maybe<Scalars['String']>;
    /** Direct manager of the user */
    manager?: Maybe<CorpUser>;
    /** department id the user belong to */
    departmentId?: Maybe<Scalars['Long']>;
    /** department name this user belong to */
    departmentName?: Maybe<Scalars['String']>;
    /** first name of the user */
    firstName?: Maybe<Scalars['String']>;
    /** last name of the user */
    lastName?: Maybe<Scalars['String']>;
    /** Common name of this user, format is firstName plus lastName */
    fullName?: Maybe<Scalars['String']>;
    /** two uppercase letters country code */
    countryCode?: Maybe<Scalars['String']>;
};

/** The state of a CorpUser */
export enum CorpUserStatus {
    /** A User that has been provisioned and logged in */
    Active = 'ACTIVE',
}

/** Arguments provided to update a CorpUser Entity */
export type CorpUserUpdateInput = {
    /** About me section of the user */
    aboutMe?: Maybe<Scalars['String']>;
    /** Teams that the user belongs to */
    teams?: Maybe<Array<Scalars['String']>>;
    /** Skills that the user possesses */
    skills?: Maybe<Array<Scalars['String']>>;
    /** A URL which points to a picture which user wants to set as a profile photo */
    pictureLink?: Maybe<Scalars['String']>;
    /** The slack handle of the user */
    slack?: Maybe<Scalars['String']>;
    /** Phone number for the user */
    phone?: Maybe<Scalars['String']>;
    /** Email address for the user */
    email?: Maybe<Scalars['String']>;
};

export type Cost = {
    __typename?: 'Cost';
    /** Type of Cost Code */
    costType: CostType;
    /** Code to which the Cost of this entity should be attributed to ie organizational cost ID */
    costValue: CostValue;
};

export enum CostType {
    /** Org Cost Type to which the Cost of this entity should be attributed to */
    OrgCostType = 'ORG_COST_TYPE',
}

export type CostValue = {
    __typename?: 'CostValue';
    /** Organizational Cost ID */
    costId?: Maybe<Scalars['Float']>;
    /** Organizational Cost Code */
    costCode?: Maybe<Scalars['String']>;
};

/** Input required to create a new Domain. */
export type CreateDomainInput = {
    /** Optional! A custom id to use as the primary key identifier for the domain. If not provided, a random UUID will be generated as the id. */
    id?: Maybe<Scalars['String']>;
    /** Display name for the Domain */
    name: Scalars['String'];
    /** Optional description for the Domain */
    description?: Maybe<Scalars['String']>;
};

/** Input for creating a new group */
export type CreateGroupInput = {
    /** Optional! A custom id to use as the primary key identifier for the group. If not provided, a random UUID will be generated as the id. */
    id?: Maybe<Scalars['String']>;
    /** The display name of the group */
    name: Scalars['String'];
    /** The description of the group */
    description?: Maybe<Scalars['String']>;
};

/** Input for creating an execution request input */
export type CreateIngestionExecutionRequestInput = {
    /** Urn of the ingestion source to execute */
    ingestionSourceUrn: Scalars['String'];
};

/** Input arguments for creating a new Secret */
export type CreateSecretInput = {
    /** The name of the secret for reference in ingestion recipes */
    name: Scalars['String'];
    /** The value of the secret, to be encrypted and stored */
    value: Scalars['String'];
    /** An optional description for the secret */
    description?: Maybe<Scalars['String']>;
};

/** A Dashboard Metadata Entity */
export type Dashboard = EntityWithRelationships &
    Entity & {
        __typename?: 'Dashboard';
        /** The primary key of the Dashboard */
        urn: Scalars['String'];
        /** A standard Entity Type */
        type: EntityType;
        /** The parent container in which the entity resides */
        container?: Maybe<Container>;
        /**
         * The dashboard tool name
         * Note that this will soon be deprecated in favor of a standardized notion of Data Platform
         */
        tool: Scalars['String'];
        /** An id unique within the dashboard tool */
        dashboardId: Scalars['String'];
        /** Additional read only properties about the dashboard */
        properties?: Maybe<DashboardProperties>;
        /** Additional read write properties about the dashboard */
        editableProperties?: Maybe<DashboardEditableProperties>;
        /** Ownership metadata of the dashboard */
        ownership?: Maybe<Ownership>;
        /** Status metadata of the dashboard */
        status?: Maybe<Status>;
        /** The tags associated with the dashboard */
        tags?: Maybe<GlobalTags>;
        /** References to internal resources related to the dashboard */
        institutionalMemory?: Maybe<InstitutionalMemory>;
        /** The structured glossary terms associated with the dashboard */
        glossaryTerms?: Maybe<GlossaryTerms>;
        /** The Domain associated with the Dashboard */
        domain?: Maybe<Domain>;
        /** Edges extending from this entity */
        relationships?: Maybe<EntityRelationshipsResult>;
        /**
         * Deprecated, use properties field instead
         * Additional read only information about the dashboard
         * @deprecated Field no longer supported
         */
        info?: Maybe<DashboardInfo>;
        /**
         * Deprecated, use editableProperties instead
         * Additional read write properties about the Dashboard
         * @deprecated Field no longer supported
         */
        editableInfo?: Maybe<DashboardEditableProperties>;
        /**
         * Deprecated, use tags field instead
         * The structured tags associated with the dashboard
         * @deprecated Field no longer supported
         */
        globalTags?: Maybe<GlobalTags>;
        /** Standardized platform urn where the dashboard is defined */
        platform: DataPlatform;
    };

/** A Dashboard Metadata Entity */
export type DashboardRelationshipsArgs = {
    input: RelationshipsInput;
};

/**
 * Dashboard properties that are editable via the UI This represents logical metadata,
 * as opposed to technical metadata
 */
export type DashboardEditableProperties = {
    __typename?: 'DashboardEditableProperties';
    /** Description of the Dashboard */
    description?: Maybe<Scalars['String']>;
};

/** Update to writable Dashboard fields */
export type DashboardEditablePropertiesUpdate = {
    /** Writable description aka documentation for a Dashboard */
    description: Scalars['String'];
};

/**
 * Deprecated, use DashboardProperties instead
 * Additional read only info about a Dashboard
 */
export type DashboardInfo = {
    __typename?: 'DashboardInfo';
    /** Display of the dashboard */
    name: Scalars['String'];
    /** Description of the dashboard */
    description?: Maybe<Scalars['String']>;
    /**
     * Deprecated, use relationship Contains instead
     * Charts that comprise the dashboard
     * @deprecated Field no longer supported
     */
    charts: Array<Chart>;
    /** Native platform URL of the dashboard */
    externalUrl?: Maybe<Scalars['String']>;
    /**
     * Access level for the dashboard
     * Note that this will soon be deprecated for low usage
     */
    access?: Maybe<AccessLevel>;
    /** A list of platform specific metadata tuples */
    customProperties?: Maybe<Array<StringMapEntry>>;
    /** The time when this dashboard last refreshed */
    lastRefreshed?: Maybe<Scalars['Long']>;
    /** An AuditStamp corresponding to the creation of this dashboard */
    created: AuditStamp;
    /** An AuditStamp corresponding to the modification of this dashboard */
    lastModified: AuditStamp;
    /** An optional AuditStamp corresponding to the deletion of this dashboard */
    deleted?: Maybe<AuditStamp>;
};

/** Additional read only properties about a Dashboard */
export type DashboardProperties = {
    __typename?: 'DashboardProperties';
    /** Display of the dashboard */
    name: Scalars['String'];
    /** Description of the dashboard */
    description?: Maybe<Scalars['String']>;
    /** Native platform URL of the dashboard */
    externalUrl?: Maybe<Scalars['String']>;
    /**
     * Access level for the dashboard
     * Note that this will soon be deprecated for low usage
     */
    access?: Maybe<AccessLevel>;
    /** A list of platform specific metadata tuples */
    customProperties?: Maybe<Array<StringMapEntry>>;
    /** The time when this dashboard last refreshed */
    lastRefreshed?: Maybe<Scalars['Long']>;
    /** An AuditStamp corresponding to the creation of this dashboard */
    created: AuditStamp;
    /** An AuditStamp corresponding to the modification of this dashboard */
    lastModified: AuditStamp;
    /** An optional AuditStamp corresponding to the deletion of this dashboard */
    deleted?: Maybe<AuditStamp>;
};

/** Arguments provided to update a Chart Entity */
export type DashboardUpdateInput = {
    /** Update to ownership */
    ownership?: Maybe<OwnershipUpdate>;
    /**
     * Deprecated, use tags field instead
     * Update to global tags
     */
    globalTags?: Maybe<GlobalTagsUpdate>;
    /** Update to tags */
    tags?: Maybe<GlobalTagsUpdate>;
    /** Update to editable properties */
    editableProperties?: Maybe<DashboardEditablePropertiesUpdate>;
};

/**
 * A Data Flow Metadata Entity, representing an set of pipelined Data Job or Tasks required
 * to produce an output Dataset Also known as a Data Pipeline
 */
export type DataFlow = Entity & {
    __typename?: 'DataFlow';
    /** The primary key of a Data Flow */
    urn: Scalars['String'];
    /** A standard Entity Type */
    type: EntityType;
    /** Workflow orchestrator ei Azkaban, Airflow */
    orchestrator: Scalars['String'];
    /** Id of the flow */
    flowId: Scalars['String'];
    /** Cluster of the flow */
    cluster: Scalars['String'];
    /** Additional read only properties about a Data flow */
    properties?: Maybe<DataFlowProperties>;
    /** Additional read write properties about a Data Flow */
    editableProperties?: Maybe<DataFlowEditableProperties>;
    /** Ownership metadata of the flow */
    ownership?: Maybe<Ownership>;
    /** The tags associated with the dataflow */
    tags?: Maybe<GlobalTags>;
    /** Status metadata of the dataflow */
    status?: Maybe<Status>;
    /** References to internal resources related to the dashboard */
    institutionalMemory?: Maybe<InstitutionalMemory>;
    /** The structured glossary terms associated with the dashboard */
    glossaryTerms?: Maybe<GlossaryTerms>;
    /** The Domain associated with the DataFlow */
    domain?: Maybe<Domain>;
    /** Edges extending from this entity */
    relationships?: Maybe<EntityRelationshipsResult>;
    /**
     * Deprecated, use properties field instead
     * Additional read only information about a Data flow
     * @deprecated Field no longer supported
     */
    info?: Maybe<DataFlowInfo>;
    /**
     * Deprecated, use tags field instead
     * The structured tags associated with the dataflow
     * @deprecated Field no longer supported
     */
    globalTags?: Maybe<GlobalTags>;
    /**
     * Deprecated, use relationship IsPartOf instead
     * Data Jobs
     * @deprecated Field no longer supported
     */
    dataJobs?: Maybe<DataFlowDataJobsRelationships>;
    /** Standardized platform urn where the datflow is defined */
    platform: DataPlatform;
};

/**
 * A Data Flow Metadata Entity, representing an set of pipelined Data Job or Tasks required
 * to produce an output Dataset Also known as a Data Pipeline
 */
export type DataFlowRelationshipsArgs = {
    input: RelationshipsInput;
};

/** Deprecated, use relationships query instead */
export type DataFlowDataJobsRelationships = {
    __typename?: 'DataFlowDataJobsRelationships';
    entities?: Maybe<Array<Maybe<EntityRelationshipLegacy>>>;
};

/**
 * Data Flow properties that are editable via the UI This represents logical metadata,
 * as opposed to technical metadata
 */
export type DataFlowEditableProperties = {
    __typename?: 'DataFlowEditableProperties';
    /** Description of the Data Flow */
    description?: Maybe<Scalars['String']>;
};

/** Update to writable Data Flow fields */
export type DataFlowEditablePropertiesUpdate = {
    /** Writable description aka documentation for a Data Flow */
    description: Scalars['String'];
};

/**
 * Deprecated, use DataFlowProperties instead
 * Additional read only properties about a Data Flow aka Pipeline
 */
export type DataFlowInfo = {
    __typename?: 'DataFlowInfo';
    /** Display name of the flow */
    name: Scalars['String'];
    /** Description of the flow */
    description?: Maybe<Scalars['String']>;
    /** Optional project or namespace associated with the flow */
    project?: Maybe<Scalars['String']>;
    /** External URL associated with the DataFlow */
    externalUrl?: Maybe<Scalars['String']>;
    /** A list of platform specific metadata tuples */
    customProperties?: Maybe<Array<StringMapEntry>>;
};

/** Additional read only properties about a Data Flow aka Pipeline */
export type DataFlowProperties = {
    __typename?: 'DataFlowProperties';
    /** Display name of the flow */
    name: Scalars['String'];
    /** Description of the flow */
    description?: Maybe<Scalars['String']>;
    /** Optional project or namespace associated with the flow */
    project?: Maybe<Scalars['String']>;
    /** External URL associated with the DataFlow */
    externalUrl?: Maybe<Scalars['String']>;
    /** A list of platform specific metadata tuples */
    customProperties?: Maybe<Array<StringMapEntry>>;
};

/** Arguments provided to update a Data Flow aka Pipeline Entity */
export type DataFlowUpdateInput = {
    /** Update to ownership */
    ownership?: Maybe<OwnershipUpdate>;
    /**
     * Deprecated, use tags field instead
     * Update to global tags
     */
    globalTags?: Maybe<GlobalTagsUpdate>;
    /** Update to tags */
    tags?: Maybe<GlobalTagsUpdate>;
    /** Update to editable properties */
    editableProperties?: Maybe<DataFlowEditablePropertiesUpdate>;
};

/**
 * A Data Job Metadata Entity, representing an individual unit of computation or Task
 * to produce an output Dataset Always part of a parent Data Flow aka Pipeline
 */
export type DataJob = EntityWithRelationships &
    Entity & {
        __typename?: 'DataJob';
        /** The primary key of the Data Job */
        urn: Scalars['String'];
        /** A standard Entity Type */
        type: EntityType;
        /**
         * Deprecated, use relationship IsPartOf instead
         * The associated data flow
         */
        dataFlow?: Maybe<DataFlow>;
        /** Id of the job */
        jobId: Scalars['String'];
        /** Additional read only properties associated with the Data Job */
        properties?: Maybe<DataJobProperties>;
        /** Additional read write properties associated with the Data Job */
        editableProperties?: Maybe<DataJobEditableProperties>;
        /** The tags associated with the DataJob */
        tags?: Maybe<GlobalTags>;
        /** Ownership metadata of the job */
        ownership?: Maybe<Ownership>;
        /** Status metadata of the DataJob */
        status?: Maybe<Status>;
        /** References to internal resources related to the dashboard */
        institutionalMemory?: Maybe<InstitutionalMemory>;
        /** The structured glossary terms associated with the dashboard */
        glossaryTerms?: Maybe<GlossaryTerms>;
        /** The Domain associated with the Data Job */
        domain?: Maybe<Domain>;
        /** Edges extending from this entity */
        relationships?: Maybe<EntityRelationshipsResult>;
        /**
         * Deprecated, use properties field instead
         * Additional read only information about a Data processing job
         * @deprecated Field no longer supported
         */
        info?: Maybe<DataJobInfo>;
        /**
         * Deprecated, use relationship Produces, Consumes, DownstreamOf instead
         * Information about the inputs and outputs of a Data processing job
         * @deprecated Field no longer supported
         */
        inputOutput?: Maybe<DataJobInputOutput>;
        /**
         * Deprecated, use the tags field instead
         * The structured tags associated with the DataJob
         * @deprecated Field no longer supported
         */
        globalTags?: Maybe<GlobalTags>;
    };

/**
 * A Data Job Metadata Entity, representing an individual unit of computation or Task
 * to produce an output Dataset Always part of a parent Data Flow aka Pipeline
 */
export type DataJobRelationshipsArgs = {
    input: RelationshipsInput;
};

/**
 * Data Job properties that are editable via the UI This represents logical metadata,
 * as opposed to technical metadata
 */
export type DataJobEditableProperties = {
    __typename?: 'DataJobEditableProperties';
    /** Description of the Data Job */
    description?: Maybe<Scalars['String']>;
};

/** Update to writable Data Job fields */
export type DataJobEditablePropertiesUpdate = {
    /** Writable description aka documentation for a Data Job */
    description: Scalars['String'];
};

/**
 * Deprecated, use DataJobProperties instead
 * Additional read only information about a Data Job aka Task
 */
export type DataJobInfo = {
    __typename?: 'DataJobInfo';
    /** Job display name */
    name: Scalars['String'];
    /** Job description */
    description?: Maybe<Scalars['String']>;
    /** External URL associated with the DataJob */
    externalUrl?: Maybe<Scalars['String']>;
    /** A list of platform specific metadata tuples */
    customProperties?: Maybe<Array<StringMapEntry>>;
};

/**
 * The lineage information for a DataJob
 * TODO Rename this to align with other Lineage models
 */
export type DataJobInputOutput = {
    __typename?: 'DataJobInputOutput';
    /**
     * Deprecated, use relationship Consumes instead
     * Input datasets produced by the data job during processing
     * @deprecated Field no longer supported
     */
    inputDatasets?: Maybe<Array<Dataset>>;
    /**
     * Deprecated, use relationship Produces instead
     * Output datasets produced by the data job during processing
     * @deprecated Field no longer supported
     */
    outputDatasets?: Maybe<Array<Dataset>>;
    /**
     * Deprecated, use relationship DownstreamOf instead
     * Input datajobs that this data job depends on
     * @deprecated Field no longer supported
     */
    inputDatajobs?: Maybe<Array<DataJob>>;
};

/** Additional read only properties about a Data Job aka Task */
export type DataJobProperties = {
    __typename?: 'DataJobProperties';
    /** Job display name */
    name: Scalars['String'];
    /** Job description */
    description?: Maybe<Scalars['String']>;
    /** External URL associated with the DataJob */
    externalUrl?: Maybe<Scalars['String']>;
    /** A list of platform specific metadata tuples */
    customProperties?: Maybe<Array<StringMapEntry>>;
};

/** Arguments provided to update a Data Job aka Task Entity */
export type DataJobUpdateInput = {
    /** Update to ownership */
    ownership?: Maybe<OwnershipUpdate>;
    /**
     * Deprecated, use tags field instead
     * Update to global tags
     */
    globalTags?: Maybe<GlobalTagsUpdate>;
    /** Update to tags */
    tags?: Maybe<GlobalTagsUpdate>;
    /** Update to editable properties */
    editableProperties?: Maybe<DataJobEditablePropertiesUpdate>;
};

/**
 * A Data Platform represents a specific third party Data System or Tool Examples include
 * warehouses like Snowflake, orchestrators like Airflow, and dashboarding tools like Looker
 */
export type DataPlatform = Entity & {
    __typename?: 'DataPlatform';
    /** Urn of the data platform */
    urn: Scalars['String'];
    /** A standard Entity Type */
    type: EntityType;
    /** Name of the data platform */
    name: Scalars['String'];
    /** Additional read only properties associated with a data platform */
    properties?: Maybe<DataPlatformProperties>;
    /**
     * Deprecated, use properties displayName instead
     * Display name of the data platform
     * @deprecated Field no longer supported
     */
    displayName?: Maybe<Scalars['String']>;
    /**
     * Deprecated, use properties field instead
     * Additional properties associated with a data platform
     * @deprecated Field no longer supported
     */
    info?: Maybe<DataPlatformInfo>;
    /** Edges extending from this entity */
    relationships?: Maybe<EntityRelationshipsResult>;
};

/**
 * A Data Platform represents a specific third party Data System or Tool Examples include
 * warehouses like Snowflake, orchestrators like Airflow, and dashboarding tools like Looker
 */
export type DataPlatformRelationshipsArgs = {
    input: RelationshipsInput;
};

/**
 * Deprecated, use DataPlatformProperties instead
 * Additional read only information about a Data Platform
 */
export type DataPlatformInfo = {
    __typename?: 'DataPlatformInfo';
    /** The platform category */
    type: PlatformType;
    /** Display name associated with the platform */
    displayName?: Maybe<Scalars['String']>;
    /** The delimiter in the dataset names on the data platform */
    datasetNameDelimiter: Scalars['String'];
    /** A logo URL associated with the platform */
    logoUrl?: Maybe<Scalars['String']>;
};

/** A Data Platform instance represents an instance of a 3rd party platform like Looker, Snowflake, etc. */
export type DataPlatformInstance = Entity & {
    __typename?: 'DataPlatformInstance';
    /** Urn of the data platform */
    urn: Scalars['String'];
    /** A standard Entity Type */
    type: EntityType;
    /** Name of the data platform */
    platform: DataPlatform;
    /** The platform instance id */
    instanceId: Scalars['String'];
    /** Edges extending from this entity */
    relationships?: Maybe<EntityRelationshipsResult>;
};

/** A Data Platform instance represents an instance of a 3rd party platform like Looker, Snowflake, etc. */
export type DataPlatformInstanceRelationshipsArgs = {
    input: RelationshipsInput;
};

/** Additional read only properties about a Data Platform */
export type DataPlatformProperties = {
    __typename?: 'DataPlatformProperties';
    /** The platform category */
    type: PlatformType;
    /** Display name associated with the platform */
    displayName?: Maybe<Scalars['String']>;
    /** The delimiter in the dataset names on the data platform */
    datasetNameDelimiter: Scalars['String'];
    /** A logo URL associated with the platform */
    logoUrl?: Maybe<Scalars['String']>;
};

/** A Dataset entity, which encompasses Relational Tables, Document store collections, streaming topics, and other sets of data having an independent lifecycle */
export type Dataset = EntityWithRelationships &
    Entity & {
        __typename?: 'Dataset';
        /** The primary key of the Dataset */
        urn: Scalars['String'];
        /** The standard Entity Type */
        type: EntityType;
        /** Standardized platform urn where the dataset is defined */
        platform: DataPlatform;
        /** The parent container in which the entity resides */
        container?: Maybe<Container>;
        /** The Dataset display name */
        name: Scalars['String'];
        /** An additional set of read only properties */
        properties?: Maybe<DatasetProperties>;
        /** An additional set of of read write properties */
        editableProperties?: Maybe<DatasetEditableProperties>;
        /** Ownership metadata of the dataset */
        ownership?: Maybe<Ownership>;
        /** The deprecation status */
        deprecation?: Maybe<Deprecation>;
        /** References to internal resources related to the dataset */
        institutionalMemory?: Maybe<InstitutionalMemory>;
        /** Schema metadata of the dataset, available by version number */
        schemaMetadata?: Maybe<SchemaMetadata>;
        /** Editable schema metadata of the dataset */
        editableSchemaMetadata?: Maybe<EditableSchemaMetadata>;
        /** Status of the Dataset */
        status?: Maybe<Status>;
        /** Tags used for searching dataset */
        tags?: Maybe<GlobalTags>;
        /** The structured glossary terms associated with the dataset */
        glossaryTerms?: Maybe<GlossaryTerms>;
        /** The Domain associated with the Dataset */
        domain?: Maybe<Domain>;
        /** Statistics about how this Dataset is used */
        usageStats?: Maybe<UsageQueryResult>;
        /**
         * Profile Stats resource that retrieves the events in a previous unit of time in descending order
         * If no start or end time are provided, the most recent events will be returned
         */
        datasetProfiles?: Maybe<Array<DatasetProfile>>;
        /** Operational events for an entity. */
        operations?: Maybe<Array<Operation>>;
        /** Edges extending from this entity */
        relationships?: Maybe<EntityRelationshipsResult>;
        /**
         * Schema metadata of the dataset
         * @deprecated Use `schemaMetadata`
         */
        schema?: Maybe<Schema>;
        /**
         * Deprecated, use properties field instead
         * External URL associated with the Dataset
         * @deprecated Field no longer supported
         */
        externalUrl?: Maybe<Scalars['String']>;
        /**
         * Deprecated,se the properties field instead
         * Environment in which the dataset belongs to or where it was generated
         * Note that this field will soon be deprecated in favor of a more standardized concept of Environment
         * @deprecated Field no longer supported
         */
        origin: FabricType;
        /**
         * Deprecated, use the properties field instead
         * Read only technical description for dataset
         * @deprecated Field no longer supported
         */
        description?: Maybe<Scalars['String']>;
        /**
         * Deprecated, do not use this field
         * The logical type of the dataset ie table, stream, etc
         * @deprecated Field no longer supported
         */
        platformNativeType?: Maybe<PlatformNativeType>;
        /**
         * Deprecated, use properties instead
         * Native Dataset Uri
         * Uri should not include any environment specific properties
         * @deprecated Field no longer supported
         */
        uri?: Maybe<Scalars['String']>;
        /**
         * Deprecated, use tags field instead
         * The structured tags associated with the dataset
         * @deprecated Field no longer supported
         */
        globalTags?: Maybe<GlobalTags>;
        /** Sub Types that this entity implements */
        subTypes?: Maybe<SubTypes>;
        /** View related properties. Only relevant if subtypes field contains view. */
        viewProperties?: Maybe<ViewProperties>;
        /**
         * Experimental API.
         * For fetching extra entities that do not have custom UI code yet
         */
        aspects?: Maybe<Array<RawAspect>>;
    };

/** A Dataset entity, which encompasses Relational Tables, Document store collections, streaming topics, and other sets of data having an independent lifecycle */
export type DatasetSchemaMetadataArgs = {
    version?: Maybe<Scalars['Long']>;
};

/** A Dataset entity, which encompasses Relational Tables, Document store collections, streaming topics, and other sets of data having an independent lifecycle */
export type DatasetUsageStatsArgs = {
    resource: Scalars['String'];
    range?: Maybe<TimeRange>;
};

/** A Dataset entity, which encompasses Relational Tables, Document store collections, streaming topics, and other sets of data having an independent lifecycle */
export type DatasetDatasetProfilesArgs = {
    startTimeMillis?: Maybe<Scalars['Long']>;
    endTimeMillis?: Maybe<Scalars['Long']>;
    limit?: Maybe<Scalars['Int']>;
};

/** A Dataset entity, which encompasses Relational Tables, Document store collections, streaming topics, and other sets of data having an independent lifecycle */
export type DatasetOperationsArgs = {
    startTimeMillis?: Maybe<Scalars['Long']>;
    endTimeMillis?: Maybe<Scalars['Long']>;
    limit?: Maybe<Scalars['Int']>;
};

/** A Dataset entity, which encompasses Relational Tables, Document store collections, streaming topics, and other sets of data having an independent lifecycle */
export type DatasetRelationshipsArgs = {
    input: RelationshipsInput;
};

/** A Dataset entity, which encompasses Relational Tables, Document store collections, streaming topics, and other sets of data having an independent lifecycle */
export type DatasetAspectsArgs = {
    input?: Maybe<AspectParams>;
};

/**
 * Deprecated, use Deprecation instead
 * Information about Dataset deprecation status
 * Note that this model will soon be migrated to a more general purpose Entity status
 */
export type DatasetDeprecation = {
    __typename?: 'DatasetDeprecation';
    /** Whether the dataset has been deprecated by owner */
    deprecated: Scalars['Boolean'];
    /** The time user plan to decommission this dataset */
    decommissionTime?: Maybe<Scalars['Long']>;
    /** Additional information about the dataset deprecation plan */
    note: Scalars['String'];
    /** The user who will be credited for modifying this deprecation content */
    actor?: Maybe<Scalars['String']>;
};

/** An update for the deprecation information for a Metadata Entity */
export type DatasetDeprecationUpdate = {
    /** Whether the dataset is deprecated */
    deprecated: Scalars['Boolean'];
    /** The time user plan to decommission this dataset */
    decommissionTime?: Maybe<Scalars['Long']>;
    /** Additional information about the dataset deprecation plan */
    note: Scalars['String'];
};

/**
 * Dataset properties that are editable via the UI This represents logical metadata,
 * as opposed to technical metadata
 */
export type DatasetEditableProperties = {
    __typename?: 'DatasetEditableProperties';
    /** Description of the Dataset */
    description?: Maybe<Scalars['String']>;
};

/** Update to writable Dataset fields */
export type DatasetEditablePropertiesUpdate = {
    /** Writable description aka documentation for a Dataset */
    description: Scalars['String'];
};

/** An individual Dataset Field Profile */
export type DatasetFieldProfile = {
    __typename?: 'DatasetFieldProfile';
    /** The standardized path of the field */
    fieldPath: Scalars['String'];
    /** The unique value count for the field across the Dataset */
    uniqueCount?: Maybe<Scalars['Long']>;
    /** The proportion of rows with unique values across the Dataset */
    uniqueProportion?: Maybe<Scalars['Float']>;
    /** The number of NULL row values across the Dataset */
    nullCount?: Maybe<Scalars['Long']>;
    /** The proportion of rows with NULL values across the Dataset */
    nullProportion?: Maybe<Scalars['Float']>;
    /** The min value for the field */
    min?: Maybe<Scalars['String']>;
    /** The max value for the field */
    max?: Maybe<Scalars['String']>;
    /** The mean value for the field */
    mean?: Maybe<Scalars['String']>;
    /** The median value for the field */
    median?: Maybe<Scalars['String']>;
    /** The standard deviation for the field */
    stdev?: Maybe<Scalars['String']>;
    /** A set of sample values for the field */
    sampleValues?: Maybe<Array<Scalars['String']>>;
};

/**
 * Deprecated
 * The type of an edge between two Datasets
 */
export enum DatasetLineageType {
    /** Direct copy without modification */
    Copy = 'COPY',
    /** Transformed dataset */
    Transformed = 'TRANSFORMED',
    /** Represents a view defined on the sources */
    View = 'VIEW',
}

/** A Dataset Profile associated with a Dataset, containing profiling statistics about the Dataset */
export type DatasetProfile = TimeSeriesAspect & {
    __typename?: 'DatasetProfile';
    /** The time at which the profile was reported */
    timestampMillis: Scalars['Long'];
    /** An optional row count of the Dataset */
    rowCount?: Maybe<Scalars['Long']>;
    /** An optional column count of the Dataset */
    columnCount?: Maybe<Scalars['Long']>;
    /** An optional set of per field statistics obtained in the profile */
    fieldProfiles?: Maybe<Array<DatasetFieldProfile>>;
    /** Information about the partition that was profiled */
    partitionSpec?: Maybe<PartitionSpec>;
};

/** Additional read only properties about a Dataset */
export type DatasetProperties = {
    __typename?: 'DatasetProperties';
    /** The name of the dataset used in display */
    name?: Maybe<Scalars['String']>;
    /**
     * Environment in which the dataset belongs to or where it was generated
     * Note that this field will soon be deprecated in favor of a more standardized concept of Environment
     */
    origin: FabricType;
    /** Read only technical description for dataset */
    description?: Maybe<Scalars['String']>;
    /** Custom properties of the Dataset */
    customProperties?: Maybe<Array<StringMapEntry>>;
    /** External URL associated with the Dataset */
    externalUrl?: Maybe<Scalars['String']>;
};

/** Arguments provided to update a Dataset Entity */
export type DatasetUpdateInput = {
    /** Update to ownership */
    ownership?: Maybe<OwnershipUpdate>;
    /** Update to deprecation status */
    deprecation?: Maybe<DatasetDeprecationUpdate>;
    /** Update to institutional memory, ie documentation */
    institutionalMemory?: Maybe<InstitutionalMemoryUpdate>;
    /**
     * Deprecated, use tags field instead
     * Update to global tags
     */
    globalTags?: Maybe<GlobalTagsUpdate>;
    /** Update to tags */
    tags?: Maybe<GlobalTagsUpdate>;
    /** Update to editable schema metadata of the dataset */
    editableSchemaMetadata?: Maybe<EditableSchemaMetadataUpdate>;
    /** Update to editable properties */
    editableProperties?: Maybe<DatasetEditablePropertiesUpdate>;
};

/** For consumption by UI only */
export enum DateInterval {
    Second = 'SECOND',
    Minute = 'MINUTE',
    Hour = 'HOUR',
    Day = 'DAY',
    Week = 'WEEK',
    Month = 'MONTH',
    Year = 'YEAR',
}

/** For consumption by UI only */
export type DateRange = {
    __typename?: 'DateRange';
    start: Scalars['String'];
    end: Scalars['String'];
};

/** Information about Metadata Entity deprecation status */
export type Deprecation = {
    __typename?: 'Deprecation';
    /** Whether the entity has been deprecated by owner */
    deprecated: Scalars['Boolean'];
    /** The time user plan to decommission this entity */
    decommissionTime?: Maybe<Scalars['Long']>;
    /** Additional information about the entity deprecation plan */
    note: Scalars['String'];
    /** The user who will be credited for modifying this deprecation content */
    actor?: Maybe<Scalars['String']>;
};

/** Incubating. Updates the description of a resource. Currently supports DatasetField descriptions only */
export type DescriptionUpdateInput = {
    /** The new description */
    description: Scalars['String'];
    /** The primary key of the resource to attach the description to, eg dataset urn */
    resourceUrn: Scalars['String'];
    /** An optional sub resource type */
    subResourceType?: Maybe<SubResourceType>;
    /** A sub resource identifier, eg dataset field path */
    subResource?: Maybe<Scalars['String']>;
};

/** A domain, or a logical grouping of Metadata Entities */
export type Domain = Entity & {
    __typename?: 'Domain';
    /** The primary key of the domain */
    urn: Scalars['String'];
    /** A standard Entity Type */
    type: EntityType;
    /** Id of the domain */
    id: Scalars['String'];
    /** Properties about a domain */
    properties?: Maybe<DomainProperties>;
    /** Ownership metadata of the dataset */
    ownership?: Maybe<Ownership>;
    /** References to internal resources related to the dataset */
    institutionalMemory?: Maybe<InstitutionalMemory>;
    /** Children entities inside of the Domain */
    entities?: Maybe<SearchResults>;
    /** Edges extending from this entity */
    relationships?: Maybe<EntityRelationshipsResult>;
};

/** A domain, or a logical grouping of Metadata Entities */
export type DomainEntitiesArgs = {
    input?: Maybe<DomainEntitiesInput>;
};

/** A domain, or a logical grouping of Metadata Entities */
export type DomainRelationshipsArgs = {
    input: RelationshipsInput;
};

/** Input required to fetch the entities inside of a Domain. */
export type DomainEntitiesInput = {
    /** Optional query filter for particular entities inside the domain */
    query?: Maybe<Scalars['String']>;
    /** The offset of the result set */
    start?: Maybe<Scalars['Int']>;
    /** The number of entities to include in result set */
    count?: Maybe<Scalars['Int']>;
    /** Optional Facet filters to apply to the result set */
    filters?: Maybe<Array<FacetFilterInput>>;
};

/** Properties about a domain */
export type DomainProperties = {
    __typename?: 'DomainProperties';
    /** Display name of the domain */
    name: Scalars['String'];
    /** Description of the Domain */
    description?: Maybe<Scalars['String']>;
};

/** Deprecated, use relationships query instead */
export type DownstreamEntityRelationships = {
    __typename?: 'DownstreamEntityRelationships';
    entities?: Maybe<Array<Maybe<EntityRelationshipLegacy>>>;
};

/** Editable schema field metadata ie descriptions, tags, etc */
export type EditableSchemaFieldInfo = {
    __typename?: 'EditableSchemaFieldInfo';
    /** Flattened name of a field identifying the field the editable info is applied to */
    fieldPath: Scalars['String'];
    /** Edited description of the field */
    description?: Maybe<Scalars['String']>;
    /**
     * Deprecated, use tags field instead
     * Tags associated with the field
     * @deprecated Field no longer supported
     */
    globalTags?: Maybe<GlobalTags>;
    /** Tags associated with the field */
    tags?: Maybe<GlobalTags>;
    /** Glossary terms associated with the field */
    glossaryTerms?: Maybe<GlossaryTerms>;
};

/** Update to writable schema field metadata */
export type EditableSchemaFieldInfoUpdate = {
    /** Flattened name of a field identifying the field the editable info is applied to */
    fieldPath: Scalars['String'];
    /** Edited description of the field */
    description?: Maybe<Scalars['String']>;
    /** Tags associated with the field */
    globalTags?: Maybe<GlobalTagsUpdate>;
};

/** Information about schema metadata that is editable via the UI */
export type EditableSchemaMetadata = {
    __typename?: 'EditableSchemaMetadata';
    /** Editable schema field metadata */
    editableSchemaFieldInfo: Array<EditableSchemaFieldInfo>;
};

/** Update to editable schema metadata of the dataset */
export type EditableSchemaMetadataUpdate = {
    /** Update to writable schema field metadata */
    editableSchemaFieldInfo: Array<EditableSchemaFieldInfoUpdate>;
};

/** Additional read write Tag properties */
export type EditableTagProperties = {
    __typename?: 'EditableTagProperties';
    /** A description of the Tag */
    description?: Maybe<Scalars['String']>;
};

/** A top level Metadata Entity */
export type Entity = {
    /** A primary key of the Metadata Entity */
    urn: Scalars['String'];
    /** A standard Entity Type */
    type: EntityType;
    /** List of relationships between the source Entity and some destination entities with a given types */
    relationships?: Maybe<EntityRelationshipsResult>;
};

/** A top level Metadata Entity */
export type EntityRelationshipsArgs = {
    input: RelationshipsInput;
};

/** Input for the get entity counts endpoint */
export type EntityCountInput = {
    types?: Maybe<Array<EntityType>>;
};

export type EntityCountResult = {
    __typename?: 'EntityCountResult';
    entityType: EntityType;
    count: Scalars['Int'];
};

export type EntityCountResults = {
    __typename?: 'EntityCountResults';
    counts?: Maybe<Array<EntityCountResult>>;
};

/** Context to define the entity profile page */
export type EntityProfileParams = {
    __typename?: 'EntityProfileParams';
    /** Urn of the entity being shown */
    urn: Scalars['String'];
};

/** A relationship between two entities TODO Migrate all entity relationships to this more generic model */
export type EntityRelationship = {
    __typename?: 'EntityRelationship';
    /** The type of the relationship */
    type: Scalars['String'];
    /** The direction of the relationship relative to the source entity */
    direction: RelationshipDirection;
    /** Entity that is related via lineage */
    entity: Entity;
    /** An AuditStamp corresponding to the last modification of this relationship */
    created?: Maybe<AuditStamp>;
};

/** Deprecated, use relationships query instead */
export type EntityRelationshipLegacy = {
    __typename?: 'EntityRelationshipLegacy';
    /** Entity that is related via lineage */
    entity?: Maybe<EntityWithRelationships>;
    /** An AuditStamp corresponding to the last modification of this relationship */
    created?: Maybe<AuditStamp>;
};

/** A list of relationship information associated with a source Entity */
export type EntityRelationshipsResult = {
    __typename?: 'EntityRelationshipsResult';
    /** Start offset of the result set */
    start?: Maybe<Scalars['Int']>;
    /** Number of results in the returned result set */
    count?: Maybe<Scalars['Int']>;
    /** Total number of results in the result set */
    total?: Maybe<Scalars['Int']>;
    /** Relationships in the result set */
    relationships: Array<EntityRelationship>;
};

/** Context that defines an entity page requesting recommendations */
export type EntityRequestContext = {
    /** Type of the enity being displayed */
    type: EntityType;
    /** Urn of the entity being displayed */
    urn: Scalars['String'];
};

/** A top level Metadata Entity Type */
export enum EntityType {
    /** A Domain containing Metadata Entities */
    Domain = 'DOMAIN',
    /** The Dataset Entity */
    Dataset = 'DATASET',
    /** The CorpUser Entity */
    CorpUser = 'CORP_USER',
    /** The CorpGroup Entity */
    CorpGroup = 'CORP_GROUP',
    /** The DataPlatform Entity */
    DataPlatform = 'DATA_PLATFORM',
    /** The Dashboard Entity */
    Dashboard = 'DASHBOARD',
    /** The Chart Entity */
    Chart = 'CHART',
    /** The Data Flow (or Data Pipeline) Entity, */
    DataFlow = 'DATA_FLOW',
    /** The Data Job (or Data Task) Entity */
    DataJob = 'DATA_JOB',
    /** The Tag Entity */
    Tag = 'TAG',
    /** The Glossary Term Entity */
    GlossaryTerm = 'GLOSSARY_TERM',
    /** A container of Metadata Entities */
    Container = 'CONTAINER',
    /** The ML Model Entity */
    Mlmodel = 'MLMODEL',
    /** The MLModelGroup Entity */
    MlmodelGroup = 'MLMODEL_GROUP',
    /** ML Feature Table Entity */
    MlfeatureTable = 'MLFEATURE_TABLE',
    /** The ML Feature Entity */
    Mlfeature = 'MLFEATURE',
    /** The ML Primary Key Entity */
    MlprimaryKey = 'MLPRIMARY_KEY',
    /** A DataHub Managed Ingestion Source */
    IngestionSource = 'INGESTION_SOURCE',
    /** A DataHub ExecutionRequest */
    ExecutionRequest = 'EXECUTION_REQUEST',
}

/** Deprecated, use relationships field instead */
export type EntityWithRelationships = {
    /** A primary key associated with the Metadata Entity */
    urn: Scalars['String'];
    /** A standard Entity Type */
    type: EntityType;
    /** Edges extending from this entity */
    relationships?: Maybe<EntityRelationshipsResult>;
};

/** Deprecated, use relationships field instead */
export type EntityWithRelationshipsRelationshipsArgs = {
    input: RelationshipsInput;
};

export type EthicalConsiderations = {
    __typename?: 'EthicalConsiderations';
    /** Does the model use any sensitive data eg, protected classes */
    data?: Maybe<Array<Scalars['String']>>;
    /** Is the model intended to inform decisions about matters central to human life or flourishing eg, health or safety */
    humanLife?: Maybe<Array<Scalars['String']>>;
    /** What risk mitigation strategies were used during model development */
    mitigations?: Maybe<Array<Scalars['String']>>;
    /**
     * What risks may be present in model usage
     * Try to identify the potential recipients, likelihood, and magnitude of harms
     * If these cannot be determined, note that they were considered but remain unknown
     */
    risksAndHarms?: Maybe<Array<Scalars['String']>>;
    /**
     * Are there any known model use cases that are especially fraught
     * This may connect directly to the intended use section
     */
    useCases?: Maybe<Array<Scalars['String']>>;
};

/** Retrieve an ingestion execution request */
export type ExecutionRequest = {
    __typename?: 'ExecutionRequest';
    /** Urn of the execution request */
    urn: Scalars['String'];
    /** Input provided when creating the Execution Request */
    input: ExecutionRequestInput;
    /** Result of the execution request */
    result?: Maybe<ExecutionRequestResult>;
};

/** Input provided when creating an Execution Request */
export type ExecutionRequestInput = {
    __typename?: 'ExecutionRequestInput';
    /** The type of the task to executed */
    task: Scalars['String'];
    /** The source of the execution request */
    source: ExecutionRequestSource;
    /** Arguments provided when creating the execution request */
    arguments?: Maybe<Array<StringMapEntry>>;
    /** The time at which the request was created */
    requestedAt: Scalars['Long'];
};

/** The result of an ExecutionRequest */
export type ExecutionRequestResult = {
    __typename?: 'ExecutionRequestResult';
    /** The result of the request, e.g. either SUCCEEDED or FAILED */
    status: Scalars['String'];
    /** Time at which the task began */
    startTimeMs?: Maybe<Scalars['Long']>;
    /** Duration of the task */
    durationMs?: Maybe<Scalars['Long']>;
    /** A report about the ingestion run */
    report?: Maybe<Scalars['String']>;
};

/** Information about the source of an execution request */
export type ExecutionRequestSource = {
    __typename?: 'ExecutionRequestSource';
    /** The type of the source, e.g. SCHEDULED_INGESTION_SOURCE */
    type?: Maybe<Scalars['String']>;
};

/**
 * An environment identifier for a particular Entity, ie staging or production
 * Note that this model will soon be deprecated in favor of a more general purpose of notion
 * of data environment
 */
export enum FabricType {
    /** Designates development fabrics */
    Dev = 'DEV',
    /** Designates testing fabrics */
    Test = 'TEST',
    /** Designates quality assurance fabrics */
    Qa = 'QA',
    /** Designates user acceptance testing fabrics */
    Uat = 'UAT',
    /** Designates early integration fabrics */
    Ei = 'EI',
    /** Designates staging fabrics */
    Stg = 'STG',
    /** Designates non-production fabrics */
    NonProd = 'NON_PROD',
    /** Designates production fabrics */
    Prod = 'PROD',
    /** Designates corporation fabrics */
    Corp = 'CORP',
}

/** Facet filters to apply to search results */
export type FacetFilterInput = {
    /** Name of field to filter by */
    field: Scalars['String'];
    /** Value of the field to filter by */
    value: Scalars['String'];
};

/** Contains valid fields to filter search results further on */
export type FacetMetadata = {
    __typename?: 'FacetMetadata';
    /** Name of a field present in the search entity */
    field: Scalars['String'];
    /** Display name of the field */
    displayName?: Maybe<Scalars['String']>;
    /** Aggregated search result counts by value of the field */
    aggregations: Array<AggregationMetadata>;
};

/** The usage for a particular Dataset field */
export type FieldUsageCounts = {
    __typename?: 'FieldUsageCounts';
    /** The path of the field */
    fieldName?: Maybe<Scalars['String']>;
    /** The count of usages */
    count?: Maybe<Scalars['Int']>;
};

/** Facet filters to apply to search results */
export type Filter = {
    __typename?: 'Filter';
    /** Name of field to filter by */
    field: Scalars['String'];
    /** Value of the field to filter by */
    value: Scalars['String'];
};

export type FloatBox = {
    __typename?: 'FloatBox';
    floatValue: Scalars['Float'];
};

/** Metadata around a foreign key constraint between two datasets */
export type ForeignKeyConstraint = {
    __typename?: 'ForeignKeyConstraint';
    /** The human-readable name of the constraint */
    name?: Maybe<Scalars['String']>;
    /** List of fields in the foreign dataset */
    foreignFields?: Maybe<Array<Maybe<SchemaFieldEntity>>>;
    /** List of fields in this dataset */
    sourceFields?: Maybe<Array<Maybe<SchemaFieldEntity>>>;
    /** The foreign dataset for easy reference */
    foreignDataset?: Maybe<Dataset>;
};

/** Input required to fetch a new Access Token. */
export type GetAccessTokenInput = {
    /** The type of the Access Token. */
    type: AccessTokenType;
    /** The actor associated with the Access Token. */
    actorUrn: Scalars['String'];
    /** The duration for which the Access Token is valid. */
    duration: AccessTokenDuration;
};

/** Input arguments for retrieving the plaintext values of a set of secrets */
export type GetSecretValuesInput = {
    /** A list of secret names */
    secrets: Array<Scalars['String']>;
};

/** Tags attached to a particular Metadata Entity */
export type GlobalTags = {
    __typename?: 'GlobalTags';
    /** The set of tags attached to the Metadata Entity */
    tags?: Maybe<Array<TagAssociation>>;
};

/**
 * Deprecated, use addTag or removeTag mutation instead
 * Update to the Tags associated with a Metadata Entity
 */
export type GlobalTagsUpdate = {
    /** The new set of tags */
    tags?: Maybe<Array<TagAssociationUpdate>>;
};

/**
 * A Glossary Term, or a node in a Business Glossary representing a standardized domain
 * data type
 */
export type GlossaryTerm = Entity & {
    __typename?: 'GlossaryTerm';
    /** The primary key of the glossary term */
    urn: Scalars['String'];
    /** Ownership metadata of the dataset */
    ownership?: Maybe<Ownership>;
    /** A standard Entity Type */
    type: EntityType;
    /** Display name of the glossary term */
    name: Scalars['String'];
    /** hierarchicalName of glossary term */
    hierarchicalName: Scalars['String'];
    /** Additional read only properties associated with the Glossary Term */
    properties?: Maybe<GlossaryTermProperties>;
    /**
     * Deprecated, use properties field instead
     * Details of the Glossary Term
     */
    glossaryTermInfo?: Maybe<GlossaryTermInfo>;
    /** Edges extending from this entity */
    relationships?: Maybe<EntityRelationshipsResult>;
    /** Schema metadata of the dataset */
    schemaMetadata?: Maybe<SchemaMetadata>;
};

/**
 * A Glossary Term, or a node in a Business Glossary representing a standardized domain
 * data type
 */
export type GlossaryTermRelationshipsArgs = {
    input: RelationshipsInput;
};

/**
 * A Glossary Term, or a node in a Business Glossary representing a standardized domain
 * data type
 */
export type GlossaryTermSchemaMetadataArgs = {
    version?: Maybe<Scalars['Long']>;
};

/**
 * An edge between a Metadata Entity and a Glossary Term Modeled as a struct to permit
 * additional attributes
 * TODO Consider whether this query should be serviced by the relationships field
 */
export type GlossaryTermAssociation = {
    __typename?: 'GlossaryTermAssociation';
    /** The glossary term itself */
    term: GlossaryTerm;
};

/**
 * Deprecated, use GlossaryTermProperties instead
 * Information about a glossary term
 */
export type GlossaryTermInfo = {
    __typename?: 'GlossaryTermInfo';
    /** Definition of the glossary term */
    definition: Scalars['String'];
    /** Term Source of the glossary term */
    termSource: Scalars['String'];
    /** Source Ref of the glossary term */
    sourceRef?: Maybe<Scalars['String']>;
    /** Source Url of the glossary term */
    sourceUrl?: Maybe<Scalars['String']>;
    /** Properties of the glossary term */
    customProperties?: Maybe<Array<StringMapEntry>>;
    /** Schema definition of glossary term */
    rawSchema?: Maybe<Scalars['String']>;
};

/** Additional read only properties about a Glossary Term */
export type GlossaryTermProperties = {
    __typename?: 'GlossaryTermProperties';
    /** Definition of the glossary term */
    definition: Scalars['String'];
    /** Term Source of the glossary term */
    termSource: Scalars['String'];
    /** Source Ref of the glossary term */
    sourceRef?: Maybe<Scalars['String']>;
    /** Source Url of the glossary term */
    sourceUrl?: Maybe<Scalars['String']>;
    /** Properties of the glossary term */
    customProperties?: Maybe<Array<StringMapEntry>>;
    /** Schema definition of glossary term */
    rawSchema?: Maybe<Scalars['String']>;
};

/** Glossary Terms attached to a particular Metadata Entity */
export type GlossaryTerms = {
    __typename?: 'GlossaryTerms';
    /** The set of glossary terms attached to the Metadata Entity */
    terms?: Maybe<Array<GlossaryTermAssociation>>;
};

/** For consumption by UI only */
export type Highlight = {
    __typename?: 'Highlight';
    value: Scalars['Int'];
    title: Scalars['String'];
    body: Scalars['String'];
};

export type HyperParameterMap = {
    __typename?: 'HyperParameterMap';
    key: Scalars['String'];
    value: HyperParameterValueType;
};

export type HyperParameterValueType = StringBox | IntBox | FloatBox | BooleanBox;

/** Configurations related to Identity Management */
export type IdentityManagementConfig = {
    __typename?: 'IdentityManagementConfig';
    /** Whether identity management screen is able to be shown in the UI */
    enabled: Scalars['Boolean'];
};

/** A set of configurations for an Ingestion Source */
export type IngestionConfig = {
    __typename?: 'IngestionConfig';
    /** The JSON-encoded recipe to use for ingestion */
    recipe: Scalars['String'];
    /** Advanced: The specific executor that should handle the execution request. Defaults to 'default'. */
    executorId: Scalars['String'];
    /** Advanced: The version of the ingestion framework to use */
    version?: Maybe<Scalars['String']>;
};

/** The runs associated with an Ingestion Source managed by DataHub */
export type IngestionRun = {
    __typename?: 'IngestionRun';
    /** The urn of the execution request associated with the user */
    executionRequestUrn?: Maybe<Scalars['String']>;
};

/** A schedule associated with an Ingestion Source */
export type IngestionSchedule = {
    __typename?: 'IngestionSchedule';
    /** Time Zone abbreviation (e.g. GMT, EDT). Defaults to UTC. */
    timezone?: Maybe<Scalars['String']>;
    /** The cron-formatted interval to execute the ingestion source on */
    interval: Scalars['String'];
};

/** An Ingestion Source Entity */
export type IngestionSource = {
    __typename?: 'IngestionSource';
    /** The primary key of the Ingestion Source */
    urn: Scalars['String'];
    /** The type of the source itself, e.g. mysql, bigquery, bigquery-usage. Should match the recipe. */
    type: Scalars['String'];
    /** The display name of the Ingestion Source */
    name: Scalars['String'];
    /** An optional schedule associated with the Ingestion Source */
    schedule?: Maybe<IngestionSchedule>;
    /** An type-specific set of configurations for the ingestion source */
    config: IngestionConfig;
    /** Previous requests to execute the ingestion source */
    executions?: Maybe<IngestionSourceExecutionRequests>;
};

/** An Ingestion Source Entity */
export type IngestionSourceExecutionsArgs = {
    start?: Maybe<Scalars['Int']>;
    count?: Maybe<Scalars['Int']>;
};

/** Requests for execution associated with an ingestion source */
export type IngestionSourceExecutionRequests = {
    __typename?: 'IngestionSourceExecutionRequests';
    /** The starting offset of the result set */
    start?: Maybe<Scalars['Int']>;
    /** The number of results to be returned */
    count?: Maybe<Scalars['Int']>;
    /** The total number of results in the result set */
    total?: Maybe<Scalars['Int']>;
    /** The execution request objects comprising the result set */
    executionRequests: Array<ExecutionRequest>;
};

/** Institutional memory metadata, meaning internal links and pointers related to an Entity */
export type InstitutionalMemory = {
    __typename?: 'InstitutionalMemory';
    /** List of records that represent the institutional memory or internal documentation of an entity */
    elements: Array<InstitutionalMemoryMetadata>;
};

/** An institutional memory resource about a particular Metadata Entity */
export type InstitutionalMemoryMetadata = {
    __typename?: 'InstitutionalMemoryMetadata';
    /** Link to a document or wiki page or another internal resource */
    url: Scalars['String'];
    /** Label associated with the URL */
    label: Scalars['String'];
    /** The author of this metadata */
    author: CorpUser;
    /** An AuditStamp corresponding to the creation of this resource */
    created: AuditStamp;
    /**
     * Deprecated, use label instead
     * Description of the resource
     * @deprecated Field no longer supported
     */
    description: Scalars['String'];
};

/**
 * An institutional memory to add to a Metadata Entity
 * TODO Add a USER or GROUP actor enum
 */
export type InstitutionalMemoryMetadataUpdate = {
    /** Link to a document or wiki page or another internal resource */
    url: Scalars['String'];
    /** Description of the resource */
    description?: Maybe<Scalars['String']>;
    /** The corp user urn of the author of the metadata */
    author: Scalars['String'];
    /** The time at which this metadata was created */
    createdAt?: Maybe<Scalars['Long']>;
};

/** An update for the institutional memory information for a Metadata Entity */
export type InstitutionalMemoryUpdate = {
    /** The individual references in the institutional memory */
    elements: Array<InstitutionalMemoryMetadataUpdate>;
};

export type IntBox = {
    __typename?: 'IntBox';
    intValue: Scalars['Int'];
};

export type IntendedUse = {
    __typename?: 'IntendedUse';
    /** Primary Use cases for the model */
    primaryUses?: Maybe<Array<Scalars['String']>>;
    /** Primary Intended Users */
    primaryUsers?: Maybe<Array<IntendedUserType>>;
    /** Out of scope uses of the MLModel */
    outOfScopeUses?: Maybe<Array<Scalars['String']>>;
};

export enum IntendedUserType {
    /** Developed for Enterprise Users */
    Enterprise = 'ENTERPRISE',
    /** Developed for Hobbyists */
    Hobby = 'HOBBY',
    /** Developed for Entertainment Purposes */
    Entertainment = 'ENTERTAINMENT',
}

/** Information about a raw Key Value Schema */
export type KeyValueSchema = {
    __typename?: 'KeyValueSchema';
    /** Raw key schema */
    keySchema: Scalars['String'];
    /** Raw value schema */
    valueSchema: Scalars['String'];
};

/** Input required when listing DataHub Domains */
export type ListDomainsInput = {
    /** The starting offset of the result set returned */
    start?: Maybe<Scalars['Int']>;
    /** The maximum number of Domains to be returned in the result set */
    count?: Maybe<Scalars['Int']>;
};

/** The result obtained when listing DataHub Domains */
export type ListDomainsResult = {
    __typename?: 'ListDomainsResult';
    /** The starting offset of the result set returned */
    start: Scalars['Int'];
    /** The number of Domains in the returned result set */
    count: Scalars['Int'];
    /** The total number of Domains in the result set */
    total: Scalars['Int'];
    /** The Domains themselves */
    domains: Array<Domain>;
};

/** Input required when listing DataHub Groups */
export type ListGroupsInput = {
    /** The starting offset of the result set returned */
    start?: Maybe<Scalars['Int']>;
    /** The maximum number of Policies to be returned in the result set */
    count?: Maybe<Scalars['Int']>;
};

/** The result obtained when listing DataHub Groups */
export type ListGroupsResult = {
    __typename?: 'ListGroupsResult';
    /** The starting offset of the result set returned */
    start: Scalars['Int'];
    /** The number of Policies in the returned result set */
    count: Scalars['Int'];
    /** The total number of Policies in the result set */
    total: Scalars['Int'];
    /** The groups themselves */
    groups: Array<CorpGroup>;
};

/** Input arguments for listing Ingestion Sources */
export type ListIngestionSourcesInput = {
    /** The starting offset of the result set */
    start?: Maybe<Scalars['Int']>;
    /** The number of results to be returned */
    count?: Maybe<Scalars['Int']>;
};

/** Results returned when listing ingestion sources */
export type ListIngestionSourcesResult = {
    __typename?: 'ListIngestionSourcesResult';
    /** The starting offset of the result set */
    start: Scalars['Int'];
    /** The number of results to be returned */
    count: Scalars['Int'];
    /** The total number of results in the result set */
    total: Scalars['Int'];
    /** The Ingestion Sources themselves */
    ingestionSources: Array<IngestionSource>;
};

/** Input required when listing DataHub Access Policies */
export type ListPoliciesInput = {
    /** The starting offset of the result set returned */
    start?: Maybe<Scalars['Int']>;
    /** The maximum number of Policies to be returned in the result set */
    count?: Maybe<Scalars['Int']>;
};

/** The result obtained when listing DataHub Access Policies */
export type ListPoliciesResult = {
    __typename?: 'ListPoliciesResult';
    /** The starting offset of the result set returned */
    start: Scalars['Int'];
    /** The number of Policies in the returned result set */
    count: Scalars['Int'];
    /** The total number of Policies in the result set */
    total: Scalars['Int'];
    /** The Policies themselves */
    policies: Array<Policy>;
};

/** Input arguments for fetching UI recommendations */
export type ListRecommendationsInput = {
    /** Urn of the actor requesting recommendations */
    userUrn: Scalars['String'];
    /** Context provider by the caller requesting recommendations */
    requestContext?: Maybe<RecommendationRequestContext>;
    /** Max number of modules to return */
    limit?: Maybe<Scalars['Int']>;
};

/** Results returned by the ListRecommendations query */
export type ListRecommendationsResult = {
    __typename?: 'ListRecommendationsResult';
    /** List of modules to show */
    modules: Array<RecommendationModule>;
};

/** Input for listing DataHub Secrets */
export type ListSecretsInput = {
    /** The starting offset of the result set */
    start?: Maybe<Scalars['Int']>;
    /** The number of results to be returned */
    count?: Maybe<Scalars['Int']>;
};

/** Input for listing DataHub Secrets */
export type ListSecretsResult = {
    __typename?: 'ListSecretsResult';
    /** The starting offset of the result set */
    start?: Maybe<Scalars['Int']>;
    /** The number of results to be returned */
    count?: Maybe<Scalars['Int']>;
    /** The total number of results in the result set */
    total?: Maybe<Scalars['Int']>;
    /** The secrets themselves */
    secrets: Array<Secret>;
};

/** Input required when listing DataHub Users */
export type ListUsersInput = {
    /** The starting offset of the result set returned */
    start?: Maybe<Scalars['Int']>;
    /** The maximum number of Policies to be returned in the result set */
    count?: Maybe<Scalars['Int']>;
};

/** The result obtained when listing DataHub Users */
export type ListUsersResult = {
    __typename?: 'ListUsersResult';
    /** The starting offset of the result set returned */
    start: Scalars['Int'];
    /** The number of Policies in the returned result set */
    count: Scalars['Int'];
    /** The total number of Policies in the result set */
    total: Scalars['Int'];
    /** The users themselves */
    users: Array<CorpUser>;
};

/** An ML Feature Metadata Entity Note that this entity is incubating */
export type MlFeature = Entity & {
    __typename?: 'MLFeature';
    /** The primary key of the ML Feature */
    urn: Scalars['String'];
    /** A standard Entity Type */
    type: EntityType;
    /** The display name for the ML Feature */
    name: Scalars['String'];
    /** MLFeature featureNamespace */
    featureNamespace: Scalars['String'];
    /** The description about the ML Feature */
    description?: Maybe<Scalars['String']>;
    /** MLFeature data type */
    dataType?: Maybe<MlFeatureDataType>;
    /** Ownership metadata of the MLFeature */
    ownership?: Maybe<Ownership>;
    /** ModelProperties metadata of the MLFeature */
    featureProperties?: Maybe<MlFeatureProperties>;
    /** References to internal resources related to the MLFeature */
    institutionalMemory?: Maybe<InstitutionalMemory>;
    /** Status metadata of the MLFeature */
    status?: Maybe<Status>;
    /** Deprecation */
    deprecation?: Maybe<Deprecation>;
    /** Edges extending from this entity */
    relationships?: Maybe<EntityRelationshipsResult>;
};

/** An ML Feature Metadata Entity Note that this entity is incubating */
export type MlFeatureRelationshipsArgs = {
    input: RelationshipsInput;
};

/** The data type associated with an individual Machine Learning Feature */
export enum MlFeatureDataType {
    Useless = 'USELESS',
    Nominal = 'NOMINAL',
    Ordinal = 'ORDINAL',
    Binary = 'BINARY',
    Count = 'COUNT',
    Time = 'TIME',
    Interval = 'INTERVAL',
    Image = 'IMAGE',
    Video = 'VIDEO',
    Audio = 'AUDIO',
    Text = 'TEXT',
    Map = 'MAP',
    Sequence = 'SEQUENCE',
    Set = 'SET',
    Continuous = 'CONTINUOUS',
    Byte = 'BYTE',
    Unknown = 'UNKNOWN',
}

export type MlFeatureProperties = {
    __typename?: 'MLFeatureProperties';
    description?: Maybe<Scalars['String']>;
    dataType?: Maybe<MlFeatureDataType>;
    version?: Maybe<VersionTag>;
    sources?: Maybe<Array<Maybe<Dataset>>>;
};

/** An ML Feature Table Entity Note that this entity is incubating */
export type MlFeatureTable = Entity & {
    __typename?: 'MLFeatureTable';
    /** The primary key of the ML Feature Table */
    urn: Scalars['String'];
    /** A standard Entity Type */
    type: EntityType;
    /** The display name */
    name: Scalars['String'];
    /** Standardized platform urn where the MLFeatureTable is defined */
    platform: DataPlatform;
    /** MLFeatureTable description */
    description?: Maybe<Scalars['String']>;
    /** Ownership metadata of the MLFeatureTable */
    ownership?: Maybe<Ownership>;
    /** Additional read only properties associated the the ML Feature Table */
    properties?: Maybe<MlFeatureTableProperties>;
    /**
     * Deprecated, use properties field instead
     * ModelProperties metadata of the MLFeature
     * @deprecated Field no longer supported
     */
    featureTableProperties?: Maybe<MlFeatureTableProperties>;
    /** References to internal resources related to the MLFeature */
    institutionalMemory?: Maybe<InstitutionalMemory>;
    /** Status metadata of the MLFeatureTable */
    status?: Maybe<Status>;
    /** Deprecation */
    deprecation?: Maybe<Deprecation>;
    /** Edges extending from this entity */
    relationships?: Maybe<EntityRelationshipsResult>;
};

/** An ML Feature Table Entity Note that this entity is incubating */
export type MlFeatureTableRelationshipsArgs = {
    input: RelationshipsInput;
};

export type MlFeatureTableProperties = {
    __typename?: 'MLFeatureTableProperties';
    description?: Maybe<Scalars['String']>;
    mlFeatures?: Maybe<Array<Maybe<MlFeature>>>;
    mlPrimaryKeys?: Maybe<Array<Maybe<MlPrimaryKey>>>;
};

export type MlHyperParam = {
    __typename?: 'MLHyperParam';
    name?: Maybe<Scalars['String']>;
    description?: Maybe<Scalars['String']>;
    value?: Maybe<Scalars['String']>;
    createdAt?: Maybe<Scalars['Long']>;
};

export type MlMetric = {
    __typename?: 'MLMetric';
    name?: Maybe<Scalars['String']>;
    description?: Maybe<Scalars['String']>;
    value?: Maybe<Scalars['String']>;
    createdAt?: Maybe<Scalars['Long']>;
};

/** An ML Model Metadata Entity Note that this entity is incubating */
export type MlModel = EntityWithRelationships &
    Entity & {
        __typename?: 'MLModel';
        /** The primary key of the ML model */
        urn: Scalars['String'];
        /** A standard Entity Type */
        type: EntityType;
        /** ML model display name */
        name: Scalars['String'];
        /** Standardized platform urn where the MLModel is defined */
        platform: DataPlatform;
        /** Fabric type where mlmodel belongs to or where it was generated */
        origin: FabricType;
        /** Human readable description for mlmodel */
        description?: Maybe<Scalars['String']>;
        /**
         * Deprecated, use tags field instead
         * The standard tags for the ML Model
         * @deprecated Field no longer supported
         */
        globalTags?: Maybe<GlobalTags>;
        /** The standard tags for the ML Model */
        tags?: Maybe<GlobalTags>;
        /** Ownership metadata of the mlmodel */
        ownership?: Maybe<Ownership>;
        /** Additional read only information about the ML Model */
        properties?: Maybe<MlModelProperties>;
        /** Intended use of the mlmodel */
        intendedUse?: Maybe<IntendedUse>;
        /** Factors metadata of the mlmodel */
        factorPrompts?: Maybe<MlModelFactorPrompts>;
        /** Metrics metadata of the mlmodel */
        metrics?: Maybe<Metrics>;
        /** Evaluation Data of the mlmodel */
        evaluationData?: Maybe<Array<BaseData>>;
        /** Training Data of the mlmodel */
        trainingData?: Maybe<Array<BaseData>>;
        /** Quantitative Analyses of the mlmodel */
        quantitativeAnalyses?: Maybe<QuantitativeAnalyses>;
        /** Ethical Considerations of the mlmodel */
        ethicalConsiderations?: Maybe<EthicalConsiderations>;
        /** Caveats and Recommendations of the mlmodel */
        caveatsAndRecommendations?: Maybe<CaveatsAndRecommendations>;
        /** References to internal resources related to the mlmodel */
        institutionalMemory?: Maybe<InstitutionalMemory>;
        /** Source Code */
        sourceCode?: Maybe<SourceCode>;
        /** Status metadata of the mlmodel */
        status?: Maybe<Status>;
        /** Cost Aspect of the mlmodel */
        cost?: Maybe<Cost>;
        /** Deprecation */
        deprecation?: Maybe<Deprecation>;
        /** Edges extending from this entity */
        relationships?: Maybe<EntityRelationshipsResult>;
    };

/** An ML Model Metadata Entity Note that this entity is incubating */
export type MlModelRelationshipsArgs = {
    input: RelationshipsInput;
};

export type MlModelFactorPrompts = {
    __typename?: 'MLModelFactorPrompts';
    /** What are foreseeable salient factors for which MLModel performance may vary, and how were these determined */
    relevantFactors?: Maybe<Array<MlModelFactors>>;
    /** Which factors are being reported, and why were these chosen */
    evaluationFactors?: Maybe<Array<MlModelFactors>>;
};

export type MlModelFactors = {
    __typename?: 'MLModelFactors';
    /** Distinct categories with similar characteristics that are present in the evaluation data instances */
    groups?: Maybe<Array<Scalars['String']>>;
    /** Instrumentation used for MLModel */
    instrumentation?: Maybe<Array<Scalars['String']>>;
    /** Environment in which the MLModel is deployed */
    environment?: Maybe<Array<Scalars['String']>>;
};

/**
 * An ML Model Group Metadata Entity
 * Note that this entity is incubating
 */
export type MlModelGroup = EntityWithRelationships &
    Entity & {
        __typename?: 'MLModelGroup';
        /** The primary key of the ML Model Group */
        urn: Scalars['String'];
        /** A standard Entity Type */
        type: EntityType;
        /** The display name for the Entity */
        name: Scalars['String'];
        /** Standardized platform urn where the MLModelGroup is defined */
        platform: DataPlatform;
        /** Fabric type where MLModelGroup belongs to or where it was generated */
        origin: FabricType;
        /** Human readable description for MLModelGroup */
        description?: Maybe<Scalars['String']>;
        /** Additional read only properties about the ML Model Group */
        properties?: Maybe<MlModelGroupProperties>;
        /** Ownership metadata of the MLModelGroup */
        ownership?: Maybe<Ownership>;
        /** Status metadata of the MLFeature */
        status?: Maybe<Status>;
        /** Deprecation */
        deprecation?: Maybe<Deprecation>;
        /** Edges extending from this entity */
        relationships?: Maybe<EntityRelationshipsResult>;
    };

/**
 * An ML Model Group Metadata Entity
 * Note that this entity is incubating
 */
export type MlModelGroupRelationshipsArgs = {
    input: RelationshipsInput;
};

export type MlModelGroupProperties = {
    __typename?: 'MLModelGroupProperties';
    description?: Maybe<Scalars['String']>;
    createdAt?: Maybe<Scalars['Long']>;
    version?: Maybe<VersionTag>;
};

export type MlModelProperties = {
    __typename?: 'MLModelProperties';
    description?: Maybe<Scalars['String']>;
    date?: Maybe<Scalars['Long']>;
    version?: Maybe<Scalars['String']>;
    type?: Maybe<Scalars['String']>;
    hyperParameters?: Maybe<HyperParameterMap>;
    hyperParams?: Maybe<Array<Maybe<MlHyperParam>>>;
    trainingMetrics?: Maybe<Array<Maybe<MlMetric>>>;
    mlFeatures?: Maybe<Array<Scalars['String']>>;
    tags?: Maybe<Array<Scalars['String']>>;
    groups?: Maybe<Array<Maybe<MlModelGroup>>>;
    customProperties?: Maybe<Array<StringMapEntry>>;
};

/** An ML Primary Key Entity Note that this entity is incubating */
export type MlPrimaryKey = Entity & {
    __typename?: 'MLPrimaryKey';
    /** The primary key of the ML Primary Key */
    urn: Scalars['String'];
    /** A standard Entity Type */
    type: EntityType;
    /** The display name */
    name: Scalars['String'];
    /** MLPrimaryKey featureNamespace */
    featureNamespace: Scalars['String'];
    /** MLPrimaryKey description */
    description?: Maybe<Scalars['String']>;
    /** MLPrimaryKey data type */
    dataType?: Maybe<MlFeatureDataType>;
    /** Additional read only properties of the ML Primary Key */
    properties?: Maybe<MlPrimaryKeyProperties>;
    /**
     * Deprecated, use properties field instead
     * MLPrimaryKeyProperties
     * @deprecated Field no longer supported
     */
    primaryKeyProperties?: Maybe<MlPrimaryKeyProperties>;
    /** Ownership metadata of the MLPrimaryKey */
    ownership?: Maybe<Ownership>;
    /** References to internal resources related to the MLPrimaryKey */
    institutionalMemory?: Maybe<InstitutionalMemory>;
    /** Status metadata of the MLPrimaryKey */
    status?: Maybe<Status>;
    /** Deprecation */
    deprecation?: Maybe<Deprecation>;
    /** Edges extending from this entity */
    relationships?: Maybe<EntityRelationshipsResult>;
};

/** An ML Primary Key Entity Note that this entity is incubating */
export type MlPrimaryKeyRelationshipsArgs = {
    input: RelationshipsInput;
};

export type MlPrimaryKeyProperties = {
    __typename?: 'MLPrimaryKeyProperties';
    description?: Maybe<Scalars['String']>;
    dataType?: Maybe<MlFeatureDataType>;
    version?: Maybe<VersionTag>;
    sources?: Maybe<Array<Maybe<Dataset>>>;
};

/** Configurations related to managed, UI based ingestion */
export type ManagedIngestionConfig = {
    __typename?: 'ManagedIngestionConfig';
    /** Whether ingestion screen is enabled in the UI */
    enabled: Scalars['Boolean'];
};

/** An overview of the field that was matched in the entity search document */
export type MatchedField = {
    __typename?: 'MatchedField';
    /** Name of the field that matched */
    name: Scalars['String'];
    /** Value of the field that matched */
    value: Scalars['String'];
};

export type Metrics = {
    __typename?: 'Metrics';
    /** Measures of ML Model performance */
    performanceMeasures?: Maybe<Array<Scalars['String']>>;
    /** Decision Thresholds used if any */
    decisionThreshold?: Maybe<Array<Scalars['String']>>;
};

/**
 * Root type used for updating DataHub Metadata
 * Coming soon createEntity, addOwner, removeOwner mutations
 */
export type Mutation = {
    __typename?: 'Mutation';
    /** Add members to a group */
    addGroupMembers?: Maybe<Scalars['Boolean']>;
    /** Add a link, or institutional memory, from a particular Entity */
    addLink?: Maybe<Scalars['Boolean']>;
    /** Add an owner to a particular Entity */
    addOwner?: Maybe<Scalars['Boolean']>;
    /** Add a tag to a particular Entity or subresource */
    addTag?: Maybe<Scalars['Boolean']>;
    /** Add a glossary term to a particular Entity or subresource */
    addTerm?: Maybe<Scalars['Boolean']>;
    /** Cancel a running execution request, provided the urn of the original execution request */
    cancelIngestionExecutionRequest?: Maybe<Scalars['String']>;
    /**
     * Create a new Domain. Returns the urn of the newly created Domain. Requires the Manage Domains privilege. If a domain with the provided ID already exists,
     * it will be overwritten.
     */
    createDomain?: Maybe<Scalars['String']>;
    /** Create a new group. Returns the urn of the newly created group. Requires the Manage Users & Groups Platform Privilege */
    createGroup?: Maybe<Scalars['String']>;
    /**
     * Create a request to execute an ingestion job
     * input: Input required for creating an ingestion execution request
     */
    createIngestionExecutionRequest?: Maybe<Scalars['String']>;
    /** Create a new ingestion source */
    createIngestionSource?: Maybe<Scalars['String']>;
    /** Create a policy and returns the resulting urn */
    createPolicy?: Maybe<Scalars['String']>;
    /** Create a new Secret */
    createSecret?: Maybe<Scalars['String']>;
    /** Delete an existing ingestion source */
    deleteIngestionSource?: Maybe<Scalars['String']>;
    /** Remove an existing policy and returns the policy urn */
    deletePolicy?: Maybe<Scalars['String']>;
    /** Delete a Secret */
    deleteSecret?: Maybe<Scalars['String']>;
    /** Remove a group. Requires Manage Users & Groups Platform Privilege */
    removeGroup?: Maybe<Scalars['Boolean']>;
    /** Remove members from a group */
    removeGroupMembers?: Maybe<Scalars['Boolean']>;
    /** Remove a link, or institutional memory, from a particular Entity */
    removeLink?: Maybe<Scalars['Boolean']>;
    /** Remove an owner from a particular Entity */
    removeOwner?: Maybe<Scalars['Boolean']>;
    /** Remove a tag from a particular Entity or subresource */
    removeTag?: Maybe<Scalars['Boolean']>;
    /** Remove a glossary term from a particular Entity or subresource */
    removeTerm?: Maybe<Scalars['Boolean']>;
    /** Remove a user. Requires Manage Users & Groups Platform Privilege */
    removeUser?: Maybe<Scalars['Boolean']>;
    /** Sets the Domain for a Dataset, Chart, Dashboard, Data Flow (Pipeline), or Data Job (Task). Returns true if the Domain was successfully added, or already exists. Requires the Edit Domains privilege for the Entity. */
    setDomain?: Maybe<Scalars['Boolean']>;
    /** Sets the Domain for a Dataset, Chart, Dashboard, Data Flow (Pipeline), or Data Job (Task). Returns true if the Domain was successfully removed, or was already removed. Requires the Edit Domains privilege for an asset. */
    unsetDomain?: Maybe<Scalars['Boolean']>;
    /** Update the metadata about a particular Chart */
    updateChart?: Maybe<Chart>;
    /** Update a particular Corp User's editable properties */
    updateCorpUserProperties?: Maybe<CorpUser>;
    /** Update the metadata about a particular Dashboard */
    updateDashboard?: Maybe<Dashboard>;
    /** Update the metadata about a particular Data Flow (Pipeline) */
    updateDataFlow?: Maybe<DataFlow>;
    /** Update the metadata about a particular Data Job (Task) */
    updateDataJob?: Maybe<DataJob>;
    /** Update the metadata about a particular Dataset */
    updateDataset?: Maybe<Dataset>;
    /** Incubating. Updates the description of a resource. Currently only supports Dataset Schema Fields, Containers */
    updateDescription?: Maybe<Scalars['Boolean']>;
    /** Update an existing ingestion source */
    updateIngestionSource?: Maybe<Scalars['String']>;
    /** Update an existing policy and returns the resulting urn */
    updatePolicy?: Maybe<Scalars['String']>;
    /** Update the information about a particular Entity Tag */
    updateTag?: Maybe<Tag>;
    /** Change the status of a user. Requires Manage Users & Groups Platform Privilege */
    updateUserStatus?: Maybe<Scalars['String']>;
};

/**
 * Root type used for updating DataHub Metadata
 * Coming soon createEntity, addOwner, removeOwner mutations
 */
export type MutationAddGroupMembersArgs = {
    input: AddGroupMembersInput;
};

/**
 * Root type used for updating DataHub Metadata
 * Coming soon createEntity, addOwner, removeOwner mutations
 */
export type MutationAddLinkArgs = {
    input: AddLinkInput;
};

/**
 * Root type used for updating DataHub Metadata
 * Coming soon createEntity, addOwner, removeOwner mutations
 */
export type MutationAddOwnerArgs = {
    input: AddOwnerInput;
};

/**
 * Root type used for updating DataHub Metadata
 * Coming soon createEntity, addOwner, removeOwner mutations
 */
export type MutationAddTagArgs = {
    input: TagAssociationInput;
};

/**
 * Root type used for updating DataHub Metadata
 * Coming soon createEntity, addOwner, removeOwner mutations
 */
export type MutationAddTermArgs = {
    input: TermAssociationInput;
};

/**
 * Root type used for updating DataHub Metadata
 * Coming soon createEntity, addOwner, removeOwner mutations
 */
export type MutationCancelIngestionExecutionRequestArgs = {
    input: CancelIngestionExecutionRequestInput;
};

/**
 * Root type used for updating DataHub Metadata
 * Coming soon createEntity, addOwner, removeOwner mutations
 */
export type MutationCreateDomainArgs = {
    input: CreateDomainInput;
};

/**
 * Root type used for updating DataHub Metadata
 * Coming soon createEntity, addOwner, removeOwner mutations
 */
export type MutationCreateGroupArgs = {
    input: CreateGroupInput;
};

/**
 * Root type used for updating DataHub Metadata
 * Coming soon createEntity, addOwner, removeOwner mutations
 */
export type MutationCreateIngestionExecutionRequestArgs = {
    input: CreateIngestionExecutionRequestInput;
};

/**
 * Root type used for updating DataHub Metadata
 * Coming soon createEntity, addOwner, removeOwner mutations
 */
export type MutationCreateIngestionSourceArgs = {
    input: UpdateIngestionSourceInput;
};

/**
 * Root type used for updating DataHub Metadata
 * Coming soon createEntity, addOwner, removeOwner mutations
 */
export type MutationCreatePolicyArgs = {
    input: PolicyUpdateInput;
};

/**
 * Root type used for updating DataHub Metadata
 * Coming soon createEntity, addOwner, removeOwner mutations
 */
export type MutationCreateSecretArgs = {
    input: CreateSecretInput;
};

/**
 * Root type used for updating DataHub Metadata
 * Coming soon createEntity, addOwner, removeOwner mutations
 */
export type MutationDeleteIngestionSourceArgs = {
    urn: Scalars['String'];
};

/**
 * Root type used for updating DataHub Metadata
 * Coming soon createEntity, addOwner, removeOwner mutations
 */
export type MutationDeletePolicyArgs = {
    urn: Scalars['String'];
};

/**
 * Root type used for updating DataHub Metadata
 * Coming soon createEntity, addOwner, removeOwner mutations
 */
export type MutationDeleteSecretArgs = {
    urn: Scalars['String'];
};

/**
 * Root type used for updating DataHub Metadata
 * Coming soon createEntity, addOwner, removeOwner mutations
 */
export type MutationRemoveGroupArgs = {
    urn: Scalars['String'];
};

/**
 * Root type used for updating DataHub Metadata
 * Coming soon createEntity, addOwner, removeOwner mutations
 */
export type MutationRemoveGroupMembersArgs = {
    input: RemoveGroupMembersInput;
};

/**
 * Root type used for updating DataHub Metadata
 * Coming soon createEntity, addOwner, removeOwner mutations
 */
export type MutationRemoveLinkArgs = {
    input: RemoveLinkInput;
};

/**
 * Root type used for updating DataHub Metadata
 * Coming soon createEntity, addOwner, removeOwner mutations
 */
export type MutationRemoveOwnerArgs = {
    input: RemoveOwnerInput;
};

/**
 * Root type used for updating DataHub Metadata
 * Coming soon createEntity, addOwner, removeOwner mutations
 */
export type MutationRemoveTagArgs = {
    input: TagAssociationInput;
};

/**
 * Root type used for updating DataHub Metadata
 * Coming soon createEntity, addOwner, removeOwner mutations
 */
export type MutationRemoveTermArgs = {
    input: TermAssociationInput;
};

/**
 * Root type used for updating DataHub Metadata
 * Coming soon createEntity, addOwner, removeOwner mutations
 */
export type MutationRemoveUserArgs = {
    urn: Scalars['String'];
};

/**
 * Root type used for updating DataHub Metadata
 * Coming soon createEntity, addOwner, removeOwner mutations
 */
export type MutationSetDomainArgs = {
    entityUrn: Scalars['String'];
    domainUrn: Scalars['String'];
};

/**
 * Root type used for updating DataHub Metadata
 * Coming soon createEntity, addOwner, removeOwner mutations
 */
export type MutationUnsetDomainArgs = {
    entityUrn: Scalars['String'];
};

/**
 * Root type used for updating DataHub Metadata
 * Coming soon createEntity, addOwner, removeOwner mutations
 */
export type MutationUpdateChartArgs = {
    urn: Scalars['String'];
    input: ChartUpdateInput;
};

/**
 * Root type used for updating DataHub Metadata
 * Coming soon createEntity, addOwner, removeOwner mutations
 */
export type MutationUpdateCorpUserPropertiesArgs = {
    urn: Scalars['String'];
    input: CorpUserUpdateInput;
};

/**
 * Root type used for updating DataHub Metadata
 * Coming soon createEntity, addOwner, removeOwner mutations
 */
export type MutationUpdateDashboardArgs = {
    urn: Scalars['String'];
    input: DashboardUpdateInput;
};

/**
 * Root type used for updating DataHub Metadata
 * Coming soon createEntity, addOwner, removeOwner mutations
 */
export type MutationUpdateDataFlowArgs = {
    urn: Scalars['String'];
    input: DataFlowUpdateInput;
};

/**
 * Root type used for updating DataHub Metadata
 * Coming soon createEntity, addOwner, removeOwner mutations
 */
export type MutationUpdateDataJobArgs = {
    urn: Scalars['String'];
    input: DataJobUpdateInput;
};

/**
 * Root type used for updating DataHub Metadata
 * Coming soon createEntity, addOwner, removeOwner mutations
 */
export type MutationUpdateDatasetArgs = {
    urn: Scalars['String'];
    input: DatasetUpdateInput;
};

/**
 * Root type used for updating DataHub Metadata
 * Coming soon createEntity, addOwner, removeOwner mutations
 */
export type MutationUpdateDescriptionArgs = {
    input: DescriptionUpdateInput;
};

/**
 * Root type used for updating DataHub Metadata
 * Coming soon createEntity, addOwner, removeOwner mutations
 */
export type MutationUpdateIngestionSourceArgs = {
    urn: Scalars['String'];
    input: UpdateIngestionSourceInput;
};

/**
 * Root type used for updating DataHub Metadata
 * Coming soon createEntity, addOwner, removeOwner mutations
 */
export type MutationUpdatePolicyArgs = {
    urn: Scalars['String'];
    input: PolicyUpdateInput;
};

/**
 * Root type used for updating DataHub Metadata
 * Coming soon createEntity, addOwner, removeOwner mutations
 */
export type MutationUpdateTagArgs = {
    urn: Scalars['String'];
    input: TagUpdateInput;
};

/**
 * Root type used for updating DataHub Metadata
 * Coming soon createEntity, addOwner, removeOwner mutations
 */
export type MutationUpdateUserStatusArgs = {
    urn: Scalars['String'];
    status: CorpUserStatus;
};

/** For consumption by UI only */
export type NamedBar = {
    __typename?: 'NamedBar';
    name: Scalars['String'];
    segments: Array<BarSegment>;
};

/** For consumption by UI only */
export type NamedLine = {
    __typename?: 'NamedLine';
    name: Scalars['String'];
    data: Array<NumericDataPoint>;
};

/** For consumption by UI only */
export type NumericDataPoint = {
    __typename?: 'NumericDataPoint';
    x: Scalars['String'];
    y: Scalars['Int'];
};

/** Operational info for an entity. */
export type Operation = TimeSeriesAspect & {
    __typename?: 'Operation';
    /** The time at which the profile was reported */
    timestampMillis: Scalars['Long'];
    /** When the entity was last updated. */
    lastUpdatedTimestamp: Scalars['Long'];
    /** Actor who issued this operation. */
    actor?: Maybe<Scalars['String']>;
    /** Operation type of change. */
    operationType: OperationType;
    /** How many rows were affected by this operation. */
    numAffectedRows?: Maybe<Scalars['Long']>;
    /** Which other datasets were affected by this operation. */
    affectedDatasets?: Maybe<Array<Scalars['String']>>;
};

/** Enum to define the operation type when an entity changes. */
export enum OperationType {
    /** When data is inserted. */
    Insert = 'INSERT',
    /** When data is updated. */
    Update = 'UPDATE',
    /** When data is deleted. */
    Delete = 'DELETE',
    /** When data is created. */
    Create = 'CREATE',
}

/** An owner of a Metadata Entity */
export type Owner = {
    __typename?: 'Owner';
    /** Owner object */
    owner: OwnerType;
    /** The type of the ownership */
    type: OwnershipType;
    /** Source information for the ownership */
    source?: Maybe<OwnershipSource>;
};

/** Entities that are able to own other entities */
export enum OwnerEntityType {
    /** A corp user owner */
    CorpUser = 'CORP_USER',
    /** A corp group owner */
    CorpGroup = 'CORP_GROUP',
}

/** An owner of a Metadata Entity, either a user or group */
export type OwnerType = CorpUser | CorpGroup;

/**
 * An owner to add to a Metadata Entity
 * TODO Add a USER or GROUP actor enum
 */
export type OwnerUpdate = {
    /** The owner URN, either a corpGroup or corpuser */
    owner: Scalars['String'];
    /** The owner type */
    type: OwnershipType;
};

/** Ownership information about a Metadata Entity */
export type Ownership = {
    __typename?: 'Ownership';
    /** List of owners of the entity */
    owners?: Maybe<Array<Owner>>;
    /** Audit stamp containing who last modified the record and when */
    lastModified: AuditStamp;
};

/** Information about the source of Ownership metadata about a Metadata Entity */
export type OwnershipSource = {
    __typename?: 'OwnershipSource';
    /** The type of the source */
    type: OwnershipSourceType;
    /** An optional reference URL for the source */
    url?: Maybe<Scalars['String']>;
};

/** The origin of Ownership metadata associated with a Metadata Entity */
export enum OwnershipSourceType {
    /** Auditing system or audit logs */
    Audit = 'AUDIT',
    /** Database, eg GRANTS table */
    Database = 'DATABASE',
    /** File system, eg file or directory owner */
    FileSystem = 'FILE_SYSTEM',
    /** Issue tracking system, eg Jira */
    IssueTrackingSystem = 'ISSUE_TRACKING_SYSTEM',
    /** Manually provided by a user */
    Manual = 'MANUAL',
    /** Other ownership like service, eg Nuage, ACL service etc */
    Service = 'SERVICE',
    /** SCM system, eg GIT, SVN */
    SourceControl = 'SOURCE_CONTROL',
    /** Other sources */
    Other = 'OTHER',
}

/**
 * The type of the ownership relationship between a Person and a Metadata Entity
 * Note that this field will soon become deprecated due to low usage
 */
export enum OwnershipType {
    /** A person or group that is in charge of developing the code */
    Developer = 'DEVELOPER',
    /** A person or group that is owning the data */
    Dataowner = 'DATAOWNER',
    /** A person or a group that overseas the operation, eg a DBA or SRE */
    Delegate = 'DELEGATE',
    /** A person, group, or service that produces or generates the data */
    Producer = 'PRODUCER',
    /** A person, group, or service that consumes the data */
    Consumer = 'CONSUMER',
    /** A person or a group that has direct business interest */
    Stakeholder = 'STAKEHOLDER',
}

/** An update for the ownership information for a Metadata Entity */
export type OwnershipUpdate = {
    /** The updated list of owners */
    owners: Array<OwnerUpdate>;
};

/** Information about the partition being profiled */
export type PartitionSpec = {
    __typename?: 'PartitionSpec';
    /** The partition identifier */
    partition: Scalars['String'];
    /** The optional time window partition information */
    timePartition?: Maybe<TimeWindow>;
};

/**
 * Deprecated, do not use this type
 * The logical type associated with an individual Dataset
 */
export enum PlatformNativeType {
    /** Table */
    Table = 'TABLE',
    /** View */
    View = 'VIEW',
    /** Directory in file system */
    Directory = 'DIRECTORY',
    /** Stream */
    Stream = 'STREAM',
    /** Bucket in key value store */
    Bucket = 'BUCKET',
}

/** The platform privileges that the currently authenticated user has */
export type PlatformPrivileges = {
    __typename?: 'PlatformPrivileges';
    /** Whether the user should be able to view analytics */
    viewAnalytics: Scalars['Boolean'];
    /** Whether the user should be able to manage policies */
    managePolicies: Scalars['Boolean'];
    /** Whether the user should be able to manage users & groups */
    manageIdentities: Scalars['Boolean'];
    /** Whether the user should be able to generate personal access tokens */
    generatePersonalAccessTokens: Scalars['Boolean'];
    /** Whether the user should be able to manage Domains */
    manageDomains: Scalars['Boolean'];
    /** Whether the user is able to manage UI-based ingestion */
    manageIngestion: Scalars['Boolean'];
    /** Whether the user is able to manage UI-based secrets */
    manageSecrets: Scalars['Boolean'];
};

/** A type of Schema, either a table schema or a key value schema */
export type PlatformSchema = TableSchema | KeyValueSchema;

/** The category of a specific Data Platform */
export enum PlatformType {
    /** Value for a file system */
    FileSystem = 'FILE_SYSTEM',
    /** Value for a key value store */
    KeyValueStore = 'KEY_VALUE_STORE',
    /** Value for a message broker */
    MessageBroker = 'MESSAGE_BROKER',
    /** Value for an object store */
    ObjectStore = 'OBJECT_STORE',
    /** Value for an OLAP datastore */
    OlapDatastore = 'OLAP_DATASTORE',
    /** Value for a query engine */
    QueryEngine = 'QUERY_ENGINE',
    /** Value for a relational database */
    RelationalDb = 'RELATIONAL_DB',
    /** Value for a search engine */
    SearchEngine = 'SEARCH_ENGINE',
    /** Value for other platforms */
    Others = 'OTHERS',
}

/** Configurations related to the Policies Feature */
export type PoliciesConfig = {
    __typename?: 'PoliciesConfig';
    /** Whether the policies feature is enabled and should be displayed in the UI */
    enabled: Scalars['Boolean'];
    /** A list of platform privileges to display in the Policy Builder experience */
    platformPrivileges: Array<Privilege>;
    /** A list of resource privileges to display in the Policy Builder experience */
    resourcePrivileges: Array<ResourcePrivileges>;
};

/** An DataHub Platform Access Policy Access Policies determine who can perform what actions against which resources on the platform */
export type Policy = {
    __typename?: 'Policy';
    /** The primary key of the Policy */
    urn: Scalars['String'];
    /** The type of the Policy */
    type: PolicyType;
    /** The name of the Policy */
    name: Scalars['String'];
    /** The present state of the Policy */
    state: PolicyState;
    /** The description of the Policy */
    description?: Maybe<Scalars['String']>;
    /** The resources that the Policy privileges apply to */
    resources?: Maybe<ResourceFilter>;
    /** The privileges that the Policy grants */
    privileges: Array<Scalars['String']>;
    /** The actors that the Policy grants privileges to */
    actors: ActorFilter;
    /** Whether the Policy is editable, ie system policies, or not */
    editable: Scalars['Boolean'];
};

/** The state of an Access Policy */
export enum PolicyState {
    /**
     * A Policy that has not been officially created, but in progress
     * Currently unused
     */
    Draft = 'DRAFT',
    /** A Policy that is active and being enforced */
    Active = 'ACTIVE',
    /** A Policy that is not active or being enforced */
    Inactive = 'INACTIVE',
}

/** The type of the Access Policy */
export enum PolicyType {
    /** An access policy that grants privileges pertaining to Metadata Entities */
    Metadata = 'METADATA',
    /** An access policy that grants top level administrative privileges pertaining to the DataHub Platform itself */
    Platform = 'PLATFORM',
}

/** Input provided when creating or updating an Access Policy */
export type PolicyUpdateInput = {
    /** The Policy Type */
    type: PolicyType;
    /** The Policy name */
    name: Scalars['String'];
    /** The Policy state */
    state: PolicyState;
    /** A Policy description */
    description?: Maybe<Scalars['String']>;
    /** The set of resources that the Policy privileges apply to */
    resources?: Maybe<ResourceFilterInput>;
    /** The set of privileges that the Policy grants */
    privileges: Array<Scalars['String']>;
    /** The set of actors that the Policy privileges are granted to */
    actors: ActorFilterInput;
};

/** An individual DataHub Access Privilege */
export type Privilege = {
    __typename?: 'Privilege';
    /** Standardized privilege type, serving as a unique identifier for a privilege eg EDIT_ENTITY */
    type: Scalars['String'];
    /** The name to appear when displaying the privilege, eg Edit Entity */
    displayName?: Maybe<Scalars['String']>;
    /** A description of the privilege to display */
    description?: Maybe<Scalars['String']>;
};

export type QuantitativeAnalyses = {
    __typename?: 'QuantitativeAnalyses';
    /** Link to a dashboard with results showing how the model performed with respect to each factor */
    unitaryResults?: Maybe<ResultsType>;
    /** Link to a dashboard with results showing how the model performed with respect to the intersection of evaluated factors */
    intersectionalResults?: Maybe<ResultsType>;
};

/**
 * Root type used for fetching DataHub Metadata
 * Coming soon listEntity queries for listing all entities of a given type
 */
export type Query = {
    __typename?: 'Query';
    /**
     * Fetch configurations
     * Used by DataHub UI
     */
    appConfig?: Maybe<AppConfig>;
    /** Autocomplete a search query against a specific DataHub Entity Type */
    autoComplete?: Maybe<AutoCompleteResults>;
    /** Autocomplete a search query against a specific set of DataHub Entity Types */
    autoCompleteForMultiple?: Maybe<AutoCompleteMultipleResults>;
    /**
     * Hierarchically browse a specific type of DataHub Entity by path
     * Used by explore in the UI
     */
    browse?: Maybe<BrowseResults>;
    /** Retrieve the browse paths corresponding to an entity */
    browsePaths?: Maybe<Array<BrowsePath>>;
    /** Fetch a Chart by primary key (urn) */
    chart?: Maybe<Chart>;
    /** Fetch an Entity Container by primary key (urn) */
    container?: Maybe<Container>;
    /** Fetch a CorpGroup, representing a DataHub platform group by primary key (urn) */
    corpGroup?: Maybe<CorpGroup>;
    /** Fetch a CorpUser, representing a DataHub platform user, by primary key (urn) */
    corpUser?: Maybe<CorpUser>;
    /** Fetch a Dashboard by primary key (urn) */
    dashboard?: Maybe<Dashboard>;
    /** Fetch a Data Flow (or Data Pipeline) by primary key (urn) */
    dataFlow?: Maybe<DataFlow>;
    /** Fetch a Data Job (or Data Task) by primary key (urn) */
    dataJob?: Maybe<DataJob>;
    /** Fetch a Dataset by primary key (urn) */
    dataset?: Maybe<Dataset>;
    /** Fetch a Domain by primary key (urn) */
    domain?: Maybe<Domain>;
    /**
     * Create a request to execute an ingestion job
     * urn: The primary key associated with the ingestion execution request.
     */
    executionRequest?: Maybe<ExecutionRequest>;
    /** Generates an access token for DataHub APIs for a particular user & of a particular type */
    getAccessToken?: Maybe<AccessToken>;
    /** Retrieves a set of server driven Analytics Charts to render in the UI */
    getAnalyticsCharts: Array<AnalyticsChartGroup>;
    /** Fetches the number of entities ingested by type */
    getEntityCounts?: Maybe<EntityCountResults>;
    /** Retrieves a set of server driven Analytics Highlight Cards to render in the UI */
    getHighlights: Array<Highlight>;
    /**
     * Fetch the values of a set of secrets. The caller must have the MANAGE_SECRETS
     * privilege to use.
     */
    getSecretValues?: Maybe<Array<SecretValue>>;
    /** Fetch a Glossary Term by primary key (urn) */
    glossaryTerm?: Maybe<GlossaryTerm>;
    /**
     * Fetch a specific ingestion source
     * urn: The primary key associated with the ingestion execution request.
     */
    ingestionSource?: Maybe<IngestionSource>;
    /**
     * Deprecated, use appConfig Query instead
     * Whether the analytics feature is enabled in the UI
     * @deprecated Field no longer supported
     */
    isAnalyticsEnabled: Scalars['Boolean'];
    /** List all DataHub Domains */
    listDomains?: Maybe<ListDomainsResult>;
    /** List all DataHub Groups */
    listGroups?: Maybe<ListGroupsResult>;
    /** List all ingestion sources */
    listIngestionSources?: Maybe<ListIngestionSourcesResult>;
    /** List all DataHub Access Policies */
    listPolicies?: Maybe<ListPoliciesResult>;
    /** Fetch recommendations for a particular scenario */
    listRecommendations?: Maybe<ListRecommendationsResult>;
    /** List all secrets stored in DataHub (no values) */
    listSecrets?: Maybe<ListSecretsResult>;
    /** List all DataHub Users */
    listUsers?: Maybe<ListUsersResult>;
    /** Fetch details associated with the authenticated user, provided via an auth cookie or header */
    me?: Maybe<AuthenticatedUser>;
    /** Incubating: Fetch a ML Feature by primary key (urn) */
    mlFeature?: Maybe<MlFeature>;
    /** Incubating: Fetch a ML Feature Table by primary key (urn) */
    mlFeatureTable?: Maybe<MlFeatureTable>;
    /** Incubating: Fetch an ML Model by primary key (urn) */
    mlModel?: Maybe<MlModel>;
    /** Incubating: Fetch an ML Model Group by primary key (urn) */
    mlModelGroup?: Maybe<MlModelGroup>;
    /** Incubating: Fetch a ML Primary Key by primary key (urn) */
    mlPrimaryKey?: Maybe<MlPrimaryKey>;
    /** Full text search against a specific DataHub Entity Type */
    search?: Maybe<SearchResults>;
    /** Search DataHub entities */
    searchAcrossEntities?: Maybe<SearchResults>;
    /** Fetch a Tag by primary key (urn) */
    tag?: Maybe<Tag>;
};

/**
 * Root type used for fetching DataHub Metadata
 * Coming soon listEntity queries for listing all entities of a given type
 */
export type QueryAutoCompleteArgs = {
    input: AutoCompleteInput;
};

/**
 * Root type used for fetching DataHub Metadata
 * Coming soon listEntity queries for listing all entities of a given type
 */
export type QueryAutoCompleteForMultipleArgs = {
    input: AutoCompleteMultipleInput;
};

/**
 * Root type used for fetching DataHub Metadata
 * Coming soon listEntity queries for listing all entities of a given type
 */
export type QueryBrowseArgs = {
    input: BrowseInput;
};

/**
 * Root type used for fetching DataHub Metadata
 * Coming soon listEntity queries for listing all entities of a given type
 */
export type QueryBrowsePathsArgs = {
    input: BrowsePathsInput;
};

/**
 * Root type used for fetching DataHub Metadata
 * Coming soon listEntity queries for listing all entities of a given type
 */
export type QueryChartArgs = {
    urn: Scalars['String'];
};

/**
 * Root type used for fetching DataHub Metadata
 * Coming soon listEntity queries for listing all entities of a given type
 */
export type QueryContainerArgs = {
    urn: Scalars['String'];
};

/**
 * Root type used for fetching DataHub Metadata
 * Coming soon listEntity queries for listing all entities of a given type
 */
export type QueryCorpGroupArgs = {
    urn: Scalars['String'];
};

/**
 * Root type used for fetching DataHub Metadata
 * Coming soon listEntity queries for listing all entities of a given type
 */
export type QueryCorpUserArgs = {
    urn: Scalars['String'];
};

/**
 * Root type used for fetching DataHub Metadata
 * Coming soon listEntity queries for listing all entities of a given type
 */
export type QueryDashboardArgs = {
    urn: Scalars['String'];
};

/**
 * Root type used for fetching DataHub Metadata
 * Coming soon listEntity queries for listing all entities of a given type
 */
export type QueryDataFlowArgs = {
    urn: Scalars['String'];
};

/**
 * Root type used for fetching DataHub Metadata
 * Coming soon listEntity queries for listing all entities of a given type
 */
export type QueryDataJobArgs = {
    urn: Scalars['String'];
};

/**
 * Root type used for fetching DataHub Metadata
 * Coming soon listEntity queries for listing all entities of a given type
 */
export type QueryDatasetArgs = {
    urn: Scalars['String'];
};

/**
 * Root type used for fetching DataHub Metadata
 * Coming soon listEntity queries for listing all entities of a given type
 */
export type QueryDomainArgs = {
    urn: Scalars['String'];
};

/**
 * Root type used for fetching DataHub Metadata
 * Coming soon listEntity queries for listing all entities of a given type
 */
export type QueryExecutionRequestArgs = {
    urn: Scalars['String'];
};

/**
 * Root type used for fetching DataHub Metadata
 * Coming soon listEntity queries for listing all entities of a given type
 */
export type QueryGetAccessTokenArgs = {
    input: GetAccessTokenInput;
};

/**
 * Root type used for fetching DataHub Metadata
 * Coming soon listEntity queries for listing all entities of a given type
 */
export type QueryGetEntityCountsArgs = {
    input?: Maybe<EntityCountInput>;
};

/**
 * Root type used for fetching DataHub Metadata
 * Coming soon listEntity queries for listing all entities of a given type
 */
export type QueryGetSecretValuesArgs = {
    input: GetSecretValuesInput;
};

/**
 * Root type used for fetching DataHub Metadata
 * Coming soon listEntity queries for listing all entities of a given type
 */
export type QueryGlossaryTermArgs = {
    urn: Scalars['String'];
};

/**
 * Root type used for fetching DataHub Metadata
 * Coming soon listEntity queries for listing all entities of a given type
 */
export type QueryIngestionSourceArgs = {
    urn: Scalars['String'];
};

/**
 * Root type used for fetching DataHub Metadata
 * Coming soon listEntity queries for listing all entities of a given type
 */
export type QueryListDomainsArgs = {
    input: ListDomainsInput;
};

/**
 * Root type used for fetching DataHub Metadata
 * Coming soon listEntity queries for listing all entities of a given type
 */
export type QueryListGroupsArgs = {
    input: ListGroupsInput;
};

/**
 * Root type used for fetching DataHub Metadata
 * Coming soon listEntity queries for listing all entities of a given type
 */
export type QueryListIngestionSourcesArgs = {
    input: ListIngestionSourcesInput;
};

/**
 * Root type used for fetching DataHub Metadata
 * Coming soon listEntity queries for listing all entities of a given type
 */
export type QueryListPoliciesArgs = {
    input: ListPoliciesInput;
};

/**
 * Root type used for fetching DataHub Metadata
 * Coming soon listEntity queries for listing all entities of a given type
 */
export type QueryListRecommendationsArgs = {
    input: ListRecommendationsInput;
};

/**
 * Root type used for fetching DataHub Metadata
 * Coming soon listEntity queries for listing all entities of a given type
 */
export type QueryListSecretsArgs = {
    input: ListSecretsInput;
};

/**
 * Root type used for fetching DataHub Metadata
 * Coming soon listEntity queries for listing all entities of a given type
 */
export type QueryListUsersArgs = {
    input: ListUsersInput;
};

/**
 * Root type used for fetching DataHub Metadata
 * Coming soon listEntity queries for listing all entities of a given type
 */
export type QueryMlFeatureArgs = {
    urn: Scalars['String'];
};

/**
 * Root type used for fetching DataHub Metadata
 * Coming soon listEntity queries for listing all entities of a given type
 */
export type QueryMlFeatureTableArgs = {
    urn: Scalars['String'];
};

/**
 * Root type used for fetching DataHub Metadata
 * Coming soon listEntity queries for listing all entities of a given type
 */
export type QueryMlModelArgs = {
    urn: Scalars['String'];
};

/**
 * Root type used for fetching DataHub Metadata
 * Coming soon listEntity queries for listing all entities of a given type
 */
export type QueryMlModelGroupArgs = {
    urn: Scalars['String'];
};

/**
 * Root type used for fetching DataHub Metadata
 * Coming soon listEntity queries for listing all entities of a given type
 */
export type QueryMlPrimaryKeyArgs = {
    urn: Scalars['String'];
};

/**
 * Root type used for fetching DataHub Metadata
 * Coming soon listEntity queries for listing all entities of a given type
 */
export type QuerySearchArgs = {
    input: SearchInput;
};

/**
 * Root type used for fetching DataHub Metadata
 * Coming soon listEntity queries for listing all entities of a given type
 */
export type QuerySearchAcrossEntitiesArgs = {
    input: SearchAcrossEntitiesInput;
};

/**
 * Root type used for fetching DataHub Metadata
 * Coming soon listEntity queries for listing all entities of a given type
 */
export type QueryTagArgs = {
    urn: Scalars['String'];
};

/** Payload representing data about a single aspect */
export type RawAspect = {
    __typename?: 'RawAspect';
    /** The name of the aspect */
    aspectName: Scalars['String'];
    /** JSON string containing the aspect's payload */
    payload?: Maybe<Scalars['String']>;
    /** Details for the frontend on how the raw aspect should be rendered */
    renderSpec?: Maybe<AspectRenderSpec>;
};

/** Content to display within each recommendation module */
export type RecommendationContent = {
    __typename?: 'RecommendationContent';
    /** String representation of content */
    value: Scalars['String'];
    /** Entity being recommended. Empty if the content being recommended is not an entity */
    entity?: Maybe<Entity>;
    /** Additional context required to generate the the recommendation */
    params?: Maybe<RecommendationParams>;
};

export type RecommendationModule = {
    __typename?: 'RecommendationModule';
    /** Title of the module to display */
    title: Scalars['String'];
    /** Unique id of the module being recommended */
    moduleId: Scalars['String'];
    /** Type of rendering that defines how the module should be rendered */
    renderType: RecommendationRenderType;
    /** List of content to display inside the module */
    content: Array<RecommendationContent>;
};

/** Parameters required to render a recommendation of a given type */
export type RecommendationParams = {
    __typename?: 'RecommendationParams';
    /** Context to define the search recommendations */
    searchParams?: Maybe<SearchParams>;
    /** Context to define the entity profile page */
    entityProfileParams?: Maybe<EntityProfileParams>;
    /** Context about the recommendation */
    contentParams?: Maybe<ContentParams>;
};

/**
 * Enum that defines how the modules should be rendered.
 * There should be two frontend implementation of large and small modules per type.
 */
export enum RecommendationRenderType {
    /** Simple list of entities */
    EntityNameList = 'ENTITY_NAME_LIST',
    /** List of platforms */
    PlatformSearchList = 'PLATFORM_SEARCH_LIST',
    /** Tag search list */
    TagSearchList = 'TAG_SEARCH_LIST',
    /** A list of recommended search queries */
    SearchQueryList = 'SEARCH_QUERY_LIST',
    /** Glossary Term search list */
    GlossaryTermSearchList = 'GLOSSARY_TERM_SEARCH_LIST',
    /** Domain Search List */
    DomainSearchList = 'DOMAIN_SEARCH_LIST',
}

/**
 * Context that defines the page requesting recommendations
 * i.e. for search pages, the query/filters. for entity pages, the entity urn and tab
 */
export type RecommendationRequestContext = {
    /** Scenario in which the recommendations will be displayed */
    scenario: ScenarioType;
    /** Additional context for defining the search page requesting recommendations */
    searchRequestContext?: Maybe<SearchRequestContext>;
    /** Additional context for defining the entity page requesting recommendations */
    entityRequestContext?: Maybe<EntityRequestContext>;
};

/** Direction between a source and destination node */
export enum RelationshipDirection {
    /** A directed edge pointing at the source Entity */
    Incoming = 'INCOMING',
    /** A directed edge pointing at the destination Entity */
    Outgoing = 'OUTGOING',
}

/** Input for the list relationships field of an Entity */
export type RelationshipsInput = {
    /** The types of relationships to query, representing an OR */
    types: Array<Scalars['String']>;
    /** The direction of the relationship, either incoming or outgoing from the source entity */
    direction: RelationshipDirection;
    /** The starting offset of the result set */
    start?: Maybe<Scalars['Int']>;
    /** The number of results to be returned */
    count?: Maybe<Scalars['Int']>;
};

/** Input required to remove members from a DataHub group */
export type RemoveGroupMembersInput = {
    /** The group to remove members from */
    groupUrn: Scalars['String'];
    /** The members to remove from the group */
    userUrns: Array<Scalars['String']>;
};

/** Input provided when removing the association between a Metadata Entity and a Link */
export type RemoveLinkInput = {
    /** The url of the link to add or remove, which uniquely identifies the Link */
    linkUrl: Scalars['String'];
    /** The urn of the resource or entity to attach the link to, for example a dataset urn */
    resourceUrn: Scalars['String'];
};

/** Input provided when removing the association between a Metadata Entity and an user or group owner */
export type RemoveOwnerInput = {
    /** The primary key of the Owner to add or remove */
    ownerUrn: Scalars['String'];
    /** The urn of the resource or entity to attach or remove the owner from, for example a dataset urn */
    resourceUrn: Scalars['String'];
};

/** The resources that a DataHub Access Policy applies to */
export type ResourceFilter = {
    __typename?: 'ResourceFilter';
    /** The type of the resource the policy should apply to Not required because in the future we want to support filtering by type OR by domain */
    type: Scalars['String'];
    /** A list of specific resource urns to apply the filter to */
    resources?: Maybe<Array<Scalars['String']>>;
    /** Whether of not to apply the filter to all resources of the type */
    allResources: Scalars['Boolean'];
};

/** Input required when creating or updating an Access Policies Determines which resources the Policy applies to */
export type ResourceFilterInput = {
    /**
     * The type of the resource the policy should apply to
     * Not required because in the future we want to support filtering by type OR by domain
     */
    type: Scalars['String'];
    /** A list of specific resource urns to apply the filter to */
    resources?: Maybe<Array<Scalars['String']>>;
    /** Whether of not to apply the filter to all resources of the type */
    allResources: Scalars['Boolean'];
};

/**
 * A privilege associated with a particular resource type
 * A resource is most commonly a DataHub Metadata Entity
 */
export type ResourcePrivileges = {
    __typename?: 'ResourcePrivileges';
    /** Resource type associated with the Access Privilege, eg dataset */
    resourceType: Scalars['String'];
    /** The name to used for displaying the resourceType */
    resourceTypeDisplayName?: Maybe<Scalars['String']>;
    /** An optional entity type to use when performing search and navigation to the entity */
    entityType?: Maybe<EntityType>;
    /** A list of privileges that are supported against this resource */
    privileges: Array<Privilege>;
};

export type ResultsType = StringBox;

/** For consumption by UI only */
export type Row = {
    __typename?: 'Row';
    values: Array<Scalars['String']>;
};

/** Type of the scenario requesting recommendation */
export enum ScenarioType {
    /** Recommendations to show on the users home page */
    Home = 'HOME',
    /** Recommendations to show on the search results page */
    SearchResults = 'SEARCH_RESULTS',
    /** Recommendations to show on an Entity Profile page */
    EntityProfile = 'ENTITY_PROFILE',
}

/**
 * Deprecated, use SchemaMetadata instead
 * Metadata about a Dataset schema
 */
export type Schema = {
    __typename?: 'Schema';
    /** Dataset this schema metadata is associated with */
    datasetUrn?: Maybe<Scalars['String']>;
    /** Schema name */
    name: Scalars['String'];
    /** Platform this schema metadata is associated with */
    platformUrn: Scalars['String'];
    /** The version of the GMS Schema metadata */
    version: Scalars['Long'];
    /** The cluster this schema metadata is derived from */
    cluster?: Maybe<Scalars['String']>;
    /** The SHA1 hash of the schema content */
    hash: Scalars['String'];
    /** The native schema in the datasets platform, schemaless if it was not provided */
    platformSchema?: Maybe<PlatformSchema>;
    /** Client provided a list of fields from value schema */
    fields: Array<SchemaField>;
    /** Client provided list of fields that define primary keys to access record */
    primaryKeys?: Maybe<Array<Scalars['String']>>;
};

/** Information about an individual field in a Dataset schema */
export type SchemaField = {
    __typename?: 'SchemaField';
    /** Flattened name of the field computed from jsonPath field */
    fieldPath: Scalars['String'];
    /** Flattened name of a field in JSON Path notation */
    jsonPath?: Maybe<Scalars['String']>;
    /** Indicates if this field is optional or nullable */
    nullable: Scalars['Boolean'];
    /** Description of the field */
    description?: Maybe<Scalars['String']>;
    /** Platform independent field type of the field */
    type: SchemaFieldDataType;
    /** The native type of the field in the datasets platform as declared by platform schema */
    nativeDataType?: Maybe<Scalars['String']>;
    /** Whether the field references its own type recursively */
    recursive: Scalars['Boolean'];
    /**
     * Deprecated, use tags field instead
     * Tags associated with the field
     * @deprecated Field no longer supported
     */
    globalTags?: Maybe<GlobalTags>;
    /** Tags associated with the field */
    tags?: Maybe<GlobalTags>;
    /** Glossary terms associated with the field */
    glossaryTerms?: Maybe<GlossaryTerms>;
    /** Whether the field is part of a key schema */
    isPartOfKey?: Maybe<Scalars['Boolean']>;
};

/** The type associated with a single Dataset schema field */
export enum SchemaFieldDataType {
    /** A boolean type */
    Boolean = 'BOOLEAN',
    /** A fixed bytestring type */
    Fixed = 'FIXED',
    /** A string type */
    String = 'STRING',
    /** A string of bytes */
    Bytes = 'BYTES',
    /** A number, including integers, floats, and doubles */
    Number = 'NUMBER',
    /** A datestrings type */
    Date = 'DATE',
    /** A timestamp type */
    Time = 'TIME',
    /** An enum type */
    Enum = 'ENUM',
    /** A NULL type */
    Null = 'NULL',
    /** A map collection type */
    Map = 'MAP',
    /** An array collection type */
    Array = 'ARRAY',
    /** An union type */
    Union = 'UNION',
    /** An complex struct type */
    Struct = 'STRUCT',
}

/**
 * Standalone schema field entity. Differs from the SchemaField struct because it is not directly nested inside a
 * schema field
 */
export type SchemaFieldEntity = {
    __typename?: 'SchemaFieldEntity';
    /** Primary key of the schema field */
    urn: Scalars['String'];
    /** Field path identifying the field in its dataset */
    fieldPath: Scalars['String'];
    /** The primary key of the field's parent. */
    parent: Scalars['String'];
};

/** Metadata about a Dataset schema */
export type SchemaMetadata = Aspect & {
    __typename?: 'SchemaMetadata';
    /**
     * The logical version of the schema metadata, where zero represents the latest version
     * with otherwise monotonic ordering starting at one
     */
    aspectVersion?: Maybe<Scalars['Long']>;
    /** Dataset this schema metadata is associated with */
    datasetUrn?: Maybe<Scalars['String']>;
    /** Schema name */
    name: Scalars['String'];
    /** Platform this schema metadata is associated with */
    platformUrn: Scalars['String'];
    /** The version of the GMS Schema metadata */
    version: Scalars['Long'];
    /** The cluster this schema metadata is derived from */
    cluster?: Maybe<Scalars['String']>;
    /** The SHA1 hash of the schema content */
    hash: Scalars['String'];
    /** The native schema in the datasets platform, schemaless if it was not provided */
    platformSchema?: Maybe<PlatformSchema>;
    /** Client provided a list of fields from value schema */
    fields: Array<SchemaField>;
    /** Client provided list of fields that define primary keys to access record */
    primaryKeys?: Maybe<Array<Scalars['String']>>;
    /** Client provided list of foreign key constraints */
    foreignKeys?: Maybe<Array<Maybe<ForeignKeyConstraint>>>;
    /** The time at which the schema metadata information was created */
    createdAt?: Maybe<Scalars['Long']>;
};

/** Input arguments for a full text search query across entities */
export type SearchAcrossEntitiesInput = {
    /** Entity types to be searched. If this is not provided, all entities will be searched. */
    types?: Maybe<Array<EntityType>>;
    /** The query string */
    query: Scalars['String'];
    /** The starting point of paginated results */
    start?: Maybe<Scalars['Int']>;
    /** The number of elements included in the results */
    count?: Maybe<Scalars['Int']>;
    /** Faceted filters applied to search results */
    filters?: Maybe<Array<FacetFilterInput>>;
};

/** Input arguments for a full text search query */
export type SearchInput = {
    /** The Metadata Entity type to be searched against */
    type: EntityType;
    /** The raw query string */
    query: Scalars['String'];
    /** The offset of the result set */
    start?: Maybe<Scalars['Int']>;
    /** The number of entities to include in result set */
    count?: Maybe<Scalars['Int']>;
    /** Facet filters to apply to search results */
    filters?: Maybe<Array<FacetFilterInput>>;
};

/** Insights about why a search result was returned or ranked in the way that it was */
export type SearchInsight = {
    __typename?: 'SearchInsight';
    /** The insight to display */
    text: Scalars['String'];
    /** An optional emoji to display in front of the text */
    icon?: Maybe<Scalars['String']>;
};

/** Context to define the search recommendations */
export type SearchParams = {
    __typename?: 'SearchParams';
    /** Entity types to be searched. If this is not provided, all entities will be searched. */
    types?: Maybe<Array<EntityType>>;
    /** Search query */
    query: Scalars['String'];
    /** Filters */
    filters?: Maybe<Array<Filter>>;
};

/** Context that defines a search page requesting recommendatinos */
export type SearchRequestContext = {
    /** Search query */
    query: Scalars['String'];
    /** Faceted filters applied to search results */
    filters?: Maybe<Array<FacetFilterInput>>;
};

/** An individual search result hit */
export type SearchResult = {
    __typename?: 'SearchResult';
    /** The resolved DataHub Metadata Entity matching the search query */
    entity: Entity;
    /** Insights about why the search result was matched */
    insights?: Maybe<Array<SearchInsight>>;
    /** Matched field hint */
    matchedFields: Array<MatchedField>;
};

/** Results returned by issuing a search query */
export type SearchResults = {
    __typename?: 'SearchResults';
    /** The offset of the result set */
    start: Scalars['Int'];
    /** The number of entities included in the result set */
    count: Scalars['Int'];
    /** The total number of search results matching the query and filters */
    total: Scalars['Int'];
    /** The search result entities */
    searchResults: Array<SearchResult>;
    /** Candidate facet aggregations used for search filtering */
    facets?: Maybe<Array<FacetMetadata>>;
};

/** A referencible secret stored in DataHub's system. Notice that we do not return the actual secret value. */
export type Secret = {
    __typename?: 'Secret';
    /** The urn of the secret */
    urn: Scalars['String'];
    /** The name of the secret */
    name: Scalars['String'];
    /** An optional description for the secret */
    description?: Maybe<Scalars['String']>;
};

/** A plaintext secret value */
export type SecretValue = {
    __typename?: 'SecretValue';
    /** The name of the secret */
    name: Scalars['String'];
    /** The plaintext value of the secret. */
    value: Scalars['String'];
};

export type SourceCode = {
    __typename?: 'SourceCode';
    /** Source Code along with types */
    sourceCode?: Maybe<Array<SourceCodeUrl>>;
};

export type SourceCodeUrl = {
    __typename?: 'SourceCodeUrl';
    /** Source Code Url Types */
    type: SourceCodeUrlType;
    /** Source Code Url */
    sourceCodeUrl: Scalars['String'];
};

export enum SourceCodeUrlType {
    /** MLModel Source Code */
    MlModelSourceCode = 'ML_MODEL_SOURCE_CODE',
    /** Training Pipeline Source Code */
    TrainingPipelineSourceCode = 'TRAINING_PIPELINE_SOURCE_CODE',
    /** Evaluation Pipeline Source Code */
    EvaluationPipelineSourceCode = 'EVALUATION_PIPELINE_SOURCE_CODE',
}

/** The status of a particular Metadata Entity */
export type Status = {
    __typename?: 'Status';
    /** Whether the entity is removed or not */
    removed: Scalars['Boolean'];
};

export type StringBox = {
    __typename?: 'StringBox';
    stringValue: Scalars['String'];
};

/** An entry in a string string map represented as a tuple */
export type StringMapEntry = {
    __typename?: 'StringMapEntry';
    /** The key of the map entry */
    key: Scalars['String'];
    /** The value fo the map entry */
    value?: Maybe<Scalars['String']>;
};

/** A type of Metadata Entity sub resource */
export enum SubResourceType {
    /** A Dataset field or column */
    DatasetField = 'DATASET_FIELD',
}

export type SubTypes = {
    __typename?: 'SubTypes';
    /** The sub-types that this entity implements. e.g. Datasets that are views will implement the "view" subtype */
    typeNames?: Maybe<Array<Scalars['String']>>;
};

/** For consumption by UI only */
export type TableChart = {
    __typename?: 'TableChart';
    title: Scalars['String'];
    columns: Array<Scalars['String']>;
    rows: Array<Row>;
};

/** Information about a raw Table Schema */
export type TableSchema = {
    __typename?: 'TableSchema';
    /** Raw table schema */
    schema: Scalars['String'];
};

/** A Tag Entity, which can be associated with other Metadata Entities and subresources */
export type Tag = Entity & {
    __typename?: 'Tag';
    /** The primary key of the TAG */
    urn: Scalars['String'];
    /** A standard Entity Type */
    type: EntityType;
    /** The display name of the tag */
    name: Scalars['String'];
    /** Additional read write properties about the Tag */
    editableProperties?: Maybe<EditableTagProperties>;
    /** Ownership metadata of the dataset */
    ownership?: Maybe<Ownership>;
    /** Edges extending from this entity */
    relationships?: Maybe<EntityRelationshipsResult>;
    /**
     * Deprecated, use editableProperties field instead
     * Description of the tag
     * @deprecated Field no longer supported
     */
    description?: Maybe<Scalars['String']>;
};

/** A Tag Entity, which can be associated with other Metadata Entities and subresources */
export type TagRelationshipsArgs = {
    input: RelationshipsInput;
};

/**
 * An edge between a Metadata Entity and a Tag Modeled as a struct to permit
 * additional attributes
 * TODO Consider whether this query should be serviced by the relationships field
 */
export type TagAssociation = {
    __typename?: 'TagAssociation';
    /** The tag itself */
    tag: Tag;
};

/** Input provided when updating the association between a Metadata Entity and a Tag */
export type TagAssociationInput = {
    /** The primary key of the Tag to add or remove */
    tagUrn: Scalars['String'];
    /** The target Metadata Entity to add or remove the Tag to */
    resourceUrn: Scalars['String'];
    /** An optional type of a sub resource to attach the Tag to */
    subResourceType?: Maybe<SubResourceType>;
    /** An optional sub resource identifier to attach the Tag to */
    subResource?: Maybe<Scalars['String']>;
};

/**
 * Deprecated, use addTag or removeTag mutation instead
 * A tag update to be applied
 */
export type TagAssociationUpdate = {
    /** The tag being applied */
    tag: TagUpdateInput;
};

/**
 * Deprecated, use addTag or removeTag mutations instead
 * An update for a particular Tag entity
 */
export type TagUpdateInput = {
    /** The primary key of the Tag */
    urn: Scalars['String'];
    /** The display name of a Tag */
    name: Scalars['String'];
    /** Description of the tag */
    description?: Maybe<Scalars['String']>;
    /** Ownership metadata of the tag */
    ownership?: Maybe<OwnershipUpdate>;
};

/** Input provided when updating the association between a Metadata Entity and a Glossary Term */
export type TermAssociationInput = {
    /** The primary key of the Glossary Term to add or remove */
    termUrn: Scalars['String'];
    /** The target Metadata Entity to add or remove the Glossary Term from */
    resourceUrn: Scalars['String'];
    /** An optional type of a sub resource to attach the Glossary Term to */
    subResourceType?: Maybe<SubResourceType>;
    /** An optional sub resource identifier to attach the Glossary Term to */
    subResource?: Maybe<Scalars['String']>;
};

/** A time range used in fetching Dataset Usage statistics */
export enum TimeRange {
    /** Last day */
    Day = 'DAY',
    /** Last week */
    Week = 'WEEK',
    /** Last month */
    Month = 'MONTH',
    /** Last quarter */
    Quarter = 'QUARTER',
    /** Last year */
    Year = 'YEAR',
    /** All time */
    All = 'ALL',
}

/** A time series aspect, or a group of related metadata associated with an Entity and corresponding to a particular timestamp */
export type TimeSeriesAspect = {
    /** The timestamp associated with the time series aspect in milliseconds */
    timestampMillis: Scalars['Long'];
};

/** For consumption by UI only */
export type TimeSeriesChart = {
    __typename?: 'TimeSeriesChart';
    title: Scalars['String'];
    lines: Array<NamedLine>;
    dateRange: DateRange;
    interval: DateInterval;
};

/** A time window with a finite start and end time */
export type TimeWindow = {
    __typename?: 'TimeWindow';
    /** The start time of the time window */
    startTimeMillis: Scalars['Long'];
    /** The end time of the time window */
    durationMillis: Scalars['Long'];
};

/** Input parameters for creating / updating an Ingestion Source */
export type UpdateIngestionSourceConfigInput = {
    /** A JSON-encoded recipe */
    recipe: Scalars['String'];
    /** The version of DataHub Ingestion Framework to use when executing the recipe. */
    version?: Maybe<Scalars['String']>;
    /** The id of the executor to use for executing the recipe */
    executorId: Scalars['String'];
};

/** Input arguments for creating / updating an Ingestion Source */
export type UpdateIngestionSourceInput = {
    /** A name associated with the ingestion source */
    name: Scalars['String'];
    /** The type of the source itself, e.g. mysql, bigquery, bigquery-usage. Should match the recipe. */
    type: Scalars['String'];
    /** An optional description associated with the ingestion source */
    description?: Maybe<Scalars['String']>;
    /** An optional schedule for the ingestion source. If not provided, the source is only available for run on-demand. */
    schedule?: Maybe<UpdateIngestionSourceScheduleInput>;
    /** A set of type-specific ingestion source configurations */
    config: UpdateIngestionSourceConfigInput;
};

/** Input arguments for creating / updating the schedule of an Ingestion Source */
export type UpdateIngestionSourceScheduleInput = {
    /** The cron-formatted interval describing when the job should be executed */
    interval: Scalars['String'];
    /** The name of the timezone in which the cron interval should be scheduled (e.g. America/Los Angeles) */
    timezone: Scalars['String'];
};

/** Deprecated, use relationships query instead */
export type UpstreamEntityRelationships = {
    __typename?: 'UpstreamEntityRelationships';
    entities?: Maybe<Array<Maybe<EntityRelationshipLegacy>>>;
};

/** An aggregation of Dataset usage statistics */
export type UsageAggregation = {
    __typename?: 'UsageAggregation';
    /** The time window start time */
    bucket?: Maybe<Scalars['Long']>;
    /** The time window span */
    duration?: Maybe<WindowDuration>;
    /** The resource urn associated with the usage information, eg a Dataset urn */
    resource?: Maybe<Scalars['String']>;
    /** The rolled up usage metrics */
    metrics?: Maybe<UsageAggregationMetrics>;
};

/** Rolled up metrics about Dataset usage over time */
export type UsageAggregationMetrics = {
    __typename?: 'UsageAggregationMetrics';
    /** The unique number of users who have queried the dataset within the time range */
    uniqueUserCount?: Maybe<Scalars['Int']>;
    /** Usage statistics within the time range by user */
    users?: Maybe<Array<Maybe<UserUsageCounts>>>;
    /** The total number of queries issued against the dataset within the time range */
    totalSqlQueries?: Maybe<Scalars['Int']>;
    /** A set of common queries issued against the dataset within the time range */
    topSqlQueries?: Maybe<Array<Maybe<Scalars['String']>>>;
    /** Per field usage statistics within the time range */
    fields?: Maybe<Array<Maybe<FieldUsageCounts>>>;
};

/** The result of a Dataset usage query */
export type UsageQueryResult = {
    __typename?: 'UsageQueryResult';
    /** A set of relevant time windows for use in displaying usage statistics */
    buckets?: Maybe<Array<Maybe<UsageAggregation>>>;
    /** A set of rolled up aggregations about the Dataset usage */
    aggregations?: Maybe<UsageQueryResultAggregations>;
};

/** A set of rolled up aggregations about the Dataset usage */
export type UsageQueryResultAggregations = {
    __typename?: 'UsageQueryResultAggregations';
    /** The count of unique Dataset users within the queried time range */
    uniqueUserCount?: Maybe<Scalars['Int']>;
    /** The specific per user usage counts within the queried time range */
    users?: Maybe<Array<Maybe<UserUsageCounts>>>;
    /** The specific per field usage counts within the queried time range */
    fields?: Maybe<Array<Maybe<FieldUsageCounts>>>;
    /**
     * The total number of queries executed within the queried time range
     * Note that this field will likely be deprecated in favor of a totalQueries field
     */
    totalSqlQueries?: Maybe<Scalars['Int']>;
};

/** Information about individual user usage of a Dataset */
export type UserUsageCounts = {
    __typename?: 'UserUsageCounts';
    /** The user of the Dataset */
    user?: Maybe<CorpUser>;
    /** The number of queries issued by the user */
    count?: Maybe<Scalars['Int']>;
    /**
     * The extracted user email
     * Note that this field will soon be deprecated and merged with user
     */
    userEmail?: Maybe<Scalars['String']>;
};

/** The technical version associated with a given Metadata Entity */
export type VersionTag = {
    __typename?: 'VersionTag';
    versionTag?: Maybe<Scalars['String']>;
};

/** Properties about a Dataset of type view */
export type ViewProperties = {
    __typename?: 'ViewProperties';
    /** Whether the view is materialized or not */
    materialized: Scalars['Boolean'];
    /** The logic associated with the view, most commonly a SQL statement */
    logic: Scalars['String'];
    /** The language in which the view logic is written, for example SQL */
    language: Scalars['String'];
};

/** The duration of a fixed window of time */
export enum WindowDuration {
    /** A one day window */
    Day = 'DAY',
    /** A one week window */
    Week = 'WEEK',
    /** A one month window */
    Month = 'MONTH',
    /** A one year window */
    Year = 'YEAR',
}
