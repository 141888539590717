/* eslint-disable */
import * as Types from '../types.generated';

import {
    DataJobFieldsFragment,
    OwnershipFieldsFragment,
    SchemaMetadataFieldsFragment,
    PlatformFieldsFragment,
    GlobalTagsFieldsFragment,
    GlossaryTermsFragment,
    EntityDomainFragment,
    InstitutionalMemoryFieldsFragment,
    EntityContainerFragment,
    NonRecursiveDataFlowFieldsFragment,
    NonConflictingPlatformFieldsFragment,
    DashboardFieldsFragment,
} from './fragments.generated';
import { gql } from '@apollo/client';
import {
    DataJobFieldsFragmentDoc,
    OwnershipFieldsFragmentDoc,
    SchemaMetadataFieldsFragmentDoc,
    PlatformFieldsFragmentDoc,
    GlobalTagsFieldsFragmentDoc,
    GlossaryTermsFragmentDoc,
    EntityDomainFragmentDoc,
    InstitutionalMemoryFieldsFragmentDoc,
    EntityContainerFragmentDoc,
    NonRecursiveDataFlowFieldsFragmentDoc,
    NonConflictingPlatformFieldsFragmentDoc,
    DashboardFieldsFragmentDoc,
} from './fragments.generated';
export type RelationshipFields_Chart_Fragment = { __typename?: 'Chart' } & Pick<
    Types.Chart,
    'tool' | 'chartId' | 'urn' | 'type'
> & {
        properties?: Types.Maybe<
            { __typename?: 'ChartProperties' } & Pick<Types.ChartProperties, 'name' | 'description'>
        >;
        editableProperties?: Types.Maybe<
            { __typename?: 'ChartEditableProperties' } & Pick<Types.ChartEditableProperties, 'description'>
        >;
        ownership?: Types.Maybe<{ __typename?: 'Ownership' } & OwnershipFieldsFragment>;
        domain?: Types.Maybe<{ __typename?: 'Domain' } & EntityDomainFragment>;
    } & ChartRelationshipsLeafFragment;

export type RelationshipFields_Container_Fragment = { __typename?: 'Container' } & Pick<
    Types.Container,
    'urn' | 'type'
>;

export type RelationshipFields_CorpGroup_Fragment = { __typename?: 'CorpGroup' } & Pick<
    Types.CorpGroup,
    'urn' | 'type'
>;

export type RelationshipFields_CorpUser_Fragment = { __typename?: 'CorpUser' } & Pick<Types.CorpUser, 'urn' | 'type'>;

export type RelationshipFields_Dashboard_Fragment = { __typename?: 'Dashboard' } & Pick<
    Types.Dashboard,
    'urn' | 'type'
> & {
        editableProperties?: Types.Maybe<
            { __typename?: 'DashboardEditableProperties' } & Pick<Types.DashboardEditableProperties, 'description'>
        >;
    } & DashboardRelationshipsLeafFragment &
    DashboardFieldsFragment;

export type RelationshipFields_DataFlow_Fragment = { __typename?: 'DataFlow' } & Pick<
    Types.DataFlow,
    'orchestrator' | 'flowId' | 'cluster' | 'urn' | 'type'
> & {
        properties?: Types.Maybe<
            { __typename?: 'DataFlowProperties' } & Pick<Types.DataFlowProperties, 'name' | 'description' | 'project'>
        >;
        ownership?: Types.Maybe<{ __typename?: 'Ownership' } & OwnershipFieldsFragment>;
        globalTags?: Types.Maybe<{ __typename?: 'GlobalTags' } & GlobalTagsFieldsFragment>;
        glossaryTerms?: Types.Maybe<{ __typename?: 'GlossaryTerms' } & GlossaryTermsFragment>;
        editableProperties?: Types.Maybe<
            { __typename?: 'DataFlowEditableProperties' } & Pick<Types.DataFlowEditableProperties, 'description'>
        >;
        domain?: Types.Maybe<{ __typename?: 'Domain' } & EntityDomainFragment>;
    };

export type RelationshipFields_DataJob_Fragment = { __typename?: 'DataJob' } & Pick<Types.DataJob, 'urn' | 'type'> & {
        editableProperties?: Types.Maybe<
            { __typename?: 'DataJobEditableProperties' } & Pick<Types.DataJobEditableProperties, 'description'>
        >;
    } & DataJobFieldsFragment &
    DataJobRelationshipsLeafFragment;

export type RelationshipFields_DataPlatform_Fragment = { __typename?: 'DataPlatform' } & Pick<
    Types.DataPlatform,
    'urn' | 'type'
>;

export type RelationshipFields_DataPlatformInstance_Fragment = { __typename?: 'DataPlatformInstance' } & Pick<
    Types.DataPlatformInstance,
    'urn' | 'type'
>;

export type RelationshipFields_Dataset_Fragment = { __typename?: 'Dataset' } & Pick<
    Types.Dataset,
    'name' | 'urn' | 'type'
> & {
        properties?: Types.Maybe<{ __typename?: 'DatasetProperties' } & Pick<Types.DatasetProperties, 'description'>>;
        editableProperties?: Types.Maybe<
            { __typename?: 'DatasetEditableProperties' } & Pick<Types.DatasetEditableProperties, 'description'>
        >;
        platform: { __typename?: 'DataPlatform' } & PlatformFieldsFragment;
        ownership?: Types.Maybe<{ __typename?: 'Ownership' } & OwnershipFieldsFragment>;
        subTypes?: Types.Maybe<{ __typename?: 'SubTypes' } & Pick<Types.SubTypes, 'typeNames'>>;
    } & DatasetRelationshipsLeafFragment;

export type RelationshipFields_Domain_Fragment = { __typename?: 'Domain' } & Pick<Types.Domain, 'urn' | 'type'>;

export type RelationshipFields_GlossaryTerm_Fragment = { __typename?: 'GlossaryTerm' } & Pick<
    Types.GlossaryTerm,
    'urn' | 'type'
>;

export type RelationshipFields_MlFeature_Fragment = { __typename?: 'MLFeature' } & Pick<
    Types.MlFeature,
    'urn' | 'type'
>;

export type RelationshipFields_MlFeatureTable_Fragment = { __typename?: 'MLFeatureTable' } & Pick<
    Types.MlFeatureTable,
    'urn' | 'type'
>;

export type RelationshipFields_MlModel_Fragment = { __typename?: 'MLModel' } & Pick<
    Types.MlModel,
    'urn' | 'type' | 'name' | 'description' | 'origin'
> & {
        platform: { __typename?: 'DataPlatform' } & PlatformFieldsFragment;
        ownership?: Types.Maybe<{ __typename?: 'Ownership' } & OwnershipFieldsFragment>;
    } & MlModelRelationshipsLeafFragment;

export type RelationshipFields_MlModelGroup_Fragment = { __typename?: 'MLModelGroup' } & Pick<
    Types.MlModelGroup,
    'urn' | 'type' | 'name' | 'description' | 'origin'
> & {
        platform: { __typename?: 'DataPlatform' } & PlatformFieldsFragment;
        ownership?: Types.Maybe<{ __typename?: 'Ownership' } & OwnershipFieldsFragment>;
    };

export type RelationshipFields_MlPrimaryKey_Fragment = { __typename?: 'MLPrimaryKey' } & Pick<
    Types.MlPrimaryKey,
    'urn' | 'type'
>;

export type RelationshipFields_Tag_Fragment = { __typename?: 'Tag' } & Pick<Types.Tag, 'urn' | 'type'>;

export type RelationshipFieldsFragment =
    | RelationshipFields_Chart_Fragment
    | RelationshipFields_Container_Fragment
    | RelationshipFields_CorpGroup_Fragment
    | RelationshipFields_CorpUser_Fragment
    | RelationshipFields_Dashboard_Fragment
    | RelationshipFields_DataFlow_Fragment
    | RelationshipFields_DataJob_Fragment
    | RelationshipFields_DataPlatform_Fragment
    | RelationshipFields_DataPlatformInstance_Fragment
    | RelationshipFields_Dataset_Fragment
    | RelationshipFields_Domain_Fragment
    | RelationshipFields_GlossaryTerm_Fragment
    | RelationshipFields_MlFeature_Fragment
    | RelationshipFields_MlFeatureTable_Fragment
    | RelationshipFields_MlModel_Fragment
    | RelationshipFields_MlModelGroup_Fragment
    | RelationshipFields_MlPrimaryKey_Fragment
    | RelationshipFields_Tag_Fragment;

export type FullRelationshipResultsFragment = { __typename?: 'EntityRelationshipsResult' } & Pick<
    Types.EntityRelationshipsResult,
    'start' | 'count' | 'total'
> & {
        relationships: Array<
            { __typename?: 'EntityRelationship' } & Pick<Types.EntityRelationship, 'type' | 'direction'> & {
                    entity:
                        | ({ __typename?: 'Chart' } & RelationshipFields_Chart_Fragment)
                        | ({ __typename?: 'Container' } & RelationshipFields_Container_Fragment)
                        | ({ __typename?: 'CorpGroup' } & RelationshipFields_CorpGroup_Fragment)
                        | ({ __typename?: 'CorpUser' } & RelationshipFields_CorpUser_Fragment)
                        | ({ __typename?: 'Dashboard' } & RelationshipFields_Dashboard_Fragment)
                        | ({ __typename?: 'DataFlow' } & RelationshipFields_DataFlow_Fragment)
                        | ({ __typename?: 'DataJob' } & RelationshipFields_DataJob_Fragment)
                        | ({ __typename?: 'DataPlatform' } & RelationshipFields_DataPlatform_Fragment)
                        | ({ __typename?: 'DataPlatformInstance' } & RelationshipFields_DataPlatformInstance_Fragment)
                        | ({ __typename?: 'Dataset' } & RelationshipFields_Dataset_Fragment)
                        | ({ __typename?: 'Domain' } & RelationshipFields_Domain_Fragment)
                        | ({ __typename?: 'GlossaryTerm' } & RelationshipFields_GlossaryTerm_Fragment)
                        | ({ __typename?: 'MLFeature' } & RelationshipFields_MlFeature_Fragment)
                        | ({ __typename?: 'MLFeatureTable' } & RelationshipFields_MlFeatureTable_Fragment)
                        | ({ __typename?: 'MLModel' } & RelationshipFields_MlModel_Fragment)
                        | ({ __typename?: 'MLModelGroup' } & RelationshipFields_MlModelGroup_Fragment)
                        | ({ __typename?: 'MLPrimaryKey' } & RelationshipFields_MlPrimaryKey_Fragment)
                        | ({ __typename?: 'Tag' } & RelationshipFields_Tag_Fragment);
                }
        >;
    };

export type LeafRelationshipResultsFragment = { __typename?: 'EntityRelationshipsResult' } & Pick<
    Types.EntityRelationshipsResult,
    'start' | 'count' | 'total'
> & {
        relationships: Array<
            { __typename?: 'EntityRelationship' } & Pick<Types.EntityRelationship, 'type' | 'direction'> & {
                    entity:
                        | ({ __typename?: 'Chart' } & Pick<Types.Chart, 'urn' | 'type'>)
                        | ({ __typename?: 'Container' } & Pick<Types.Container, 'urn' | 'type'>)
                        | ({ __typename?: 'CorpGroup' } & Pick<Types.CorpGroup, 'urn' | 'type'>)
                        | ({ __typename?: 'CorpUser' } & Pick<Types.CorpUser, 'urn' | 'type'>)
                        | ({ __typename?: 'Dashboard' } & Pick<Types.Dashboard, 'urn' | 'type'>)
                        | ({ __typename?: 'DataFlow' } & Pick<Types.DataFlow, 'urn' | 'type'>)
                        | ({ __typename?: 'DataJob' } & Pick<Types.DataJob, 'urn' | 'type'>)
                        | ({ __typename?: 'DataPlatform' } & Pick<Types.DataPlatform, 'urn' | 'type'>)
                        | ({ __typename?: 'DataPlatformInstance' } & Pick<Types.DataPlatformInstance, 'urn' | 'type'>)
                        | ({ __typename?: 'Dataset' } & Pick<Types.Dataset, 'urn' | 'type'>)
                        | ({ __typename?: 'Domain' } & Pick<Types.Domain, 'urn' | 'type'>)
                        | ({ __typename?: 'GlossaryTerm' } & Pick<Types.GlossaryTerm, 'urn' | 'type'>)
                        | ({ __typename?: 'MLFeature' } & Pick<Types.MlFeature, 'urn' | 'type'>)
                        | ({ __typename?: 'MLFeatureTable' } & Pick<Types.MlFeatureTable, 'urn' | 'type'>)
                        | ({ __typename?: 'MLModel' } & Pick<Types.MlModel, 'urn' | 'type'>)
                        | ({ __typename?: 'MLModelGroup' } & Pick<Types.MlModelGroup, 'urn' | 'type'>)
                        | ({ __typename?: 'MLPrimaryKey' } & Pick<Types.MlPrimaryKey, 'urn' | 'type'>)
                        | ({ __typename?: 'Tag' } & Pick<Types.Tag, 'urn' | 'type'>);
                }
        >;
    };

export type DataJobRelationshipsLeafFragment = { __typename?: 'DataJob' } & {
    incoming?: Types.Maybe<{ __typename?: 'EntityRelationshipsResult' } & LeafRelationshipResultsFragment>;
    outgoing?: Types.Maybe<{ __typename?: 'EntityRelationshipsResult' } & LeafRelationshipResultsFragment>;
};

export type DatasetRelationshipsLeafFragment = { __typename?: 'Dataset' } & {
    incoming?: Types.Maybe<{ __typename?: 'EntityRelationshipsResult' } & LeafRelationshipResultsFragment>;
    outgoing?: Types.Maybe<{ __typename?: 'EntityRelationshipsResult' } & LeafRelationshipResultsFragment>;
};

export type ChartRelationshipsLeafFragment = { __typename?: 'Chart' } & {
    inputs?: Types.Maybe<{ __typename?: 'EntityRelationshipsResult' } & LeafRelationshipResultsFragment>;
    dashboards?: Types.Maybe<{ __typename?: 'EntityRelationshipsResult' } & LeafRelationshipResultsFragment>;
};

export type DashboardRelationshipsLeafFragment = { __typename?: 'Dashboard' } & {
    charts?: Types.Maybe<{ __typename?: 'EntityRelationshipsResult' } & LeafRelationshipResultsFragment>;
};

export type MlModelRelationshipsLeafFragment = { __typename?: 'MLModel' } & {
    incoming?: Types.Maybe<{ __typename?: 'EntityRelationshipsResult' } & LeafRelationshipResultsFragment>;
    outgoing?: Types.Maybe<{ __typename?: 'EntityRelationshipsResult' } & LeafRelationshipResultsFragment>;
};

export type MlModelGroupRelationshipsLeafFragment = { __typename?: 'MLModelGroup' } & {
    incoming?: Types.Maybe<{ __typename?: 'EntityRelationshipsResult' } & LeafRelationshipResultsFragment>;
    outgoing?: Types.Maybe<{ __typename?: 'EntityRelationshipsResult' } & LeafRelationshipResultsFragment>;
};

export const LeafRelationshipResultsFragmentDoc = gql`
    fragment leafRelationshipResults on EntityRelationshipsResult {
        start
        count
        total
        relationships {
            type
            direction
            entity {
                urn
                type
            }
        }
    }
`;
export const DataJobRelationshipsLeafFragmentDoc = gql`
    fragment dataJobRelationshipsLeaf on DataJob {
        incoming: relationships(
            input: { types: ["DownstreamOf", "Consumes", "Produces"], direction: INCOMING, start: 0, count: 100 }
        ) {
            ...leafRelationshipResults
        }
        outgoing: relationships(
            input: { types: ["DownstreamOf", "Consumes", "Produces"], direction: OUTGOING, start: 0, count: 100 }
        ) {
            ...leafRelationshipResults
        }
    }
    ${LeafRelationshipResultsFragmentDoc}
`;
export const DashboardRelationshipsLeafFragmentDoc = gql`
    fragment dashboardRelationshipsLeaf on Dashboard {
        charts: relationships(input: { types: ["Contains"], direction: OUTGOING, start: 0, count: 100 }) {
            ...leafRelationshipResults
        }
    }
    ${LeafRelationshipResultsFragmentDoc}
`;
export const ChartRelationshipsLeafFragmentDoc = gql`
    fragment chartRelationshipsLeaf on Chart {
        inputs: relationships(input: { types: ["Consumes"], direction: OUTGOING, start: 0, count: 100 }) {
            ...leafRelationshipResults
        }
        dashboards: relationships(input: { types: ["Contains"], direction: INCOMING, start: 0, count: 100 }) {
            ...leafRelationshipResults
        }
    }
    ${LeafRelationshipResultsFragmentDoc}
`;
export const DatasetRelationshipsLeafFragmentDoc = gql`
    fragment datasetRelationshipsLeaf on Dataset {
        incoming: relationships(
            input: { types: ["DownstreamOf", "Consumes", "Produces"], direction: INCOMING, start: 0, count: 100 }
        ) {
            ...leafRelationshipResults
        }
        outgoing: relationships(
            input: { types: ["DownstreamOf", "Consumes", "Produces"], direction: OUTGOING, start: 0, count: 100 }
        ) {
            ...leafRelationshipResults
        }
    }
    ${LeafRelationshipResultsFragmentDoc}
`;
export const MlModelRelationshipsLeafFragmentDoc = gql`
    fragment mlModelRelationshipsLeaf on MLModel {
        incoming: relationships(
            input: {
                types: ["DownstreamOf", "Consumes", "Produces", "TrainedBy", "MemberOf"]
                direction: INCOMING
                start: 0
                count: 100
            }
        ) {
            ...leafRelationshipResults
        }
        outgoing: relationships(
            input: {
                types: ["DownstreamOf", "Consumes", "Produces", "TrainedBy", "MemberOf"]
                direction: OUTGOING
                start: 0
                count: 100
            }
        ) {
            ...leafRelationshipResults
        }
    }
    ${LeafRelationshipResultsFragmentDoc}
`;
export const RelationshipFieldsFragmentDoc = gql`
    fragment relationshipFields on Entity {
        urn
        type
        ... on DataJob {
            ...dataJobFields
            ...dataJobRelationshipsLeaf
            editableProperties {
                description
            }
        }
        ... on DataFlow {
            orchestrator
            flowId
            cluster
            properties {
                name
                description
                project
            }
            ownership {
                ...ownershipFields
            }
            globalTags {
                ...globalTagsFields
            }
            glossaryTerms {
                ...glossaryTerms
            }
            editableProperties {
                description
            }
            domain {
                ...entityDomain
            }
        }
        ... on Dashboard {
            ...dashboardRelationshipsLeaf
            ...dashboardFields
            editableProperties {
                description
            }
        }
        ... on Chart {
            tool
            chartId
            properties {
                name
                description
            }
            editableProperties {
                description
            }
            ownership {
                ...ownershipFields
            }
            domain {
                ...entityDomain
            }
            ...chartRelationshipsLeaf
        }
        ... on Dataset {
            name
            properties {
                description
            }
            editableProperties {
                description
            }
            platform {
                ...platformFields
            }
            ownership {
                ...ownershipFields
            }
            subTypes {
                typeNames
            }
            ...datasetRelationshipsLeaf
        }
        ... on MLModelGroup {
            urn
            type
            name
            description
            origin
            platform {
                ...platformFields
            }
            ownership {
                ...ownershipFields
            }
        }
        ... on MLModel {
            urn
            type
            name
            description
            origin
            platform {
                ...platformFields
            }
            ownership {
                ...ownershipFields
            }
            ...mlModelRelationshipsLeaf
        }
    }
    ${DataJobFieldsFragmentDoc}
    ${DataJobRelationshipsLeafFragmentDoc}
    ${OwnershipFieldsFragmentDoc}
    ${GlobalTagsFieldsFragmentDoc}
    ${GlossaryTermsFragmentDoc}
    ${EntityDomainFragmentDoc}
    ${DashboardRelationshipsLeafFragmentDoc}
    ${DashboardFieldsFragmentDoc}
    ${ChartRelationshipsLeafFragmentDoc}
    ${PlatformFieldsFragmentDoc}
    ${DatasetRelationshipsLeafFragmentDoc}
    ${MlModelRelationshipsLeafFragmentDoc}
`;
export const FullRelationshipResultsFragmentDoc = gql`
    fragment fullRelationshipResults on EntityRelationshipsResult {
        start
        count
        total
        relationships {
            type
            direction
            entity {
                ...relationshipFields
            }
        }
    }
    ${RelationshipFieldsFragmentDoc}
`;
export const MlModelGroupRelationshipsLeafFragmentDoc = gql`
    fragment mlModelGroupRelationshipsLeaf on MLModelGroup {
        incoming: relationships(
            input: {
                types: ["DownstreamOf", "Consumes", "Produces", "TrainedBy", "MemberOf"]
                direction: INCOMING
                start: 0
                count: 100
            }
        ) {
            ...leafRelationshipResults
        }
        outgoing: relationships(
            input: {
                types: ["DownstreamOf", "Consumes", "Produces", "TrainedBy", "MemberOf"]
                direction: OUTGOING
                start: 0
                count: 100
            }
        ) {
            ...leafRelationshipResults
        }
    }
    ${LeafRelationshipResultsFragmentDoc}
`;
